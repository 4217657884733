import {
    TOGGLE_APP_ENV,
    TOGGLE_MODAL,
    TOGGLE_DATA_VIEW,
    SELECT_NAV_ITEM,
    STORE_NAV_KEY,
    STORE_CURRENT_PATH,
    SET_BASE_URL,
    SET_LANDING_PAGE_DATA,
    UPLOAD_FILE,
    CANCEL_FILE_UPLOAD,
    SET_POCKET_TIER,
    SET_POCKET_BAL,
    SET_BATCH_ID,
    CLEAR_BATCH_ID,
    STORE_BANK_LIST
} from './app.type'

export const toggleAppEnv = () => ({
    type: TOGGLE_APP_ENV
})

export const toggleModal = () => ({
    type: TOGGLE_MODAL
})

export const toggleDataView = () => ({
    type: TOGGLE_DATA_VIEW
}) 

export const selectNavItem = (data: any) => ({
    type: SELECT_NAV_ITEM,
    payload: data
})

export const storeNavKey = (data: any) => ({
    type: STORE_NAV_KEY,
    payload: data
})

export const getPocketTier = (data: any) => ({
    type: SET_POCKET_TIER,
    payload: data
})

export const getPocketBal = (data: any) => ({
    type: SET_POCKET_BAL,
    payload: data
})

export const storeCurrentPath = (data: any) => ({
    type: STORE_CURRENT_PATH,
    payload: data
})

export const setBaseUrl = (url: any) => ({
    type: SET_BASE_URL,
    payload: url
})

export const setLandingPageData = (data: any[]) => ({
    type: SET_LANDING_PAGE_DATA,
    payload: data
})

export const uploadFile = (file: any[]) => ({
    type: UPLOAD_FILE,
    payload: file
})

export const cancelFileUpload = (id?: any) => ({
    type: CANCEL_FILE_UPLOAD,
    payload: id
})

export const setBatchID = (id: any) => ({
    type: SET_BATCH_ID,
    payload: id
})

export const clearBatchID = () => ({
    type: CLEAR_BATCH_ID,
})

export const storeBanklist = (data: any[]) => ({
    type: STORE_BANK_LIST,
    payload: data
})