import React, { useCallback, useEffect, useState } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import SideNav from 'components/organisms/SideNav'
import TopNav from 'components/organisms/TopNav'
// import BreadCrumbs from '../../components/atoms/BreadCrumbs'
import Footer from 'components/organisms/Footer'
import MobileNav from 'components/organisms/MobileNav'
import { connect } from 'react-redux'
import ErrorEmptyState from 'components/atoms/ErrorEmptyState'
import { IdleTimerProvider, useIdleTimerContext } from 'react-idle-timer'

const Dashboard = ({appEnv, currentUser, loggedUserData, setDataAvailable, dataAvailable}) => {
  const idleTimer = useIdleTimerContext()

  const onIdle = () => {
    localStorage.clear()
    sessionStorage.clear()
    window.location.href = "/auth/login"
  }

  return (
    <IdleTimerProvider
      timeout={1000 * 600}
      onIdle={onIdle} 
    >
      <div className='flex desktop:flex-row mobile:flex-col min-h-screen w-full'>
        <div className='w-3/12 desktop:w-2/12 mobile:hidden min-h-screen bg-black relative'>
          <SideNav/>
        </div>
        <div className='w-9/12 desktop:w-10/12 mobile:w-full min-h-screen h-full relative overflow-x-hidden flex flex-col'>
          <div className='mobile:hidden'>
            <TopNav/>
          </div>
          <div className={`hidden mobile:flex shadow ${appEnv === false && 'shadow-Primary'} justify-between items-center py-5`}>
            <MobileNav/>
          </div>
          <div className={`desktop:px-12 tablet:px-10d mobile:px-5 pt-5 pb-16 desktop:min-h-[93.75vh] min-h-[95.5vh] mobile:min-h-[91.5vh] h-full ${'bg-AppBg'}`}>
            {/* <BreadCrumbs/> */}
            <Outlet/>
          </div>
          {/* <Footer/> */}
        </div>
      </div>
    </IdleTimerProvider>
  )
}

const mapStateToProps = state => ({
  appEnv: state.app.appEnv,
  currentUser: state.auth.device_auth_data,
  loggedUserData: state.auth.loggedUserData,
  dataAvailable: state.app.dataAvailable
})

const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)