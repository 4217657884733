import React, { useEffect, useState } from 'react'
import PageTitle from 'components/atoms/PageTitle'
import Button from 'components/atoms/Button'
import CodeInput from 'components/atoms/CodeInput'
import infoIcon from '../../../../assets/svg/info-circle-danger.svg'
import AppModal from '../../../../components/organisms/CustomModal'
import Prompts from 'components/atoms/Prompts'
import { connect } from 'react-redux'
import { cancelFileUpload, setBatchID } from '../../../../redux/app/app.action'
import Alert from 'components/atoms/Alert'
import { useLocation, useNavigate } from 'react-router-dom'
import CountdownTimer from 'components/atoms/CountDown'
import { handleException } from 'utils'
import axios from 'services/axios'
import { getValues } from 'services/storage'
import Spinner from 'components/atoms/Spinner'
import { PocketUserActionEnum, PocketUserEnum } from 'types'

const ValidateOTP = (props) => {
    const [OTP, setOTP] = React.useState('');
    const [loading, setLoading] = useState(false)
    const [OTPChange, setOTPChange] = useState('')
    const [OTPErr, setOTPErr] = useState('');
    
    const navigate = useNavigate();

    const location: any = useLocation();
    const fileAvailable = location?.state?.fileData ? true : false;
    const transferType = location.state.selected
    useEffect(() => {
        if (fileAvailable === false) {
            navigate("/dashboard/disbursement/bulk")
        }
    }, [navigate, location]);

    const [showPrompt,  setShowPrompt] = useState(false)
    const promptSetting = {
        icon: infoIcon,
        iconStyle: "bg-DangerAccent4",
        BgColor:"white",
        loadColor:"bg-Danger3",
        message:"Tarminate Transaction",
        subMessage:"This action would cancel the transaction process and remove the uploaded file from memory, do you wish to continue?"
    }

    const userName = getValues('username')
    // const actionItemValue = getValues('disburse')

    const accountType = getValues('pocketType');
    const actionItemValue = accountType === PocketUserEnum.PRIMARY_POCKET ? PocketUserActionEnum.APPROVE_DISBURSEMENT_BY_HEAD_CHURCH : PocketUserActionEnum.APPROVE_DISBURSEMENT_BY_OUTLET;


    const handleOTPComplete = (p) => {
        setOTP(p)
    }

    const requestOTP = async (dataObj:any) => {
        setLoading(true)
        try{
            const res = await  axios.post(`https://pocket.seerbitapi.com/winner/sendOtp`, dataObj, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.bearerToken}`,
                }
            })

            if(res.data.code === 'error'){
                setLoading(false)
                Alert('error', res.data.code);
            }else{
                setLoading(false)
                Alert('success','Next, Validate OTP');
                navigate('/dashboard/disbursement/validate_otp', { state: { fileData: location?.state?.fileData, pocketID: location?.state?.pocketID } });
            }

            return res;
        }catch(error){
            setLoading(false)
            handleException(error)
            Alert('error', "Failed");
        }
    }
    
    const verifyOTP = async (verifyObj:any) => {
        setLoading(true)
        try{
            const res = await  axios.post(`https://pocket.seerbitapi.com/winner/otp-verify`, verifyObj, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.bearerToken}`
                }
            })

            if(res.data.code === 'error'){
                setLoading(false)
                Alert('error', res.data.code);
            }else{
                setLoading(false)
                Alert('success','OTP Validated');
                navigate('/dashboard/disbursement/auth_processing', { state: { fileData: location?.state?.fileData, selected: transferType } });
                console.log("OTP_verify_res:", res);
            }

            return res;
        }catch(error){
            setLoading(false)
            handleException(error)
        }
    }

    const confirmationContent = (
        <AppModal 
            modalStyle="desktop:w-[30%] w-3/5 mobile:w-4/5 min-h-fit z-30 top-0 left-0 right-0 p-4 my-[10rem] mobile:my-[5rem] mx-auto animate-center_align rounded-md" 
            contentStyle="overflow-y-scroll min-h-fit w-full"
        >
            <Prompts
                promptSetting={promptSetting}
                action={true}
                handleClose={()=>{
                    setShowPrompt(false)
                }}
                handleConfirm={()=>{
                    Alert('success','Batch transaction terminated')
                    navigate('/dashboard/disbursement/bulk')
                    setShowPrompt(false)
                }}
            />
        </AppModal>
    )

    const handleValidateOTP = () => {
        if(OTPChange === "" || OTPChange.length < 6){
            Alert('error',"Please enter the complete OTP.")
        }else{
            verifyOTP({
                otp:OTPChange,
                email:userName,
                category: actionItemValue
            })
        }
    }

    return (
        <div className='flex flex-col gap-5 justify-between'>
            <PageTitle
                pageTitle='Authentication'
                pageTitleStyle='font-bold'
            />

            <div className='py-5 mobile:p-0 rounded-[15px] min-h-[70vh] mobile:min-h-fit mobile:my-[4rem] mobile:rounded-[10px] mobile:shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] overflow-y-scroll custom_container mb-10'>
                <div className='flex justify-center items-center min-h-[65vh] mobile:min-h-fit p-5 mobile:p-0 rounded-[15px]'>

                    <div className="flex flex-col gap-16 justify-between items-center w-[515px] mobile:w-full min-h-[350px] mobile:min-h-[200px] p-10 mobile:p-5 bg-white rounded-[10px] shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]">
                        {loading &&
                            <div className={`absolute z-10 left-0 top-0 flex items-center py-10 justify-center rounded-[15px] bg-white bg-opacity-80 w-full h-full`}>
                                <Spinner text='Validating OTP...'  textStyle='text-lg'/>
                            </div> 
                        }

                        <div className='flex flex-col gap-8'>
                            <span className='font-semibold text-2xl leading-[28.8px] text-black'>Enter One Time Passcode</span>
                            <span className='font-normal text-lg leading-[30px] text-SecondaryAccent'>
                                Please enter the 6-digit OTP sent to the assigned signatory Email
                            </span>
                        </div>
                        <div className='w-fit flex flex-col justify-center items-center gap-3'>
                            <CodeInput
                                length={6}
                                onChange={(v) => setOTPChange(v)} 
                                type={'numeric'} 
                                secret={false} 
                                onComplete={handleOTPComplete}
                            />
                            <div className='flex justify-center items-center gap-1'>
                                <span className="text-zinc-950 text-[15px] font-normal font-['Blinker'] leading-[30px]">
                                    Didn’t receive the OTP? 
                                </span>
                                <div className={`flex items-center gap-1 text-red-600 text-[15px] font-normal font-['Blinker'] leading-[30px]`}>
                                    <span onClick={()=>requestOTP({actionItem: actionItemValue})} className='cursor-pointer'>
                                        Resend
                                    </span>
                                    {/* {showTimer && (<CountdownTimer initialSeconds={60}/>)} */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='flex justify-between items-center absolute w-full bottom-0 left-0 py-8 px-16 mobile:py-4 mobile:px-10 bg-white shadow-[0_8px_30px_rgb(0,0,0,0.12)]'>
                <Button 
                    btnType="button"
                    btnText='Cancel'
                    btnStyle="bg-SecondaryAccent5 text-DarkBg3 rounded-lg truncate flex gap-3 justify-center items-center w-[216px] mobile:w-fit mobile:px-8 px-3 py-2 font-semibold text-lg leading-7 flex-row-reverse rounded-lg truncate" 
                    disabled={false} 
                    disabledClass=""
                    onClick={()=>setShowPrompt(true)}
                />
                <Button 
                    btnType="button"
                    btnText="Validate OTP"
                    btnStyle="bg-DarkBg3 text-white rounded-lg truncate flex gap-3 justify-center items-center w-[216px] mobile:w-fit mobile:px-8 px-3 py-2 font-semibold flex-row-reverse rounded-lg truncate create-button" 
                    disabled={false} 
                    disabledClass=""
                    onClick={handleValidateOTP}
                />
            </div>

            {showPrompt && confirmationContent}
        </div>
    )
}

const mapStateToProps =  (state: any) => ({
    bearerToken: state.auth.token,
    public_key: state.auth.public_key,
    pocketId: state.auth.pocket_data?.pocketId,
})

const mapDispatchToProps = (dispatch: any) => ({
    setBatchID: (id: any) => dispatch(setBatchID(id)),
    cancelFileUpload: () => dispatch(cancelFileUpload())
})

export default connect(mapStateToProps, mapDispatchToProps)(ValidateOTP)