import React, { ChangeEvent, useCallback, useEffect, useState } from "react";
import axios from "services/axios";
import arrow_left from "../../../../assets/svg/arrow-left.svg";
import arrow_right from "../../../../assets/svg/arrow-right.svg";
import FormInput from "components/atoms/FormInput";
import Button from "components/atoms/Button";
import Alert from "components/atoms/Alert";
import {
  generateRandomAlphanumeric,
  getBusinessInitials,
  getFileType,
  handleException,
} from "utils";
import { BASE_URL } from "services/http";
import { connect } from "react-redux";
import { toggleModal, uploadFile } from "redux/app/app.action";
import SideComponent from "../SideComponent";
import FileUpload from "components/atoms/FileUpload.tsx";
import fileIcon from "../../../../assets/svg/Subtract.svg";
import PageTitle from "components/atoms/PageTitle";
import { Link, useNavigate } from "react-router-dom";
import CSVToJson from "../../../../components/atoms/CSVToJson.js";
import { CSVLink, CSVDownload } from "react-csv";
import Spinner from "components/atoms/Spinner";

import { useDropzone } from "react-dropzone";
import fileUpIcon from "../../../../assets/svg/upload-file-grey.svg";
import * as XLSX from "xlsx";
import sampleXlsx from "../../../../assets/sample/sample.xlsx";
import walletIcon_active from "../../../../assets/svg/wallet-d-outline.svg";
import bankIcon_active from "../../../../assets/svg/bank.svg";
import walletIcon from "../../../../assets/svg/wallet-outline-grey.svg";
import bankIcon from "../../../../assets/svg/bank_grey.svg";
import { getValues, setValues } from 'services/storage';
import { SelectPicker } from 'rsuite';
import countryList from "../../../../data/country_list.json";
import Select from "react-select";

const getCountry: any = (data: any) => countryList.find((country) =>
    data.country
      ? country.alpha2 === data.country
      : country.currency_code === data.currency
);

const BulkTransfer = (props) => {
  const { pocketAccountInfo } = props;
  const [selected, setSelected] = useState("sub_pocket_tf");
  const [loading, setLoading] = useState(false);
  const [fileData, setFileData] = useState<any[]>([]);
  const [pocketID, setPocketID] = useState("");
  const businessInitials =
    getBusinessInitials(pocketAccountInfo?.pocketOwner?.businessName) || "SBT";

  const navigate = useNavigate();

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      setLoading(true);
      const reader = new FileReader();
      const validHeaders = [
        "amount",
        "accountNumber",
        "bankCode",
        "currency",
        "description",
      ];

      reader.onabort = () => Alert("info", "file reading was aborted");
      reader.onerror = () => Alert("info", "file reading has failed");

      reader.onload = async (e: any) => {
        // const csvData = e.target?.result;

        // const jsonData = await CSVToJson(csvData);
        const workbook = XLSX.read(e.target.result, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const headers: any = data[0];
        const excelData = data.slice(1).map((row: any) => {
          const obj = {};
          headers.forEach((header, index) => {
            obj[header] = row[index];
          });
          return obj;
        });

        if (excelData.length === 0) {
          Alert("error", "Your CSV file is empty");
          setLoading(false);
          return false;
        } else {
          let trimmedFileData: any[] = [];
          if (excelData.length === 1) {
            trimmedFileData = excelData;
          } else {
            trimmedFileData = excelData
              .map((row) =>
                Object.keys(row).some(
                  (key) =>
                    row[key] !== "" &&
                    row[key] !== '""' &&
                    row[key] !== undefined
                )
                  ? row
                  : null
              )
              .filter((row) => row !== null);
          }

          if (
            trimmedFileData.some((row) =>
              Object.keys(row).some(
                (key) =>
                  validHeaders.includes(key) === false ||
                  row[key] === '""' ||
                  row[key] !== ""
              )
            )
          ) {
            const dataWithRef = trimmedFileData.map((item: any) => ({
              ...item,
              reference: `${businessInitials}-B${Math.floor(
                Math.pow(12, 10 - 1) + Math.random() * 10 * Math.pow(12, 10 - 1)
              )}`,
            }));
            setFileData(dataWithRef);
          } else {
            Alert(
              "error",
              "Your CSV file contains invalid/empty fields, please fix and try again"
            );
            setLoading(false);
            return false;
          }
        }
      };

      reader.readAsBinaryString(file);
    });
  }, []);
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "application/vnd.ms-excel": [],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
      "application/vnd.openxmlformats-officedocument.spreadsheetml.template":
        [],
      //   'text/csv': [],
    },
    maxFiles: 1,
  });

  useEffect(() => {
    if (fileData.length) {
      setTimeout(() => {
        setLoading(false);
        navigate("/dashboard/disbursement/preview", { state: { fileData, selected, pocketID } });
      }, 5000);
    }
  }, [fileData]);

  const handleDownloadSample = () => {
    const filename = "sample.xlsx";

    const anchor = document.createElement("a");
    anchor.href =
      "https://www.dropbox.com/scl/fi/d7i07re53j18sx4plyeej/sample.xlsx?rlkey=1iemq21w3j69y4ih60dztupfy&dl=1";
    anchor.download = filename;

    document.body.appendChild(anchor);

    // Trigger the download
    anchor.click();

    // Clean up
    document.body.removeChild(anchor);
  };

  const accountType = getValues('pocketType')

  const pockets = props.allPrimaryPockets.map((prim) => ({
    value: prim.pocketId,
    label: <div className="flex items-center gap-4">
      <div className="flex gap-2 items-center w-4/12">
        <img
            src={`/flags/${
              getCountry(prim)?.flag
                ? getCountry(prim)?.flag.toLowerCase()
                : getCountry(prim)?.name.toLowerCase()
            }.gif`}
            className="h-5 w-6"
          />
          <span className="text-sm">({getCountry(prim).name.split("_").join(" ")})</span>
      </div>
        <span className="font-semibold">{prim.pocketId}</span>
    </div>
  }))

  return (
    <form
      className="py-3 mobile:p-0 mobile:rounded-[15px] max-h-[70vh] overflow-y-scroll custom_container mb-10"
      onSubmit={() => {}}
    >
      <div className="flex justify-center items-center">
        <div className="relative shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] bg-white p-10 desktop:w-[43%] w-[80%] mobile:w-full rounded-[15px] flex flex-col gap-8">
          {loading && (
            <div
              className={`absolute z-10 left-0 top-0 flex items-center py-10 justify-center rounded-[15px] bg-white bg-opacity-80 w-full h-full`}
            >
              <Spinner />
            </div>
          )}

          <div className="flex flex-col gap-2 w-full">
            <span className="font-semibold text-base leading-6 text-Black2 mx-2">
              Payout Account
            </span>
            {/* <div
              className="flex justify-between items-center gap-5 border rounded-md p-3 w-full cursor-pointer bg-Background3"
              onClick={() => {}}
            >
              <span className="text-Black2">{props.pocketId}</span>
            </div> */}
            <Select
              options={pockets}
              onChange={(o: any) => setPocketID(o.value)}
            />

          </div>

          <div className="flex flex-col gap-2 w-full">
            <span className="font-semibold text-base leading-6 text-Black2 mx-2">
            Transfer Type
            </span>
            <div className="bg-GrayCustom6 flex rounded-md overflow-hidden w-[75%] w-full ">
              <span
                className={`${
                  selected === "sub_pocket_tf" && "bg-white border"
                } flex justify-center items-center gap-3 p-3 w-full rounded-md text-base mobile:text-sm cursor-pointer w-full`}
                onClick={() => setSelected("sub_pocket_tf")}
              >
                <img
                  src={
                    selected === "sub_pocket_tf"
                      ? walletIcon_active
                      : walletIcon
                  }
                  alt="walletIcon"
                  className="w-4 h-4"
                />
                <p
                  className={`${
                    selected === "sub_pocket_tf"
                      ? "text-Black4 font-semibold"
                      : "text-GrayCustom font-normal"
                  }`}
                >
                  Sub Pocket
                </p>
              </span>
              {/* <span
                className={`${
                  selected === "bank_tf" && "bg-white border"
                } flex justify-center items-center gap-3 p-3 w-full rounded-md text-base mobile:text-sm cursor-pointer`}
                onClick={() => setSelected("bank_tf")}
              >
                <img
                  src={selected === "bank_tf" ? bankIcon_active : bankIcon}
                  alt="bankIcon"
                  className="w-4 h-4"
                />
                <p
                  className={`${
                    selected === "bank_tf"
                      ? "text-Black4 font-semibold"
                      : "text-GrayCustom font-normal"
                  }`}
                >
                  Bank Transfer
                </p>
              </span> */}
            </div>
          </div>

          { accountType !== "PRIMARY_POCKETc" && <div
            className="border border-dashed rounded-lg p-5 cursor-copy"
            {...getRootProps()}
          >
            <input {...getInputProps()} />
            <div className="flex flex-col gap-5 items-center">
              <img className="h-8 w-8" src={fileUpIcon} />
              <p>
                Drag 'n' drop some your Excel file, or click to select the file
                from your computer
              </p>
            </div>
          </div>}

          <div className="">
            <PageTitle
              pageTitle="Guidelines"
              pageTitleStyle="!font-bold !text-xl mobile:text-base leading-6"
            />
            <ul className="list-disc font-normal text-base mobile:text-sm text-SecondaryAccent ml-4">
              {/* <li>Download sample file <Link to='#' className='decoration-none hover:underline text-Accent_blue'>here</Link></li> */}
              <li>
                {`Download sample ${
                  selected === "sub_pocket_tf"
                    ? "Sub-Pocket Transfer"
                    : "Bank Transfer"
                } file `}
                {/* <button
                  className='font-bold bg-none text-blue-500'
                  onClick={handleDownloadSample}
                >
                  here
                </button> */}
                <a
                  className="font-bold bg-none text-blue-500"
                  href={
                    selected === "bank_tf"
                      ? "https://www.dropbox.com/scl/fi/d7i07re53j18sx4plyeej/sample.xlsx?rlkey=1iemq21w3j69y4ih60dztupfy&dl=1"
                      : "https://www.dropbox.com/scl/fi/bs704vl9na8m21knimvzq/sample_pocket.xlsx?rlkey=hnp5gd0ifzwmrnria15vfu42i&dl=1"
                  }
                  download
                >
                  here
                </a>
                {/* <CSVLink
                  data={csvFileHeaders}
                  className='ml-1 decoration-none hover:underline text-Accent_blue'
                >
                  here
                </CSVLink> */}
              </li>
              <li>The file must be excel</li>
              <li>
                Ensure that your excel columns match those on the sample file.
              </li>
              <li>Upload the filled sheet.</li>
            </ul>
          </div>
        </div>
      </div>
    </form>
  );
};

const mapStateToProps = (state: any) => ({
  bearerToken: state.auth.token,
  public_key: state.auth.public_key,
  fileData: state.app.fileData,
  pocketId: state.auth.pocket_data?.pocketId,
  pocketAccountInfo: state.pocket.pocketAccountInfo,
  allPrimaryPockets: state.pocket.allPrimaryPockets,
});

const mapDispatchToProps = (dispatch: any) => ({
  uploadFile: (file: any[]) => dispatch(uploadFile(file)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BulkTransfer);
