import React, { useState } from "react";
import axios from "axios";
import _ from "lodash";
import Alert from "components/atoms/Alert";
import {
  setAuthRes,
  setUserLoggedIn,
  logoutHandler,
} from "redux/auth/auth.action";
import { removeValues, setValues } from "services/storage";
import { BASE_URL, getRequest, postRequest } from "../services/http";
import { getValues } from "../services/storage";
import { store } from "../redux/store";
import { StatusType } from "types";
// const crypto = require("crypto");
// const CryptoJS = require("crypto-js");
var forge = require("node-forge");

const state = store.getState();
const bearerToken = state.auth.token;

export const appMode = () => {
  return "development";
};

export function toMoney(value: string | any) {
  var formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "NGN",
  });

  return `₦${formatter.format(value).replace("NGN", "").trim()}`;
}

export const handleException = (error: any) => {
  try {
    if (error.response) {
      if (error.response.data.message === "Invalid token") {
        Alert("info", "Token expired, reinitiate log in.");
        logoutFunc(bearerToken);
      } else {
        Alert("error", error.response.data.message);
      }
    } else {
      Alert("error", error.message);
    }
  } catch (e) {
    Alert("error", "We are unable to process your request at the moment.");
  }
};

export const commaSeparateNumber = (val: string) => {
  if (val.length > 3) {
    return (Number(val.replace(/\D/g, "")) || "").toLocaleString();
  }
  return val;
};

// export const TokenCheck = (setAuthRes, setUserLoggedIn) => {
//   const [tokenStatus, setTokenStatus] = useState(null)
//   const url = `is-token-valid?token=${getValues('token')}`

//   getRequest(url)
//   .then(res => {
//       console.log("token checker res:", res.data.data)
//       setTokenStatus(res.data.data)
//   })
//   .catch(error => {
//       handleException(error)
//   })

//   const output:any = tokenStatus ? true : logoutFunc(setAuthRes, setUserLoggedIn)

//   return {output}
// }

export const logoutFunc = async (bearerToken) => {
  try {
    const res = await axios.post(`${BASE_URL}logout?token=${bearerToken}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });
    if (res.data.code === "error") {
      Alert("error", "Something went wrong");
      logoutHandler();
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/auth/login";
    } else {
      logoutHandler();
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "/auth/login";
    }
    return res;
  } catch (error) {
    logoutHandler();
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/auth/login";
    // handleException(error);
  }
};

export const getBusinessInitials = (name): string => {
  const bnl: string[] = name.split(" ");
  let initials = bnl[0].charAt(0);
  if (bnl.length === 1) {
    initials = initials + bnl[0].charAt(1) + bnl[0].charAt(2);
  } else if (bnl.length > 1) {
    initials = initials + bnl[1].charAt(0) + bnl[1].charAt(1);
  }
  return initials.toLocaleUpperCase();
};

export function generateRandomAlphanumeric(n: number) {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let result = "";

  for (let i = 0; i < n; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    result += characters.charAt(randomIndex);
  }

  return result;
}

export const getStatus = (code: string): StatusType => {
  switch (code) {
    case "00":
      return "SUCCESSFUL";
    case "S20":
      return "PENDING";
    default:
      return "FAILED";
  }
};

export const getFileType = (fileName) => {
  return fileName.split(".").pop();
};

export const encryptPayload = (text) => {
  const h = forge.hmac.create();
  h.start("sha256", process.env.REACT_APP_SECRET_KEY);
  h.update(text);
  const byteBuffer = h.digest();

  return byteBuffer.toHex();
};
