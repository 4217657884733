import {
    TOGGLE_APP_ENV,
    TOGGLE_MODAL,
    TOGGLE_DATA_VIEW,
    SELECT_NAV_ITEM,
    STORE_NAV_KEY,
    STORE_CURRENT_PATH,
    SET_BASE_URL,
    SET_LANDING_PAGE_DATA,
    UPLOAD_FILE,
    CANCEL_FILE_UPLOAD,
    SET_POCKET_TIER,
    SET_POCKET_BAL,
    SET_BATCH_ID,
    STORE_BANK_LIST
} from './app.type'

const APP_INIT_STATE = {
    appEnv: true,
    modalState: false,
    dataView: false,
    selectedNavItem: 'home',
    navKey: '',
    currentPath: '',
    base_url: '',
    landingPage_data: null,
    fileData: null,
    bankList: null,
    pocketTier: '',
    pocketBal: '',
    currentBatchID: ''
}

const AppReducer = (state = APP_INIT_STATE, action) => {
    switch (action.type) {
        case TOGGLE_APP_ENV:
            return {
                ...state,
                appEnv: !state.appEnv
            }
        case TOGGLE_MODAL:
            return {
                ...state,
                modalState: !state.modalState
            }
        case TOGGLE_DATA_VIEW:
            return {
                ...state,
                dataView: !state.dataView
            }
        case SELECT_NAV_ITEM:
            return {
                ...state,
                selectedNavItem: action.payload
            }
        case STORE_NAV_KEY:
            return {
                ...state,
                navKey: action.payload
            }
        case SET_POCKET_TIER:
            return {
                ...state,
                pocketTier: action.payload
            }
        case SET_POCKET_BAL:
            return {
                ...state,
                pocketBal: action.payload
            }
        case STORE_CURRENT_PATH:
            return {
                ...state,
                currentPath: action.payload
            }
        case SET_BASE_URL:
            return {
                ...state,
                base_url: action.payload
            }
        case SET_LANDING_PAGE_DATA:
            return {
                ...state,
                landingPage_data: action.payload
            }
        case UPLOAD_FILE:
            return {
                ...state,
                fileData: action.payload
            }
        case CANCEL_FILE_UPLOAD:
            return {
                ...state,
                fileData: state.fileData === null
            }
        case SET_BATCH_ID:
            return {
                ...state,
                currentBatchID: action.payload
            }
        case STORE_BANK_LIST:
            return {
                ...state,
                bankList: action.payload
            }
        default:
            return state
    }
}

export default AppReducer