export const TOGGLE_APP_ENV = "TOGGLE_APP_ENV"
export const TOGGLE_MODAL = "TOGGLE_MODAL"
export const TOGGLE_DATA_VIEW = "TOGGLE_DATA_VIEW"
export const SELECT_NAV_ITEM = "SELECT_NAV_ITEM"
export const STORE_NAV_KEY = "STORE_NAV_KEY"
export const STORE_CURRENT_PATH = "STORE_CURRENT_PATH"
export const SET_BASE_URL = "SET_BASE_URL"
export const SET_LANDING_PAGE_DATA = "SET_LANDING_PAGE_DATA"
export const UPLOAD_FILE =  "UPLOAD_FILE"
export const CANCEL_FILE_UPLOAD = "CANCEL_FILE_UPLOAD"
export const SET_POCKET_TIER = "SET_POCKET_TIER"
export const SET_POCKET_BAL = "SET_POCKET_BAL"
export const SET_BATCH_ID = "SET_BATCH_ID"
export const CLEAR_BATCH_ID = "CLEAR_BATCH_ID"
export const STORE_BANK_LIST = "STORE_BANK_LIST"