import React from 'react';
import PinInput from 'react-pin-input';
import './CodeInput.css';
import useWindowSize from '../../atoms/WindowWidth.jsx'

type Props = {
  length: number,
  type: 'numeric' | 'custom',
  secret: boolean,
  initialValue?: string,
  autoSelect?: boolean,
  onComplete: (pin: string) => void,
  onChange?: (value, index) => void,
}

const loadInputStyle = (secret, windowSize) => ({
    border:'1px solid #D0D5DD',
    background:'#FFFFFF',
    borderRadius: '6px',
    color: '#747C91',
    height: windowSize <= 700 ? 30 : 48,
    width: windowSize <= 700 ? 30 : 48,
    font: `normal normal normal ${secret ? '60px' : '32px'} ${secret ? 'small-caption' : 'inherit'}`,
    fontSize: secret ? '60px !important' : '32px',
    autoFocus: true,
});

const pinStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  gap: 'mobile:20px desktop:15px'
}

const inputFocusStyle = {
    color: '#04A778',
    border: '1px solid #04A778',
}

const CodeInput: React.FC<Props> = ({ length, type, secret, initialValue, autoSelect, onComplete, onChange }: Props) => {
  const {windowWidth} = useWindowSize();
  
  return (
    <div className="flex justify-center flex-shrink-0 max-w-md">
      <PinInput
        length={length}
        type={type}
        secret={secret}
        initialValue={initialValue}
        onComplete={onComplete}
        onChange={onChange}
        inputFocusStyle={inputFocusStyle}
        inputStyle={loadInputStyle(secret, windowWidth)}
        style={pinStyle}
        autoSelect={autoSelect}
        placeholder=" "
      />
    </div>
  )
}

export default CodeInput;
