import React, { useState } from "react";
import axios from "services/axios";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/atoms/Button";
import FormInput from "components/atoms/FormInput";
import Alert from "components/atoms/Alert";
import { handleException } from "utils";


const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [loading, setLoading] = useState(false);
  const [passSent, setPassSent] = useState(false);
  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (email.length < 1) {
      setEmailErr("Please enter a valid email");
    }

    const userData = {
      username: email,
    };

    try {
      const res = await axios.post(
        `https://pocket.seerbitapi.com/pocket/forgot-password`,
        userData,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (res?.data?.responseCode === "00") {
        Alert("success", "Password Sent successfully");
        setLoading(false);
        setPassSent(true);
      }
    } catch (error) {
      setLoading(false);
      handleException(error);
    }
  };

  return (
    <>
      {!passSent ? (
        <form onSubmit={handleSubmit}>
          <span className="text-centerk flex flex-col gap-5">
            <p className="font-bold text-2xl leading-7  ">Forgot Password?</p>
            <p className="font-normal text-base leading-7 mb-5 text-Black2">
              Request a temporary password
            </p>
          </span>
          <span>
            <FormInput
              type="email"
              name="email"
              placeholder="example@gmail.com"
              inputStyle="border border-trannsparent text-Black2"
              label="Email Address"
              labelStyle="font-normal text-base l6 px-0 mx-0  text-Black2"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              validationErr={emailErr}
            />
          </span>
          <span>
            <Button
              btnType="submit"
              btnText={loading ? "Loading..." : "Reset Password"}
              btnStyle="bg-black text-white px-6 py-3 mt-12 w-full"
              onClick={() => {}}
            />
          </span>
        </form>
      ) : (
        <div>
          <span className="text-centerk flex flex-col gap-5">
            <p className="font-bold text-2xl leading-7  ">Contact your admin</p>
            <p className="font-normal text-base leading-7 mb-5 text-Black2">
              We have sent a temporary password to the Admin. Please request the
              password from the admin to Log in.
            </p>
          </span>
          <p>
            Admin didn’t get the mail?{" "}
            <span
              className="text-[#E90500] cursor-pointer"
              onClick={() => {
                setEmail("");
                setPassSent(false);
              }}
            >
              Resend email{" "}
            </span>
          </p>
          <p className="mt-5 font-bold">
            <Link
              to="/auth/login"
              className="text-[#000000] text-base font-bold hover:underline hover:transition-all"
            >
              Back to Login
            </Link>
          </p>
        </div>
      )}
    </>
  );
};

export default ForgotPassword;
