import React, { useCallback, useEffect, useRef, useState } from "react";
import PageHeader from "components/molecules/PageHeader";
import PocketCard from "components/molecules/PocketCard";
import Pagination from "../../../components/organisms/app-table/Pagination";
import AppTable from "components/organisms/app-table";
import TableDataSpan from "components/organisms/app-table/TableDataSpan";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { BASE_POCKET_URL, BASE_URL } from "services/http";
import Alert from "components/atoms/Alert";
import CopyText from "components/atoms/CopyText";
import ColorTag from "components/atoms/ColorTag";
import Spinner from "../../../components/atoms/Spinner";
import { ToCamelCase } from "components/atoms/CaseManager";
import moment from "moment";
import { handleException } from "../../../utils";
import axios from "services/axios";
import { MerchantPocket } from "components/molecules/tableExport";
import { storeNavKey } from "redux/app/app.action";
import { TrashIcon } from "components/atoms/ImagesImport";
import { getValues } from "services/storage";
// import { store } from 'redux/store'

// const state = store.getState()
// console.log("State:", state);

const searchKeys = [
  { title: "email", value: null, link: null, action: () => null },
  { title: "subPocketId", value: null, link: null, action: () => null },
  // {title:"accountNumber", value: null, link: null, action: () => null},
  // {title:"availableBalanceAmount", value: null, link: null, action: () => null},
  // { title: "reference", value: null, link: null, action: () => null },
  // {title:"parentId", value: null, link: null, action: () => null}
].sort();

const SubPockets = ({
  dataView,
  bearerToken,
  publicKey,
  storeNavKey,
  pocketId,
}) => {
  const [query, setQuery] = useState("");
  const [queryKey, setQueryKey] = useState("");
  const [loading, setLoading] = useState(true);
  const [errorState, setErrorState] = useState(false);
  const [pocketData, setPocketData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalElements, setTotalElements] = useState();
  const [totalPages, setTotalPages] = useState(Number);
  const [pageNum, setPageNum] = useState(null);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [recordsPerPage] = useState(10);

  const navigate = useNavigate();

  // const recordsPerPage = useRef(0)
  // if(dataView){
  //   recordsPerPage.current = 10
  // }else{
  //   recordsPerPage.current = 16
  // }

  console.log({ endDate });

  const defaultUrl = `${pocketId}?`;

  console.log(query, "query");

  const url =
    query.length !== 0 && (!startDate || !endDate)
      ? `${defaultUrl}${ToCamelCase(queryKey)}=${query}`
      : query.length !== 0 && startDate !== "" && endDate !== ""
      ? `${defaultUrl}${ToCamelCase(queryKey)}=${query}&startDate=${moment(
          startDate
        ).format("YYYY-MM-DD")}T00:00:00&endDate=${moment(endDate).format(
          "YYYY-MM-DD"
        )}T23:59:59`
      : startDate && endDate === ""
      ? `${defaultUrl}startDate=${moment(startDate).format(
          "YYYY-MM-DD"
        )}T00:00:00`
      : startDate === "" && endDate
      ? `${defaultUrl}endDate=${moment(endDate).format("YYYY-MM-DD")}T23:59:59`
      : startDate && endDate
      ? `${defaultUrl}startDate=${moment(startDate).format(
          "YYYY-MM-DD"
        )}T00:00:00&endDate=${moment(endDate).format("YYYY-MM-DD")}T23:59:59`
      : `${defaultUrl}`;
  // : `search?reference=primary-`

  console.log(url);

  const mainURL = `${url}&size=${recordsPerPage}&page=${
    pageNum === null ? 0 : pageNum - 1
  }`;

  console.log(mainURL);

  const getCurrentPage = (pageNumber) => {
    setPageNum(pageNumber);
    setCurrentPage(pageNumber);
  };

  const fetchDataFunc = useCallback(
    async (URL: string) => {
      setLoading(true);

      try {
        const res = await axios.get(`${BASE_POCKET_URL + URL}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearerToken}`,
            "Public-Key": `${publicKey}`,
          },
        });
        if (res.data.code === "error") {
          setLoading(false);
          Alert("error", res.data.message);
        } else {
          setLoading(false);
          const contentData = res.data.content.filter(
            (item) => item.parentId !== null
          );

          console.log("SubPocket_data:", contentData);

          // setPocketData(contentData)
          setPocketData(res.data.content);

          setTotalElements(res.data.totalElementPerPage);
          setTotalPages(res.data.totalPages);
        }
      } catch (error: any) {
        setLoading(false);
        setErrorState(true);
        handleException(error);
      }
    },
    [mainURL]
  );

  useEffect(() => {
    fetchDataFunc(mainURL);
  }, [fetchDataFunc, mainURL]);

  const handlePageChangeGrid = (pageNumber) => {
    setCurrentPage(pageNumber);
    getCurrentPage(pageNumber);
    setLoading(false);
  };

  const columns = [
    {
      label: "Name",
      render: (data) => {
        const initials = `${data.pocket.pocketOwner.firstName} ${data.pocket.pocketOwner.lastName}`
          .split(" ")
          .map((i) => i[0])
          .join("")
          .toUpperCase();
        return (
          <div className="flex items-center gap-3 ml-5">
            <span className="h-10 w-10 rounded-full bg-[#E4E7EC] font-bold flex items-center justify-center">
              {initials}
            </span>
            <div className="flex flex-col gap-0">
              <span className="text-sm">
                {`${data.pocket.pocketOwner.firstName} ${data.pocket.pocketOwner.lastName}`}
              </span>
              <span className="text-sm text-[#667085]">
                {data.pocket.pocketId}
              </span>
            </div>
          </div>
        );
      },
    },
    {
      label: "Status",
      render: (data) => (
        <TableDataSpan
          handleMouseEnter={() =>
            storeNavKey(
              `/dashboard/accounts/sub_pocket_overview/${data.pocket.pocketId}`
            )
          }
        >
          <div
            className={`text-xs font-light px-2 py-1 inline-block rounded-md ${
              data.pocket.status === "ACTIVE"
                ? "text-green-600 bg-[#ECFDF3]"
                : "text-red-600 bg-[#FEF8F8]"
            }`}
          >
            <div className="flex items-center gap-2">
              <span
                className={`h-2 w-2 rounded-full ${
                  data.pocket.status === "ACTIVE"
                    ? "bg-green-600"
                    : "bg-red-600"
                }`}
              ></span>
              <span>
                {data.pocket.status === "ACTIVE" ? "Active" : "Inactive"}
              </span>
            </div>
          </div>
        </TableDataSpan>
      ),
    },
    {
      label: "Phone number",
      render: (data) => (
        <TableDataSpan
          handleMouseEnter={() =>
            storeNavKey(
              `/dashboard/accounts/sub_pocket_overview/${data.pocket.pocketId}`
            )
          }
        >
          {data.pocket.pocketOwner.phoneNumber}
        </TableDataSpan>
      ),
    },
    {
      label: "Email Address",
      render: (data) => (
        <TableDataSpan
          handleMouseEnter={() =>
            storeNavKey(
              `/dashboard/accounts/sub_pocket_overview/${data.pocket.pocketId}`
            )
          }
        >
          {data.pocket.pocketOwner.emailAddress}
        </TableDataSpan>
      ),
    },
    {
      label: "Account Number",
      render: (data) => (
        <TableDataSpan>
          <CopyText
            text={data.vaccountnumber}
            textStyle="text-opacity-70 text-xs mr-2 inline-block item-center"
            mssgStyle="-right-3 -top-[18px]"
            imgStyle="w-4 h-4"
          />
        </TableDataSpan>
      ),
    },
    {
      label: "Date Created",
      render: (data) => (
        <TableDataSpan
          handleMouseEnter={() =>
            storeNavKey(
              `/dashboard/accounts/sub_pocket_overview/${data.pocket.pocketId}`
            )
          }
          text={moment(data.pocket.createdAt).format("MMM DD, YYYY - LTS")}
          additionalClass="opacity-70 text-sm"
        />
      ),
    },
    // {
    //   label: 'Actions',
    //   render: (data) =>
    //     <div className='flex justify-center'>
    //       <TrashIcon />
    //     </div>
    // },
  ];

  return (
    <>
      <PageHeader
        pageTitle="Accounts"
        exportTable={false}
        manageTableData={true}
        manageTableBtnText="Add pocket"
        manageTableBtnAction={() =>
          navigate("/dashboard/accounts/create_sub_pocket")
        }
        tableKeys={searchKeys}
        queryValue={query}
        setQuery={setQuery}
        setQueryKey={setQueryKey}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        tableHeaders={MerchantPocket}
        tableData={pocketData}
        csvUrl={defaultUrl}
      />

      <AppTable
        data={pocketData}
        columns={columns}
        loading={loading}
        errorState={errorState}
        itemsPerPage={recordsPerPage}
        dataLength={totalElements}
        getCurrentPage={getCurrentPage}
      />
    </>
  );
};

const mapStateToProps = (state: any) => ({
  dataView: state.app.dataView,
  bearerToken: state.auth.token,
  publicKey: state.auth.public_key,
  pocketId: state.auth.pocket_data.pocketId,
});

const mapDispatchToProps = (dispatch: any) => ({
  storeNavKey: (data: any) => dispatch(storeNavKey(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SubPockets);
