import Button from "components/atoms/Button";
import PageTitle from "components/atoms/PageTitle";
import React, { useEffect, useState } from "react";

import SendIcon from "../../../assets/svg/sendIcon.svg";
import { useNavigate } from "react-router-dom";
import AnalyticsCard from "./components/AnalyticsCard";
import PocketCard from "./components/PocketCard";
import ChartComp from "components/molecules/ChartComp";
import axios from "axios";
import { connect } from "react-redux";
import Alert from "components/atoms/Alert";
import { handleException } from "utils";
import { BASE_URL } from "../../../services/http";
import { getValues, setValues } from "services/storage";
import {
  setDashboardAnalytics,
  setPocketAccountInfo,
  setPocketDetails,
} from "redux/pocket/pocket.action";
import bankIcon from "../../../assets/svg/bank.svg";
import walletIcon from "../../../assets/icons/wallet-2.svg";
import linkIcon from "../../../assets/svg/link.svg";
import refreshIcon from "../../../assets/svg/refresh.svg";
import convertIcon from "../../../assets/svg/convertIcon.svg";
import sendIconBlack from "../../../assets/svg/sendIconBlack.svg";
import TransactionCard from "./components/TransactionCard";
import { seAlltPocketDetails } from "redux/pocket/pocket.action";
import { IAreaChartDataItem } from "types";
import AppStackedAreaChart from "components/organisms/AppStackedAreaChart";

const sampleAreaData: IAreaChartDataItem[] = [
  { name: "Jan", USD: 300, },
  { name: "Feb", USD: 150, },
  { name: "Mar", USD: 250, },
  { name: "Apr", USD: 100, },
  { name: "May", USD: 200, },
  { name: "Jun", USD: 150, },
  { name: "Jul", USD: 50, },
  { name: "Aug", USD: 450, },
  { name: "Sep", USD: 750, },
  { name: "Oct", USD: 250, },
  { name: "Nov", USD: 550, },
  { name: "Dec", USD: 250, },
];

const datasetOne = (mainData: any[]) => {
  const labels = mainData && mainData.map((item) => item.year);
  const data = mainData && mainData.map((item) => item.amount);
  const borderWidth = 1;
  const borderRadius = 0;
  const barPercentage = 0.3;
  const categoryPercentage = 1;
  const backgroundColor = ["#17191D", "#E7E9F1"];
  return {
    labels,
    data,
    borderWidth,
    borderRadius,
    barPercentage,
    categoryPercentage,
    backgroundColor,
  };
};

const Dashboard = ({
  pocket_data,
  bearerToken,
  public_key,
  primaryPocket_details,
  setPocketDetails,
  pocketAccountInfo,
  setPocketAccountInfo,
  dashboardAnalytics,
  setDashboardAnalytics,
  allPrimaryPockets,
}) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState<any>([]);
  const [accountInfo, setAccountInfo] = useState(null);
  const [fetchingAnalytics, setFetchingAnalytics] = useState(false);

  const accountType = getValues("pocketType");

  // const allPrimaryPockets = getValues("allPrimaryPockets");

  console.log(allPrimaryPockets, " ===============> allPrimaryPockets");

  const fecthPocketDetails = async (pocketId: any) => {
    setLoading(true);
    try {
      const res = await axios.get(`${BASE_URL}pocket-id/${pocketId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearerToken}`,
          "Public-Key": `${public_key}`,
        },
      });
      if (res.data.code === "error") {
        Alert("error", res.data.message);
        setLoading(false);
      } else {
        setPocketAccountInfo({ ...res?.data.data });
        setPocketDetails({ ...primaryPocket_details, ...res.data.data });
        // setLoading(false)
      }
    } catch (error) {
      setLoading(false);
      handleException(error);
    }
  };

  useEffect(() => {
    fecthPocketDetails(pocket_data?.pocketId);
  }, []);

  const getAnalytics = async () => {
    setFetchingAnalytics(true);
    try {
      const { data } = await axios.get(
        `https://pocket.seerbitapi.com/winner/dashboard/${pocket_data?.pocketId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearerToken}`,
          },
        }
      );

      await checkAllPrimaryPockets();
      setFetchingAnalytics(false);
      if (data.status === "00") {
        const details = data.PrimaryPocket || data.Cell;
        setDashboardAnalytics({ ...details });
      } else {
        Alert("error", data.message);
      }
    } catch (err: any) {
      setFetchingAnalytics(false);
      Alert("error", err.message);
    }
  };

  const checkAllPrimaryPockets = async () => {
    try {
      const res = await axios.get(
        `https://pocket.seerbitapi.com/pocket/multiple-pockets/${pocket_data?.pocketCredentials?.userName}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearerToken}`,
            "Public-Key": `${public_key}`,
          },
        }
      );

      if (res.data.code === "error") {
        Alert("error", res.data.message);
      } else {
        console.log("allPrimaryPockets:", res.data);
        seAlltPocketDetails(res.data);
        setValues("allPrimaryPockets", res.data);
      }

      return res;
    } catch (error) {
      // handleException(error);
    }
  };

  useEffect(() => {
    if (dashboardAnalytics) {
      setChartData([
        {
          id: 1,
          year: "Pay in",
          amount: parseFloat(dashboardAnalytics.totalPayIn),
        },
        {
          id: 2,
          year: "Payout",
          amount: parseFloat(dashboardAnalytics.totalPayOut),
        },
      ]);
    }
  }, [dashboardAnalytics]);

  useEffect(() => {
    if (pocket_data && dashboardAnalytics === undefined) {
      getAnalytics();
    }
  }, [pocket_data]);

  return (
    <div>
      <div className="flex justify-between mb-10">
        <PageTitle pageTitle="Dashboard" />

        {allPrimaryPockets?.length > 1 ? (
          <div className="flex">
            <Button
              btnType="button"
              btnText="Send Money"
              btnImg={sendIconBlack}
              btnImgStyle={`${
                accountType !== "PRIMARY_POCKET" && "hidden"
              } w-4 h-4`}
              btnStyle="bg-[#E4E7EC] text-black font-semibold px-5 py-2 truncate w-fit flex-shrink-0 flex-row-reverse mx-4"
              onClick={() => navigate("/dashboard/disbursement")}
            />
            <Button
              btnType="button"
              btnText="Convert Money"
              btnImg={convertIcon}
              btnImgStyle={`${
                accountType !== "PRIMARY_POCKET" && "hidden"
              } w-4 h-4`}
              btnStyle="bg-DarkBg3 text-white font-semibold px-5 py-2 truncate w-fit flex-shrink-0 flex-row-reverse"
              onClick={() => navigate("/dashboard/convert")}
            />
          </div>
        ) : (
          <Button
            btnType="button"
            btnText="Send Money"
            btnImg={SendIcon}
            btnImgStyle={`${
              accountType !== "PRIMARY_POCKET" && "hidden"
            } w-4 h-4`}
            btnStyle="bg-DarkBg3 text-white font-semibold px-5 py-2 truncate w-fit flex-shrink-0 flex-row-reverse"
            onClick={() => navigate("/dashboard/disbursement")}
          />
        )}
      </div>

      <div className="flex justify-between mb-10 items-center mobile:flex-col mobile:gap-7 mobile:items-start">
        {!pocketAccountInfo ? (
          <div
            role="status"
            className="animate-pulse flex gap-10 mobile:flex-col mobile:gap-5"
          >
            <div className="flex items-center gap-2">
              <div className="h-14 w-14 bg-gray-200 rounded"></div>
              <div className="h-6 bg-gray-200 rounded-full w-44"></div>
            </div>
            <div className="flex items-center gap-2">
              <div className="h-14 w-14 bg-gray-200 rounded"></div>
              <div className="h-6 bg-gray-200 rounded-full w-44"></div>
            </div>
          </div>
        ) : (
          <div className="flex gap-10 mobile:flex-col mobile:gap-5">
            <div className="flex items-center gap-2">
              <span className="flex items-center justify-center rounded-md text-black bg-gray-200 w-14 h-14">
                <img src={bankIcon} alt="" className="w-6 h-6" />
              </span>
              <div>
                <p className="mb-0">
                  Account number:{" "}
                  <strong>
                    {pocketAccountInfo?.pocketAccounts[0].accountNumber} (NGN)
                  </strong>
                </p>
                <p className="mt-0">
                  Bank name:{" "}
                  <strong>
                    {pocketAccountInfo?.pocketAccounts[0].bankName}
                  </strong>
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2 border-l border-gray-400 mobile:border-none mobile:pl-0 pl-4">
              <span className="flex items-center justify-center rounded-md text-black bg-gray-200 w-14 h-14">
                <img src={walletIcon} alt="" className="w-6 h-6" />
              </span>
              <div>
                <p className="mb-1">
                  Pocket ID: <strong>{pocket_data?.pocketId}</strong>
                </p>
                <a
                  href={primaryPocket_details?.fundingLink}
                  target="_blank"
                  className="bg-blue-500 self-start text-xs px-3 no-underline hover:no-underline inline-flex hover:text-white hover:bg-blue-700 active:text-white active:bg-blue-700 active:no-underline text-white py-1 gap-1 justify-center rounded-lg"
                >
                  <img src={linkIcon} alt="" />
                  <span>Funding link</span>
                </a>
              </div>
            </div>
           {/* {allPrimaryPockets?.length > 1 && <div className="flex items-center gap-2  border-l border-gray-400 pl-4">
              <span className="flex items-center justify-center rounded-md text-black bg-gray-200 w-14 h-14">
                <img src={bankIcon} alt="" className="w-6 h-6" />
              </span>
              <div>
                <div className="flex items-center">
                  <p className="mb-0">
                    Account Number: <strong>{` 0621583902 (USD)`}</strong>
                  </p>
                  <p className="mb-0 mt-0 px-5">
                    Account Name:{" "}
                    <strong>{`Seerbit Technology Limited`}</strong>
                  </p>
                </div>

                <div className="flex items-center">
                  <p className="mt-0">
                    Bank name: <strong>Wema bank</strong>
                  </p>
                  <p className="mt-0 px-5">
                    Swift Code: <strong>WEMANGLA</strong>
                  </p>
                </div>
              </div>
            </div>} */}
          </div>
        )}

        <button
          disabled={fetchingAnalytics}
          onClick={getAnalytics}
          className="px-5 py-2 text-sm font-semibold rounded-lg flex gap-2 items-center border border-gray-200 hover:bg-gray-200 hover:border-gray-300"
        >
          {fetchingAnalytics === false && (
            <img src={refreshIcon} alt="refresh" />
          )}
          {fetchingAnalytics ? "Please wait..." : "Refresh"}
        </button>
      </div>

      {fetchingAnalytics ? (
        <div className="grid grid-cols-2 mobile:grid-cols-1 gap-8">
          <div
            role="status"
            className="h-[312px] flex flex-col justify-between animate-pulse border rounded-lg p-5 border-[#E2EEFF]"
          >
            <div className="h-6 bg-gray-200 rounded-full mb-7"></div>
            <div className="flex flex-col items-center justify-center">
              <div className="h-4 bg-gray-200 rounded-full w-48 mb-4"></div>
              <div className="h-2.5 bg-gray-200 rounded-full w-48 mb-4"></div>
            </div>
          </div>
          <div
            role="status"
            className="h-[312px] flex flex-col justify-between animate-pulse border rounded-lg p-5 border-[#E2EEFF]"
          >
            <div className="h-6 bg-gray-200 rounded-full mb-7"></div>
            <div className="flex flex-col items-center justify-center">
              <div className="h-4 bg-gray-200 rounded-full w-48 mb-4"></div>
              <div className="h-2.5 bg-gray-200 rounded-full w-48 mb-4"></div>
            </div>
          </div>
          {accountType === "PRIMARY_POCKET" && (
            <div
              role="status"
              className="h-[312px] flex flex-col justify-between animate-pulse border rounded-lg p-5 border-[#E2EEFF]"
            >
              <div className="h-6 bg-gray-200 rounded-full mb-7"></div>
              <div className="flex flex-col items-center justify-center">
                <div className="h-4 bg-gray-200 rounded-full w-48 mb-4"></div>
                <div className="h-2.5 bg-gray-200 rounded-full w-48 mb-4"></div>
              </div>
            </div>
          )}
          {accountType === "PRIMARY_POCKET" && (
            <div
              role="status"
              className="h-[312px] flex flex-col justify-between animate-pulse border rounded-lg p-5 border-[#E2EEFF]"
            >
              <div className="h-6 bg-gray-200 rounded-full mb-7"></div>
              <div className="flex flex-col items-center justify-center">
                <div
                  className="h-44 w-44 rounded-full border-gray-200"
                  style={{ borderWidth: 35 }}
                ></div>
              </div>
            </div>
          )}
        </div>
      ) : accountType === "PRIMARY_POCKET" && allPrimaryPockets?.length > 1  && pocket_data?.pocketCredentials?.userName !== "" ? (
        <>
        <div className="overflow-x-scroll">
          <div className={`flex gap-8`} style={{ width: 300 * allPrimaryPockets.length }}>
            {allPrimaryPockets.map((data) => (
              <PocketCard
                title="Balance"
                subtitle="Pocket Balance"
                value={`₦${
                  Number(dashboardAnalytics?.availableBalanceAmount)
                    ?.toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,") || "_"
                }`}
                {...data}
              />
            ))}
          </div>
        </div>

          {/* <div className="flex mt-8 gap-8">
            <div className="bg-white pt-8 px-7 border border-[#E2EEFF] rounded-lg w-7/12 h-[390px]">
              <div className="mb-4 flex justify-between">
                <div className="">
                  <p className="text-[#7E869B] text-sm">1 United States Dollar equals</p>
                  <h3>NGN 1432.320</h3>
                  <p className="text-[#7E869B] text-sm">Today</p>
                </div>
              </div>
              <AppStackedAreaChart data={sampleAreaData}/>
            </div> 
            <AnalyticsCard title="Top up Information">
              <div></div>
            </AnalyticsCard>    
          </div> */}


          <div className="grid grid-cols-2 mobile:grid-cols-1 gap-8 mt-6">
            {accountType === "PRIMARY_POCKET" && (
              <TransactionCard
                title="Outlet count"
                subtitle="Active Sub pockets"
                value={dashboardAnalytics?.numberOfOutletSubPockets}
              />
            )}
            <AnalyticsCard title="Performance">
              <ChartComp
                chartType="doughnut"
                dataset={datasetOne}
                propData={chartData}
                legend="Amount"
                style="max-h-50 mt-[-90px]"
              />
            </AnalyticsCard>
          </div>
        </>
      ) : (
        <div className="grid grid-cols-2 mobile:grid-cols-1 gap-8">
          <AnalyticsCard
            title="Balance"
            subtitle="Pocket Balance"
            value={`₦${
              Number(dashboardAnalytics?.availableBalanceAmount || "0.00")
                ?.toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,") || "_"
            }`}
          />
          {accountType === "PRIMARY_POCKET" && (
            <AnalyticsCard
              title="Outlets balance"
              subtitle="Total Sub pocket Balance"
              value={`₦${dashboardAnalytics?.availableOutletSubPocketBalanceAmount || "0.00"}`}
            />
          )}
          {accountType === "PRIMARY_POCKET" && (
            <AnalyticsCard
              title="Outlet count"
              subtitle="Active Sub pockets"
              value={dashboardAnalytics?.numberOfOutletSubPockets || 0}
            />
          )}
          <AnalyticsCard title="Performance">
            <ChartComp
              chartType="doughnut"
              dataset={datasetOne}
              propData={chartData}
              legend="Amount"
              style="max-h-50 mt-[-90px]"
            />
          </AnalyticsCard>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  pocket_data: state.auth.pocket_data,
  bearerToken: state.auth.token,
  primaryPocket_details: state.pocket.primaryPocket_details,
  public_key: state.auth.public_key,
  pocketAccountInfo: state.pocket.pocketAccountInfo,
  dashboardAnalytics: state.pocket.dashboardAnalytics,
  allPrimaryPockets: state.pocket.allPrimaryPockets,
});

const mapDispatchToProps = (dispatch: any) => ({
  setPocketDetails: (data: any[]) => dispatch(setPocketDetails(data)),
  setPocketAccountInfo: (data: any) => dispatch(setPocketAccountInfo(data)),
  setDashboardAnalytics: (data: any) => dispatch(setDashboardAnalytics(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
