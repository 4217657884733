import React from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import PageTitle from 'components/atoms/PageTitle'
import { getValues } from 'services/storage'
import SettingsNav from 'components/organisms/SettingsNav'

const Settings = () => {
  return (
    <div>
      <PageTitle
        pageTitle="Settings"
        pageTitleStyle='text-left mb-5'
      />
      <div className='flex flex-col gap-5'>
        {/* <SettingsNav/> */}
        <div className='justify-center flex flex-col items-center mt-5'>
          <div className='w-full'>
            <Outlet/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Settings