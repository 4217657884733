import React from 'react';
import { connect } from 'react-redux';
import { DateRangePicker } from 'rsuite';

type Placement =
| 'bottomStart'
| 'bottomEnd'
| 'topStart'
| 'topEnd'
| 'leftStart'
| 'leftEnd'
| 'rightStart'
| 'rightEnd'
| 'auto'
| 'autoVerticalStart'
| 'autoVerticalEnd'
| 'autoHorizontalStart'
| 'autoHorizontalEnd';

type Props = {
  placeholder?: string;
  handleChange: (range: any) => void
  handleClear?: (e) => void;
  defaultRange?: [any, any];
  label?: string;
  placement?: Placement;
  maxAllowedDays?: number; 
}

const { allowedMaxDays, allowedDays, allowedRange, beforeToday, afterToday, combine } =
DateRangePicker;

const AppDateRange: React.FC<Props> = ({ placeholder, placement, handleChange, handleClear, defaultRange, label, maxAllowedDays }) => {
  return (
    <div>
      {label && <label className={`mb-3 block text-sm`}>{label}</label>}
      <div className='min-w-[215px]'>
        <DateRangePicker 
          placeholder={placeholder}
          className={`w-full h-[46px]`}
          placement={placement  || "bottomStart"}
          onChange={handleChange}
          onClean={handleClear}
          defaultValue={defaultRange}
          shouldDisableDate={maxAllowedDays ? allowedMaxDays(maxAllowedDays) : undefined}
        />
      </div>
    </div>
  )
}

export default AppDateRange;