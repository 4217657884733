import React from 'react'
import Button from './Button'

type Props = {
    promptSetting: any,
    action?: boolean,
    handleClose?: () => void,
    handleConfirm?: () => void
}

const Prompts:React.FC<Props> = ({promptSetting, action, handleClose, handleConfirm }) => {
    console.log("promptSetting.loadColor:", (promptSetting.loadColor).slice(3));

    const themeColor = (promptSetting.loadColor).slice(3)
    
    return (
        <div className={`bg-${promptSetting.BgColor} flex flex-col w-full rounded-lg overflow-hidden`}>
            <div className={`flex flex-col w-full rounded-lg overflow-hidden`}>
                <div className='flex flex-col gap-3 p-5 mobile:p-3 rounded-t-lg'>
                    <span className={`${promptSetting.iconStyle} rounded-full w-[48px] h-[48px] flex flex-shrink-0 justify-center items-center`}>
                        <img src={promptSetting.icon} alt="prompt_type" className='w-[24px] h-[24px]'/>
                    </span>
                    <div className='flex flex-col gap-3'>
                        <span className={`font-semibold text-xl leading-7 text-${themeColor} `}>{promptSetting.message}</span>
                        <span className='font-normal text-[15px] leading-6 text-Black3 mb-3'>{promptSetting.subMessage}</span>
                    </div>
                </div>
            </div>
            {action &&
                <div className='flex justify-end gap-5 p-5 mobile:p-3 pt-0'>
                    <Button
                        btnType='button'
                        btnText="Cancel"
                        btnStyle={`bg-white w-[25%] border border-${themeColor} py-2 mobile:py-1 font-semibold text-lg leading-7 rounded-lg truncate text-${themeColor}`}
                        onClick={handleClose}
                    />
                    <Button
                        btnType='button'
                        btnText="Confirm"
                        btnStyle={`${promptSetting.loadColor} w-[25%] border border-${themeColor} py-2 mobile:py-1 font-semibold text-lg leading-7 rounded-lg truncate text-white`}
                        onClick={handleConfirm}
                    />
                </div>
            }
            <div className={`flex rounded-b-lg overflow-hidden`}>
                <span className={`bg-${themeColor} ${promptSetting.load && 'animate-slide_right rounded-r-lg'} py-1 mobile:py-[2px] w-full`}></span>
            </div>
        </div>
    )
}

export default Prompts