import CopyText from 'components/atoms/CopyText';
import PageHeader from 'components/molecules/PageHeader';
import AppTable from 'components/organisms/app-table';
import React, { useEffect, useRef, useState } from 'react';
import TableDataSpan from 'components/organisms/app-table/TableDataSpan';
import moment from 'moment';
import { connect } from 'react-redux';
import PageTitle from 'components/atoms/PageTitle';
import FromCSV from 'components/organisms/app-table/FromCSV';
import Button from 'components/atoms/Button';
import { cancelFileUpload, toggleModal } from 'redux/app/app.action';
import Alert from 'components/atoms/Alert';
import { useLocation, useNavigate } from 'react-router-dom';
import AppModal from '../../../../components/organisms/CustomModal';
import Prompts from 'components/atoms/Prompts';
import infoIcon from '../../../../assets/svg/info-circle-danger.svg';
import ErrorEmptyState from 'components/atoms/ErrorEmptyState';
import Spinner from 'components/atoms/Spinner';
import { getValues } from 'services/storage';
import {
  validateAmount,
  validateCurrency,
  validateAccountNum,
  validateBankCode,
  validateDesc,
  ValidationErrMssg,
  validatePocketId
} from './ValidateCSV';
import axios from 'services/axios';
import { BASE_URL } from 'services/http';
import { handleException } from 'utils';
import { elements } from 'chart.js';
import { remove } from 'lodash';
import { PocketUserActionEnum, PocketUserEnum } from 'types';

const PreviewUpload = (props) => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageNum, setPageNum] = useState(null);
  const [recordsPerPage] = useState(7);

  const [rowValidity, setRowValidity] = useState();
  const [overAllTest, setOverAllTest] = useState('');

  const numOfValidItems = useRef('');
  const numOfInvalidItems = useRef('');

  const [showPrompt, setShowPrompt] = useState(false);
  const promptSetting = {
    icon: infoIcon,
    iconStyle: 'bg-DangerAccent4',
    BgColor: 'AppBg',
    loadColor: 'bg-Danger3',
    message: 'Terminate Transaction',
    subMessage:
      'This action would cancel the transaction process and remove all uploaded files from memory, do you wish to continue?',
  };

  const accountType = getValues('pocketType');
  
  const location: any = useLocation();

  
  const transferType = location.state.selected

  console.log(transferType)

  const fileAvailable = location?.state?.fileData ? true : false;
  useEffect(() => {
    if (fileAvailable === false) {
      navigate('/dashboard/disbursement/bulk');
    }
  }, [navigate, location]);

  useEffect(() => {
    fileAvailable && dataCheck();
  }, [fileAvailable, location]);

  const actionItemValue = accountType === PocketUserEnum.PRIMARY_POCKET ? PocketUserActionEnum.APPROVE_DISBURSEMENT_BY_HEAD_CHURCH : PocketUserActionEnum.APPROVE_DISBURSEMENT_BY_OUTLET;

  const userName = getValues('username');

  const getCurrentPage = (pageNumber) => {
    setPageNum(pageNumber);
    setCurrentPage(pageNumber);
  };

  const currentTime = moment().format('MMM DD, YYYY - LTS');

  const confirmationContent = (
    <AppModal
      modalStyle='desktop:w-[28%] w-[45%] mobile:w-[80%] min-h-fit z-30 top-0 left-0 right-0 desktop:my-[10rem] tablet:my-[8rem] mx-auto animate-center_align rounded-md'
      contentStyle='overflow-y-scroll min-h-fit w-full'
      // handleClose={()=>setShowPrompt(false)}
    >
      <Prompts
        promptSetting={promptSetting}
        action={true}
        handleClose={() => {
          setShowPrompt(false);
        }}
        handleConfirm={() => {
          props.cancelFileUpload();
          Alert('success', 'Batch transaction terminated');
          navigate('/dashboard/disbursement/bulk');
          setShowPrompt(false);
        }}
      />
    </AppModal>
  );

  const getSumation = (varObj: any[]) => {
    if (varObj) {
      let sum = varObj.reduce(function (accumulator, currentValue) {
        return accumulator + currentValue;
      }, 0);

      return sum.toFixed(2);
    }
  };

  let totalAmountArr = location?.state?.fileData.map((item) =>
    parseInt(item.amount || 0)
  );

  const totalAmount = getSumation(totalAmountArr || 0);

  const requestOTP = async (dataObj: any) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `https://pocket.seerbitapi.com/winner/sendOtp`,
        dataObj,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${props.bearerToken}`,
          },
        }
      );

      if (res.data.code === 'error') {
        setLoading(false);
        Alert('error', res.data.code);
      } else {
        setLoading(false);
        Alert('success', 'Next, Validate OTP');
        navigate('/dashboard/disbursement/validate_otp', {
          state: { fileData: location?.state?.fileData, selected: transferType, pocketID: location?.state?.pocketID },
        });
      }

      return res;
    } catch (error) {
      setLoading(false);
      handleException(error);
      Alert('error', 'Failed');
    }
  };

  const addSignatories = async (dataObj: any) => {
    setLoading(true);
    try {
      const res = await axios.post(
        `https://pocket.seerbitapi.com/winner/add-signatorye`,
        dataObj,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${props.bearerToken}`,
          },
        }
      );

      if (res.data.code === 'error') {
        setLoading(false);
        Alert('error', res.data.code);
      } else {
        Alert('success', 'Signatories Added');

        requestOTP({
          actionItem: actionItemValue,
        });
      }

      return res;
    } catch (error) {
      setLoading(false);
      handleException(error);
    }
  };

  const dataCheck = () => {
    const data = location?.state?.fileData;

    const wholeFileData = data.map((item, rowIndex) => {
      let validity = '';
      let valid = '';
      if (
        transferType === "bank_tf" &&
        validateAmount(item.amount) &&
        validateCurrency(item.currency) &&
        validateAccountNum(item.accountNumber) &&
        validateBankCode(item.bankCode) &&
        validateDesc(item.description)
      ) {
        validity = 'Valid';
        valid = 'No Issues found';

        return { validity, errorReport: valid };
      } else if (
        transferType === "sub_pocket_tf" &&
        validateAmount(item.amount) &&
        validateCurrency(item.currency) &&
        validatePocketId(item.pocketId) &&
        validateDesc(item.description)
      ) {
        validity = 'Valid';
        valid = 'No Issues found';

        return { validity, errorReport: valid };

      }else {
        validity = 'Invalid';
        const reportMssg = Object.keys(item).map((key) =>
          ValidationErrMssg(rowIndex, key, item[key])
        );

        return { validity, errorReport: reportMssg };
      }
    });

    setRowValidity(wholeFileData);

    if (wholeFileData.some((item) => item.validity === 'Invalid')) {
      Alert('error', 'File Uploaded with Errors!');
      setOverAllTest('Failed');
    } else {
      Alert('success', 'File Uploaded Successfully');
      setOverAllTest('Successful');
    }

    const rowStatusCheck_Valid = wholeFileData.filter(
      (item) => item.validity === 'Valid'
    );
    const rowStatusCheck_Invalid = wholeFileData.filter(
      (item) => item.validity === 'Invalid'
    );

    numOfValidItems.current = rowStatusCheck_Valid.length;
    numOfInvalidItems.current = rowStatusCheck_Invalid.length;

    return wholeFileData;
  };

  const handleNextEvents = () => {
    if (overAllTest === 'Failed') {
      props.cancelFileUpload();
      Alert('error', 'Please, try uploading file again');
      navigate('/dashboard/disbursement/bulk');
    } else {
      requestOTP({
        actionItem: actionItemValue,
      });

      // addSignatories({
      //     "pocketId": props.pocketId,
      //     "secondaryEmails":["henryadedugba@gmail.com"],
      //     "areaName":"suruLe"
      // })
    }
  };

//   const newFileData = location?.state?.fileData.map((p) => {
//     const obj = { ...p, fromPocket: "SBP0001551", toPocket: p.pocketId }
//     delete obj["pocketId"];
//     return obj;
//   })
  

  return (
    <div className='flex flex-col gap-2 justify-between'>
      <PageTitle pageTitle='Send Money' pageTitleStyle='font-bold' />

      {fileAvailable && (
        <div className='flex flex-col gap-4 mobile:max-h-[75vh]'>
          <div className='bg-Background3 rounded-[5px] p-4 mobile:py-2 flex flex-col justify-between gap-4 mobile:gap-2'>
            <div className='flex mobile:flex-col justify-between gap-4'>
              <div className='flex flex-col gap-3'>
                <span className='font-normal text-base mobile:text-sm leading-5 text-SecondaryAccent gap-[5px] mobile:gap-[2px] flex mobile:justify-between'>
                  <span className='w-fit break-keep'>Uploaded at</span>
                  <span className='text-DarkBg3 mobile:justify-end w-fit mobile:w-[60%] flex'>
                    <p className='text-right w-full font-semibold truncate'>
                      {currentTime}
                    </p>
                  </span>
                </span>
                <span className='font-normal text-base mobile:text-sm leading-5 text-SecondaryAccent gap-[5px] mobile:gap-[2px] flex mobile:justify-between'>
                  <span className='w-fit break-keep'>Uploaded by</span>
                  <span className='text-DarkBg3 mobile:justify-end w-fit mobile:w-[60%] flex'>
                    <p className='text-right w-full font-semibold truncate'>
                      {userName}
                    </p>
                  </span>
                </span>
              </div>
              <div className='flex flex-col gap-3'>
                <span className='font-normal text-base mobile:text-sm leading-5 text-SecondaryAccent gap-[5px] mobile:gap-[2px] flex mobile:justify-between'>
                  <span className='w-fit break-keep'>Transaction count</span>
                  <span className='text-DarkBg3 mobile:justify-end w-fit mobile:w-[60%] flex'>
                    <p className='text-right w-full font-semibold truncate'>
                      {location?.state?.fileData.length}
                    </p>
                  </span>
                </span>
                <span className='font-normal text-base mobile:text-sm leading-5 text-SecondaryAccent gap-[5px] mobile:gap-[2px] flex mobile:justify-between'>
                  <span className='w-fit break-keep'>Total Amount</span>
                  <span className='text-DarkBg3 mobile:justify-end w-fit mobile:w-[60%] flex'>
                    <p className='text-right w-full font-semibold truncate'>
                      {totalAmount || 0}
                    </p>
                  </span>
                </span>
              </div>
            </div>

            <div className='border-t-2 flex justify-start items-center gap-4 pt-3 pb-0 mobile:hidden'>
              <span className='bg-Green_Accent8 text-Success2 border border-Success2 px-[10px] py-[5px] w-fit text-center rounded-md font-normal text-base leading-5 gap-[5px] flex mobile:justify-between'>
                Valid:
                <p className='text-Success2 font-semibold'>
                  {numOfValidItems.current}
                </p>
              </span>
              <span className='bg-DangerAccent5 text-Danger4 border border-Danger4 px-[10px] py-[5px] w-fit text-center rounded-md font-normal text-base leading-5 gap-[5px] flex mobile:justify-between'>
                Invalid:
                <p className='text-Danger4 font-semibold'>
                  {numOfInvalidItems.current}
                </p>
              </span>
            </div>
          </div>

          <div className='w-full h-full'>
            {loading && (
              <div
                className={`absolute z-[19] left-0 top-0 flex items-center py-10 justify-center rounded-[15px] bg-white bg-opacity-80 w-full h-full`}
              >
                <Spinner />
              </div>
            )}

            {location?.state?.fileData.length === 0 ? (
              <ErrorEmptyState img={true} />
            ) : (
              <FromCSV
                data={location?.state?.fileData && location?.state?.fileData}
                loading={loading}
                errorState={errorState}
                currentPage={currentPage}
                recordsPerPage={recordsPerPage}
                getCurrentPage={getCurrentPage}
                rowStatus={rowValidity}
              />
            )}
          </div>
        </div>
      )}

      <div
        className={`${
          overAllTest === 'Failed' ? 'justify-end' : 'justify-between'
        } flex items-center absolute w-full -bottom-[2px] left-0 py-8 px-16 mobile:py-4 mobile:px-10 bg-white shadow-[0_8px_30px_rgb(0,0,0,0.12)]`}
      >
        <Button
          btnType='button'
          btnText='Cancel'
          btnStyle={`${
            overAllTest === 'Failed' && 'hidden'
          } rounded-lg truncate flex gap-3 justify-center items-center w-[216px] mobile:w-fit mobile:px-8 px-3 py-2 font-semibold text-sm border border-gray-200 leading-7 flex-row-reverse rounded-lg truncate`}
          disabled={false}
          disabledClass=''
          onClick={() => setShowPrompt(true)}
        />
        <Button
          btnType='button'
          btnText={overAllTest === 'Failed' ? 'Retry' : 'Get OTP'}
          btnStyle='bg-DarkBg3 text-white rounded-lg truncate flex gap-3 justify-center items-center w-[216px] mobile:w-fit mobile:px-8 px-3 py-2 font-semibold flex-row-reverse rounded-lg truncate create-button'
          disabled={loading ? true : false}
          disabledClass='bg-BackDrop_d_sm'
          onClick={handleNextEvents}
        />
      </div>

      {showPrompt && confirmationContent}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  fileData: state.app?.fileData,
  bearerToken: state.auth.token,
  public_key: state.auth.public_key,
  pocketId: state.auth.pocket_data?.pocketId,
});

const mapDispatchToProps = (dispatch: any) => ({
  cancelFileUpload: () => dispatch(cancelFileUpload()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PreviewUpload);
