import React, { useEffect, useRef } from 'react'
import { TableColumn } from 'types';
import TableCell from './TableCell';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { storeNavKey } from 'redux/app/app.action';

type TableRowProps = {
  columns: TableColumn[];
  rowData: any;
  style?: string;
  navKey?: string
  storeNavKey?: (data:any) => void
} & ReturnType<typeof mapStateToProps>;

const TableRow: React.FC<TableRowProps> = ({ columns, rowData, style, navKey, storeNavKey}) => {
  const navigate = useNavigate()

  const handleClick = () => {
    console.log("navKey:", navKey)
    
    navigate(navKey)
    storeNavKey && storeNavKey('')
  }

  return (
    <tr className={`${style} border-b border-gray-100 hover:bg-GrayCustom3 hover:bg-opacity-30 transition-all cursor-pointer`} onClick={handleClick}>
      {columns.map((column, columnIndex) => (
        <TableCell
          key={columnIndex}
          column={column}
          rowData={rowData}
        />
      ))}
    </tr>
  );
};

const mapStateToProps = (state: any) => ({
  navKey: state.app.navKey
});

const mapDispatchToProps = (dispatch:any) => ({
  storeNavKey: (data: any) => dispatch(storeNavKey(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(TableRow);