import React, { useState } from "react";
import Button from "./Button";

type Props = {
  type: string;
  name: string;
  autoComplete?: "on" | "off";
  placeholder?: string;
  style?: string;
  inputStyle?: string;
  inputStyle2?: string;
  inputContainerStyle?: string;
  label?: string;
  labelStyle?: string;
  value?: string;
  img?: string;
  icon?: string;
  imgStyle?: string;
  imgOnClick?: (e?: any) => void;
  min?: number;
  max?: number;
  disabled?: boolean;
  required?: boolean;
  btnElement?: boolean;
  btnType?: "button" | "submit" | "reset" | undefined;
  btnText?: string;
  btnIcon?: any;
  btnStyle?: string;
  validationErr?: string;
  onClick?: () => void;
  currency?: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

const FormInput = React.forwardRef(
  (
    {
      label,
      labelStyle,
      autoComplete,
      type,
      name,
      placeholder,
      style,
      inputStyle,
      inputStyle2,
      inputContainerStyle,
      value,
      required,
      img,
      imgStyle,
      imgOnClick,
      min,
      max,
      validationErr,
      disabled,
      onChange,

      btnElement,
      btnType,
      btnText,
      btnIcon,
      btnStyle,
      onClick,
      icon,
      currency
    }: Props,
    ref
  ) => {
    const btnContent = (
      <Button
        btnType={btnType}
        btnText={btnText}
        btnIcon={btnIcon}
        btnStyle={btnStyle}
        onClick={onClick}
      />
    );

    // const [showError, setShowError] = useState(false)

    // if(validationErr !== ''){
    //     set
    // }

    return (
      <div className={`a ${style} flex flex-col`}>
        {label && <label className={`${labelStyle} my-2`}>{label}</label>}
        {btnElement && img ? (
          <div className={`${inputContainerStyle} flex`}>
            <span
              className={`${inputStyle} flex items-center w-3/4 rounded-md overflow-hidden relative`}
            >
              <img
                src={img}
                alt="icon"
                className={`${imgStyle} absolute right-5`}
                onClick={imgOnClick}
              />
              <input
                type={type}
                name={name}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                min={min}
                max={min}
                required={required}
                className={`${inputStyle2} py-2 bg-NoColor outline-none w-full`}
                disabled={disabled}
                autoComplete={autoComplete}
              />
            </span>
            {btnContent}
          </div>
        ) : btnElement && !img ? (
          <div className={`${inputContainerStyle} flex`}>
            <span className={`${inputStyle} rounded-md overflow-hidden w-3/4`}>
              <input
                type={type}
                name={name}
                placeholder={placeholder}
                onChange={onChange}
                value={value}
                min={min}
                max={min}
                required={required}
                className={`${inputStyle2} w-full bg-NoColor outline-none py-2 px-2`}
                disabled={disabled}
                autoComplete={autoComplete}
              />
            </span>
            {btnContent}
          </div>
        ) : !btnElement && img ? (
          <span
            className={`d-span flex ${inputStyle} items-center rounded-md overflow-hidden relative`}
          >
            <img
              src={img}
              alt="icon"
              className={`${imgStyle} absolute right-5`}
              onClick={imgOnClick}
            />
            <input
              type={type}
              name={name}
              placeholder={placeholder}
              onChange={onChange}
              value={value}
              min={min}
              max={min}
              required={required}
              className={`${inputStyle2} w-full bg-NoColor outline-none py-2 px-2`}
              disabled={disabled}
              autoComplete={autoComplete}
            />
          </span>
        ) : (
          <span
            className={`e-span ${inputStyle} rounded-md overflow-hidden flex items-center ${
              icon ? "py-1" : ""
            }`}
          >
            <input
              type={type}
              name={name}
              placeholder={placeholder}
              onChange={onChange}
              value={value}
              min={min}
              max={min}
              required={required}
              className={`${inputStyle2} w-full bg-NoColor outline-none py-2 px-2`}
              disabled={disabled}
              autoComplete={autoComplete}
            />
            {icon && (
              <div className="bg-[#F0F0F0] p-1 mx-1 px-3 rounded-[50px] flex items-center justify-center">
                <img
                  src={`/flags/${icon}.gif`}
                  className="w-[16px] h-[16px] rounded-[100%] p-0 m-0"
                />
                <p className="m-0 p-0 pl-1 font-semiboldf text-[#000]">{currency}</p>
              </div>
            )}
          </span>
        )}
        {validationErr !== "" && (
          <div className="f-span text-right mt-2">
            <span className="text-xs font-medium text-red-500">
              {validationErr}
            </span>
          </div>
        )}
      </div>
    );
  }
);

export default FormInput;
