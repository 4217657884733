import React, { useState, useCallback } from "react";
import Button from "components/atoms/Button";
import FormInput from "components/atoms/FormInput";
import RadioAndCheckInput from "components/atoms/RadioAndCheckInputGroup/RadioAndCheckInput";
import Toggle from "components/atoms/Toggle";
import { connect } from "react-redux";
import { toggleAppEnv } from "redux/app/app.action";
import { getValues } from "services/storage";
import profileEdit from "../../../assets/svg/user-edit-black.svg";
import AppModal from "../../../components/organisms/CustomModal";
import showPwd from "../../../assets/svg/show.svg";
import hidePwd from "../../../assets/svg/hide.svg";
import Alert from "components/atoms/Alert";
import { BASE_URL, username } from "services/http";
import axios from "axios";
import { handleException } from "../../../utils";
import Spinner from 'components/atoms/Spinner'
// import { store } from '../../../redux/store'

// const state = store.getState()
// console.log("State:", state.pocket)

const checkboxData = [{ label: "Are you a developer" }];

const UserProfile = ({
  appEnv,
  toggleAppEnv,
  user_data,
  primaryPocketData,
  bearerToken,
  publicKey,
  pocketDetails
}) => {
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [passwordErr, setPasswordErr] = useState("");

  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState(false);

  console.log(
    "primaryPocketData?.pocketOwner:",
    primaryPocketData?.pocketOwner
  );

  const username = primaryPocketData?.pocketOwner?.username;
  const default_firstname = primaryPocketData?.pocketOwner?.firstName;
  const default_lastname = primaryPocketData?.pocketOwner?.lastName;
  const default_email = primaryPocketData?.pocketOwner?.emailAddress;
  const default_phoneNumber = primaryPocketData?.pocketOwner?.phoneNumber;

  const handleReset = () => {
    setFirstname("");
    setLastname("");
    setEmail("");
    setPhone("");
  };

  const mainURL = `user/change-password`;

  const ChangePasswordFunc = useCallback(async () => {
    if (!newPassword || !confirmPassword || !password) {
      Alert("error", "Incomplete Form data input");
      return;
    }

    if (newPassword !== confirmPassword) {
      Alert("error", "Password do not Match");
      return;
    }

    setLoading(true);
    try {
      const res = await axios.post(
        `${BASE_URL + mainURL}`,
        {
          currentPassword: password,
          newPassword: newPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearerToken}`,
            "Public-Key": `${publicKey}`,
          },
        }
      );
      if (res.data.code === "error") {
        setLoading(false);
        Alert("error", res.data.message);
      } else {
        setLoading(false);
        Alert("success", res.data.message);
        setShowModal(false)
      }
    } catch (error) {
      setLoading(false);
      setErrorState(true);
      handleException(error);
    }
  }, [password, newPassword, confirmPassword]);


  return (
    <>
      <div className="flex flex-col gap-1 mb-12">
        <span className="text-lg font-normal text-black leading-5">
          Your Personal Information
        </span>
        <span className="text-[15px] font-normal leading-5 text-DarkBg6">
          Update your personal and contact information.
        </span>
      </div>
      <div className="flex flex-col gap-5 w-full desktop:w-[715px]">
        <div className="flex flex-col gap-5 w-full">
          <span className="bg-GrayCustom11 w-[96px] h-[96px] rounded-full flex justify-center items-center">
            <img src={profileEdit} alt="profile_icon" className="" />
          </span>
          <form className="flex flex-col gap-5">
            <div className="flex mobile:flex-col gap-5 justify-between items-center">
              <FormInput
                type="text"
                name="firstname"
                placeholder="Firstname"
                inputStyle="border border-transparent text-Black2"
                label="First name"
                labelStyle="font-normal text-base leading-6 text-DarkBg6"
                style="w-full"
                onChange={(e) => setFirstname(e.target.value)}
                value={firstname === "" ? default_firstname : firstname}
                validationErr=""
                disabled={true}
              />
              <FormInput
                type="text"
                name="lastname"
                placeholder="Lastname"
                inputStyle="border border-transparent text-Black2"
                label="Last name"
                labelStyle="font-normal text-base leading-6 text-DarkBg6"
                style="w-full"
                onChange={(e) => setLastname(e.target.value)}
                value={lastname === "" ? default_lastname : lastname}
                validationErr=""
                disabled={true}
              />
            </div>
            <div className="flex mobile:flex-col gap-5 justify-between items-center">
              <FormInput
                type="email"
                name="email"
                placeholder="example@gmail.com"
                inputStyle="border border-transparent text-Black2"
                label="Email address"
                labelStyle="font-normal text-base leading-6 text-DarkBg6"
                style="w-full"
                onChange={(e) => setEmail(e.target.value)}
                value={email === "" ? default_email : email}
                disabled={true}
                validationErr=""
              />
              <FormInput
                type="text"
                name="phone"
                placeholder="+234"
                inputStyle="border border-transparent text-Black2"
                label="Phone number"
                labelStyle="font-normal text-base leading-6 text-DarkBg6"
                style="w-full"
                onChange={(e) => setPhone(e.target.value)}
                value={phone === "" ? default_phoneNumber : phone}
                validationErr=""
                disabled={true}
              />
            </div>
            <div className="flex flex-col gap-8">
              {/* <span className="w-full flex flex-col justify-between">
                <RadioAndCheckInput
                  type="checkbox"
                  name="currency"
                  inputData={checkboxData}
                  checkStyle=""
                />
              </span> */}
            </div>
          </form>
        </div>
        <div className="">
          <div className="border-b pb-2">
            <span className="text-lg">Profile security</span>
          </div>
          <div className="flex flex-col gap-12">
            <div className="flex items-center justify-start gap-24 mobile:gap-5 py-5">
              <span>
                <p className="font-normal text-[15px] leading-5 text-black">
                  Change your password
                </p>
                <p className="font-normal text-xs leading-6 text-Black4">
                  You can change your password here easily
                </p>
              </span>
              <Button
                onClick={() => setShowModal(true)}
                btnType="button"
                btnText="Change Password"
                btnStyle="bg-Accent_blue text-SecondaryAccent5 font-semibold text-sm mobile:text-xs leading-7 px-4 py-2 !rounded-lg mobile:w-full truncate"
              />
            </div>
            {/* <div className="flex justify-center items-center gap-5">
              <Button
                btnType="button"
                btnText="Cancel"
                btnStyle="bg-SecondaryAccent3 text-black font-semibold text-sm leading-7 px-5 py-1 !rounded-lg mobile:w-full"
                onClick={handleReset}
              />
              <Button
                btnType="button"
                btnText="Save changes"
                btnStyle="bg-black text-SecondaryAccent5 font-semibold text-sm leading-7 px-5 py-1 !rounded-lg mobile:w-full"
              />
            </div> */}
          </div>
        </div>
      </div>
      {showModal && (
        <AppModal
          modalStyle="desktop:w-[40%] w-3/5 mobile:w-4/5 min-h-fit z-30 top-0 left-0 right-0 p-4 my-[10rem] mobile:my-[5rem] mx-auto animate-center_align rounded-md"
          contentStyle="overflow-y-scroll min-h-fit w-full"
          handleClose={() => setShowModal(false)}
        >
          <div className="text-center bg-white p-10 w-full rounded-lg">
            <h5>Change Password</h5>

            <FormInput
              type={showPassword ? "text" : "password"}
              name="passwd"
              placeholder="********"
              inputStyle="border border-transparent text-Black2 flex-row-reverse"
              label="Current Password"
              labelStyle="font-normal text-left text-Black2 mx-0 mt-6"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              validationErr={passwordErr}
              img={showPassword ? showPwd : hidePwd}
              imgStyle="cursor-pointer w-5 h-5 block"
              imgOnClick={() => setShowPassword((prevState) => !prevState)}
            />
            <FormInput
              type={showPassword ? "text" : "password"}
              name="passwd"
              placeholder="********"
              inputStyle="border border-transparent text-Black2 flex-row-reverse"
              label="New Password"
              labelStyle="font-normal text-left text-Black2 mx-0 mt-6"
              onChange={(e) => setNewPassword(e.target.value)}
              value={newPassword}
              validationErr={passwordErr}
              img={showPassword ? showPwd : hidePwd}
              imgStyle="cursor-pointer w-5 h-5 block"
              imgOnClick={() => setShowPassword((prevState) => !prevState)}
            />
            <FormInput
              type={showPassword ? "text" : "password"}
              name="passwd"
              placeholder="********"
              inputStyle="border border-transparent text-Black2 flex-row-reverse"
              label="Confirm Password"
              labelStyle="font-normal text-left text-Black2 mx-0 mt-6"
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
              validationErr={passwordErr}
              img={showPassword ? showPwd : hidePwd}
              imgStyle="cursor-pointer w-5 h-5 block"
              imgOnClick={() => setShowPassword((prevState) => !prevState)}
            />
            <Button
              onClick={ChangePasswordFunc}
              btnType="button"
              btnText={loading ? (
                <Spinner/>      // <Spinner text="Loading..." textStyle="text-white" />
            ) : "Change Password"}
              btnStyle="mt-10 bg-black text-SecondaryAccent5 font-semibold text-sm leading-7 px-5 py-1 !rounded-lg mobile:w-full"
              disabled={loading}
              
            />
          </div>
        </AppModal>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  appEnv: state.app.appEnv,
  user_data: state.auth.user_data,
  primaryPocketData: state.pocket.primaryPocket_details,
  bearerToken: state.auth.token,
  publicKey: state.auth.public_key,
  pocketDetails: state.auth.primaryPocket_details
});

const mapDispatchToProps = (dispatch) => ({
  toggleAppEnv: () => dispatch(toggleAppEnv()),
});

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
