import React, { useEffect, useState } from 'react';
import Button from '../../../../components/atoms/Button';
import PageTitle from '../../../../components/atoms/PageTitle';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'services/axios';
import Alert from 'components/atoms/Alert';
import { toggleModal } from 'redux/app/app.action';
import { connect } from 'react-redux';
import { handleException } from 'utils';
import Spinner from 'components/atoms/Spinner';
import { getValues } from 'services/storage';
import { PocketUserActionEnum, PocketUserEnum } from 'types';

const TransactionDetails = (props) => {
	const [loading, setLoading] = useState(false);
	const [chargeData, setChargeData] = useState<any>();
	const navigate = useNavigate();

	const location: any = useLocation();
	const fileAvailable = location?.state?.fileData ? true : false;

	const accountType = getValues('pocketType');

	const actionItemValue =
		accountType === PocketUserEnum.PRIMARY_POCKET
			? PocketUserActionEnum.APPROVE_DISBURSEMENT_BY_HEAD_CHURCH
			: PocketUserActionEnum.APPROVE_DISBURSEMENT_BY_OUTLET;

	useEffect(() => {
		if (fileAvailable === false) {
			navigate('/dashboard/disbursement/single');
		}
	}, [navigate, location]);

	console.log(location?.state);

	const checkBalance = async () => {
		setLoading(true);

		try {
			const res = await axios.post(
				'https://pocket.seerbitapi.com/pocket/payout/check',
				{
					pocketId:
						location?.state?.fileData.payoutAccount ||
						location?.state?.pocketID,
					amount: Number(location?.state?.fileData.amount),
					currency: location?.state?.fileData.currency || 'NGN',
				},
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${props.bearerToken}`,
					},
				}
			);

			if (res.data.status == '00') {
				setChargeData(res.data.data);
				setLoading(false);
				return true;
			} else {
				setChargeData(res.data.data);
				setLoading(false);
				Alert('error', res?.data?.message);
				return false;
			}
		} catch (e: any) {
			console.log(e);
			Alert('error', e?.response?.data?.message);
			setLoading(false);
			return false;
		}
	};

	const requestOTP = async (dataObj: any) => {
		if (!(await checkBalance())) {
			return;
		}
		setLoading(true);
		try {
			const res = await axios.post(
				`https://pocket.seerbitapi.com/winner/sendOtp`,
				dataObj,
				{
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${props.bearerToken}`,
					},
				}
			);

			if (res.data.code === 'error') {
				setLoading(false);
				Alert('error', res.data.code);
			} else {
				setLoading(false);
				Alert('success', 'Next, Validate OTP');
				navigate('/dashboard/disbursement/validate_otp_single', {
					state: {
						fileData: location?.state?.fileData,
						mode: location?.state?.mode,
					},
				});
			}

			return res;
		} catch (error) {
			setLoading(false);
			handleException(error);
			Alert('error', 'Failed');
		}
	};

	useEffect(() => {
		checkBalance();
	}, []);

	return (
		<div className="flex flex-col gap-5 justify-between">
			<PageTitle pageTitle="Transaction details" pageTitleStyle="font-bold" />

			<div className="py-5 mobile:p-0 rounded-[15px] min-h-[70vh] mobile:min-h-fit mobile:my-[4rem] mobile:rounded-[10px] overflow-y-scroll custom_container mb-10 flex justify-center">
				<div className="flex flex-col gap-10 my-[3rem]">
					<div className="relative flex flex-col gap-16 items-center w-[515px] mobile:w-full min-h-[350px] mobile:min-h-[200px] p-10 mobile:p-5 bg-white rounded-[10px] shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px]">
						{loading && (
							<div
								className={`absolute z-10 left-0 top-0 flex items-center py-10 justify-center rounded-[15px] bg-white bg-opacity-80 w-full h-full`}
							>
								<Spinner />
							</div>
						)}
						<div className="flex justify-center w-full">
							<div className="text-center">
								<span className="text-center font-normal text-base w-full break-words">
									You’re about to send{' '}
									<b>
										{location?.state?.fileData.currency || 'NGN'}{' '}
										{location?.state?.fileData.pocketBalance ||
											location?.state?.fileData.amount}
									</b>{' '}
									to the details as shown below
								</span>
								<div className="font-bold text-lg leading-[21.6px] mb-1 text-center my-4">
									Account Balance:{' '}
									{props.dashboardAnalytics?.availableBalanceAmount}
								</div>
							</div>
						</div>
						<div className="w-full flex flex-col gap-5">
							<span className="font-bold text-lg leading-[21.6px] mb-5 text-center">
								Transfer Details
							</span>
							<div className="flex flex-col items-center gap-5 w-full">
								{
									<span className="flex justify-between items-center gap-5 w-full">
										<p className="text-slate-500 text-sm font-normal leading-tight">
											{location?.state?.mode === 'sub_pocket_tf'
												? 'Sub Pocket ID'
												: location?.state?.mode === 'mobile_money'
												? 'Phone Number'
												: 'Account Number'}
										</p>
										<p className="text-black text-base font-normal">
											{location?.state?.fileData.destination ||
												location?.state?.fileData.mobileNumber}
										</p>
									</span>
								}
								{location?.state?.mode === 'mobile_money' && (
									<span className="flex justify-between items-center gap-5 w-full">
										<p className="text-slate-500 text-sm font-normal leading-tight">
											Network
										</p>
										<p className="text-black text-base font-normal">
											{location?.state?.fileData?.network.value}
										</p>
									</span>
								)}
								{location?.state?.mode === 'bank_tf' && (
									<span className="flex justify-between items-center gap-5 w-full">
										<p className="text-slate-500 text-sm font-normal leading-tight">
											Bank Name
										</p>
										<p className="text-black text-base font-normal">
											{location?.state?.fileData.bankName}
										</p>
									</span>
								)}
								{location?.state?.mode === 'bank_tf' && (
									<span className="flex justify-between items-center gap-5 w-full">
										<p className="text-slate-500 text-sm font-normal leading-tight">
											Account Name
										</p>
										<p className="text-black text-base font-normal">
											{location?.state?.fileData.accountName}
										</p>
									</span>
								)}
								{location?.state?.mode !== 'mobile_money' && (
									<span className="flex justify-between items-center gap-5 w-full">
										<p className="text-slate-500 text-sm font-normal leading-tight">
											Transaction Reference
										</p>
										<p className="text-black text-base font-normal">
											{location?.state?.fileData.transactionRef}
										</p>
									</span>
								)}
								{/* <span className="flex justify-between items-center gap-5 w-full">
                  <p className="text-slate-500 text-sm font-normal leading-tight">
                    Description
                  </p>
                  <p className="text-black text-base font-normal">
                    {location?.state?.fileData.transactionPurpose}
                  </p>
                </span> */}
								<span className="flex justify-between items-center gap-5 w-full">
									<p className="text-slate-500 text-sm font-normal leading-tight">
										Charge
									</p>
									<p className="text-black text-base font-normal">
										{chargeData?.transactionCharge
											? `${location?.state?.fileData.currency || 'NGN'} ${
													chargeData?.transactionCharge
											  }`
											: 'N/A'}
									</p>
								</span>
								<span className="flex justify-between items-center gap-5 w-full">
									<p className="text-slate-500 text-sm font-normal leading-tight">
										Total Charge Amount
									</p>
									<p className="text-black text-base font-normal">
										{chargeData?.totalTransactionAmount
											? `${location?.state?.fileData.currency || 'NGN'} ${
													chargeData?.totalTransactionAmount
											  }`
											: location?.state?.fileData.amount}
									</p>
								</span>
							</div>
						</div>

						<Button
							btnType="button"
							btnText="Proceed to get OTP"
							btnStyle="bg-DarkBg3 text-white rounded-lg truncate flex gap-3 justify-center items-center w-[216px] mobile:w-fit mobile:px-8 px-3 py-2 font-semibold flex-row-reverse rounded-lg truncate create-button"
							onClick={() => requestOTP({ actionItem: actionItemValue })}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

const mapStateToProps = (state: any) => ({
	fileData: state.app?.fileData,
	bearerToken: state.auth.token,
	public_key: state.auth.public_key,
	modalState: state.app.modalState,
	pocketId: state.auth.pocket_data?.pocketId,
	pocketAccountInfo: state.pocket.pocketAccountInfo,
	dashboardAnalytics: state.pocket.dashboardAnalytics,
});

const mapDispatchToProps = (dispatch: any) => ({
	toggleModal: () => dispatch(toggleModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(TransactionDetails);
