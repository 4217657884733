import React, {useEffect, useState} from 'react'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'

type Props = {
    title:string, 
    active:boolean,
    link:string,
    icon?: string,
    iconActive?: string,
    styleType?: string,
    onClick: (e) => void
}

const SubNavItem: React.FC<Props> = ({title, active, link, icon, iconActive, styleType, onClick}) => {
    const [style, setStyle] = useState(active)
    
    const LoadItemStyle = (isActive) => {
        const standardStyle = `${styleType ? 'border-none' : 'border rounded-lg min-w-[120px]'} px-[14px] py-[6px] decoration-none underline-none`
        const activeClassName = `${styleType ? 'border-b border-PrimnaryActive' : 'bg-gradient-to-b from-GrayCustom to-DarkBg3 shadow border-opacity-20'} transition-all`

        setStyle(isActive)
        
        if(isActive){
            return `${activeClassName + standardStyle}`
        }else{
            return `${standardStyle}`
        }
    }

    return (
        <NavLink to={link} className={({ isActive }) => LoadItemStyle(isActive)} onClick={onClick} style={{ textDecoration: 'none' }}>
            <div className={`${style ? `${styleType ? 'text-PrimnaryActive font-normal' : 'text-white font-bold'}` : 'text-DarkBg3 font-normal'} truncate desktop:text-lg desktop:leading-[30px] tablet:text-[15px] tablet:leading-[20px] flex justify-center items-center gap-3 transition-all`}>
                {icon && <img src={style ? iconActive : icon} className='w-4 h-4'/>}
                {title}
            </div>
        </NavLink>
    )
}

export default SubNavItem