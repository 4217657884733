import React, { useCallback, useEffect, useState } from 'react'
import Button from 'components/atoms/Button'
import {TransactionsData} from '../../../mockData/TransactionsData'
import printerIcon from '../../../assets/svg/printer.svg'
import copyIcon from '../../../assets/svg/copy.svg'
import mastercard from '../../../assets/svg/mastercard.svg'
import nigeriaIcon from '../../../assets/svg/nigeria_flag.svg'
import PageTitleHeader from 'components/molecules/PageTitleHeader'
import { useParams } from 'react-router-dom'
import moment from 'moment'
import ColorTag from 'components/atoms/ColorTag'
import CopyText from 'components/atoms/CopyText'
import {printContent, printStyled} from '../../../components/atoms/PrintFunction'
import {handleDownload} from '../../../components/atoms/DownloadFunction'
import { handleException } from 'utils'
import axios from 'services/axios'
import { BASE_URL } from 'services/http'
import { connect } from 'react-redux'
import Alert from 'components/atoms/Alert'
import ReceiptOutline from '../../../assets/svg/receipt-icon.svg'
import { store } from '../../../redux/store'
import Spinner from 'components/atoms/Spinner'

const state = store.getState()
// console.log("State_data:", state)

const TransactionOverview = (props) => {
    const [loading, setLoading] = useState(false)
    const [errorState, setErrorState] = useState(false)
    const [data, setData] = useState<any>({});

    const transactionRef = useParams()

    const url = `transaction/reference/${transactionRef.id}`

    // console.log("transactionRef:", transactionRef)

    const fetchTrasactionData = useCallback(async () => {
        setLoading(true)
        try{
            const res = await axios.get(`${BASE_URL + url}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${props.bearerToken}`,
                    "Public-Key": `${props.publicKey}`
                }
            })
            if(res.data.code === 'error'){
                setLoading(false)
                Alert('error', res.data.message)
            }else{
                setLoading(false)
                setData(res.data.data)
            }
            return res;
        }catch(error){
            setLoading(false)  
            setErrorState(true)
            handleException(error)
        }
    }, [])

    // console.log("pocketDetail:", props.pocketDetail);
    
    useEffect(() => {
        fetchTrasactionData()
    }, [])

    const elementId = document.getElementById('printable_content');

    const multipleBtnData = [
        {btnText:"Print Receipt", btnStyle:"bg-SecondaryAccent2 flex-row-reverse px-4 py-2 font-semibold desktop:text-sm mobile:text-xs text-DarkBg3 truncate flex-shrink-0", btnImg: printerIcon, btnImgStyle:"w-4 h-4", onClick:() => printContent(elementId)},
        {btnText:"Download Receipt", btnStyle:"bg-DarkBg3 px-10 py-2 font-semibold desktop:text-sm mobile:text-xs text-white flex-shrink-0", btnImg:"", btnImgStyle:"", onClick: () => handleDownload(elementId, elementId)}
    ]

    const currency = data.transactionCurrency === 'NGN' 
                        ? '₦' 
                        : data.transactionCurrency 

    // console.log("Transaction Data:", data)

    return (
        <div className='relative'>
        {loading
            ?    <div className={`absolute z-20 flex items-center py-10 justify-center w-full h-[90vh]`}>
                    <Spinner
                        text="Fetching data..."
                    />
                </div> 
            
            :   <div className='py-5 flex flex-col gap-5 tablet:gap-8'>
                    <PageTitleHeader 
                        title="Transaction Overview"
                        btn_num={0}
                        // btnData={multipleBtnData} 
                    />
                    <div className='flex tablet:flex-col gap-4 tablet:gap-10 rounded-md w-full deskop:p-10'>
                        <div id="printable_content" className='flex flex-col tablet:flex-col desktop:w-1/2 w-full gap-10 px-5'>
                            <div className='flex w-full justify-start mobile:justify-center'>
                                <div className='w-full'>
                                    <span className='text-[15px] leading-[30px] text-slate-500'>Amount</span>
                                    
                                    <div className='flex gap-3 items-center'>
                                        <span className='font-bold text-[28px] mobile:text-xl leading-7 text-DarkBg3'><span className='text-zinc-950 text-base leading-[30px]'>{currency}</span> {" " + (Intl.NumberFormat().format(data.transactionAmount))}</span>
                                        <span className={`${data.transactionLeg === 'CREDIT' ? 'bg-Green_Accent8 text-Success2' : 'bg-DangerAccent5 text-Danger4'} rounded-d px-2 py-1 text-xs leading-[15.6px] font-semibold`}>{data.transactionLeg}</span>
                                    </div>
                                    
                                    <div className='mb-12 mt-8 flex flex-col gap-3'>
                                        <span className='text-[15px] font-normal leading-7 text-DarkBg3'>{moment(data.createdAt).format("MMM DD, YYYY - LTS")}</span>
                                        <Button 
                                            btnType="button"
                                            btnText='Receipt'
                                            btnStyle="bg-SecondaryAccent5 text-DarkBg3 flex gap-3 justify-center items-center w-fit px-8 py-2 font-semibold text-base leading-7 flex-row-reverse rounded-lg truncate" 
                                            btnImg={ReceiptOutline}
                                            btnImgStyle='w-[18px] h-[18px]'
                                            disabled={false} 
                                            disabledClass=""
                                            onClick={() => printContent(elementId)}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className='w-full flex justify-end mobile:justify-center border-b-2 pb-6'>
                                <div className='flex flex-col w-full'>
                                    <span className='font-bold text-lg leading-[21.6px] mb-5'>Paymennt Details</span>
                                    <div className='flex flex-col gap-5'>
                                        <span className='flex gap-5 justify-between items-center'>
                                            <p className='text-slate-500 text-sm font-normal leading-tight'>Transaction currency</p>
                                            <p className='text-black text-base font-normal'>{data.currency}</p>
                                        </span>
                                        <span className='flex gap-5 justify-between items-center'>
                                            <p className='text-slate-500 text-sm font-normal leading-tight'>Transaction ID</p>
                                            <p className='text-black text-base font-normal'>{data.transactionId}</p>
                                        </span>
                                        <span className='flex gap-5 justify-between items-center'>
                                            <p className='text-slate-500 text-sm font-normal leading-tight'>Transaction type</p>
                                            <p className='text-black text-base font-normal'>{data.transactionType}</p>
                                        </span>
                                        <span className='flex gap-5 justify-between items-center'>
                                            <p className='text-slate-500 text-sm font-normal leading-tight'>Reference</p>
                                            <p className='text-black text-base font-normal'>{data.reference}</p>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='w-full flex justify-end mobile:justify-center border-b-2 pb-6'>
                                <div className='flex flex-col w-full'>
                                    <span className='font-bold text-lg leading-[21.6px] mb-5'>Balances</span>
                                    <div className='flex flex-col gap-5'>
                                        <span className='flex gap-5 justify-between items-center'>
                                            <p className='text-slate-500 text-sm font-normal leading-tight'>Pocket Balance</p>
                                            <p className='text-black text-base font-normal'>
                                                <span className='text-zinc-950 text-base leading-[30px]'>{currency}</span> {props.pocketBal}
                                            </p>
                                        </span>
                                        <span className='flex gap-5 justify-between items-center'>
                                            <p className='text-slate-500 text-sm font-normal leading-tight'>Transaction description</p>
                                            <p className='text-black text-base font-normal'>{data.description?.length > 35 ? data.description.slice(0,35) : data.description}</p>
                                        </span>
                                        <span className='flex gap-5 justify-between items-center'>
                                            <p className='text-slate-500 text-sm font-normal leading-tight'>Narration</p>
                                            <p className='text-black text-base font-normal'>{data.narration?.length > 35 ? data.narration.slice(0,35) : data.narration}</p>
                                        </span>
                                        <span className='flex gap-5 justify-between items-center'>
                                            <p className='text-slate-500 text-sm font-normal leading-tight'>Status</p>
                                            <p className='text-black text-base font-normal'>{data.message}</p>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='flex flex-col justify-between tablet:flex-col desktop:w-1/2 w-full gap-8 p-12 rounded-lg bg-GrayCustom8 bg-opacity-50'>
                            <div className='bg-white shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] p-5 rounded-md flex flex-col gap-5'>
                                <span className='text-neutral-900 text-xl font-normal'>Pocket Details</span>
                                <div className='flex flex-col gap-5'>
                                    <span className='flex gap-5 justify-between items-center'>
                                        <p className='text-slate-500 text-sm font-normal leading-tight'>Pocket ID</p>
                                        <p className='text-black text-base font-normal'>{data.pocketId}</p>
                                    </span>
                                    <span className='flex gap-5 justify-between items-center'>
                                        <p className='text-slate-500 text-sm font-normal leading-tight'>Pocket Tier</p>
                                        <p className='text-black text-base font-normal'>{props.pocketTier}</p>
                                    </span>
                                </div>
                            </div>

                            <div className='w-full flex justify-end mobile:justify-center'>
                                <div className='flex flex-col w-full'>
                                    <span className='font-bold text-lg leading-[21.6px] mb-5'>Receiver details</span>
                                    <div className='flex flex-col gap-5'>
                                        <span className='flex gap-5 justify-between items-center'>
                                            <p className='text-slate-500 text-sm font-normal leading-tight'>Name</p>
                                            <p className='text-black text-base font-normal'>{data.receiverName}</p>
                                        </span>
                                        <span className='flex gap-5 justify-between items-center'>
                                            <p className='text-slate-500 text-sm font-normal leading-tight'>Bank</p>
                                            <p className='text-black text-base font-normal'>{data.receiverBankCode}</p>
                                        </span>
                                        <span className='flex gap-5 justify-between items-center'>
                                            <p className='text-slate-500 text-sm font-normal leading-tight'>Account number</p>
                                            <p className='text-black text-base font-normal'>{data.receiverAccountNumber}</p>
                                        </span>
                                    </div>
                                </div>
                            </div>

                            <div className='w-full flex justify-end mobile:justify-center'>
                                <div className='flex flex-col w-full'>
                                    <span className='font-bold text-lg leading-[21.6px] mb-5'>Sender details</span>
                                    <div className='flex flex-col gap-5'>
                                        <span className='flex gap-5 justify-between items-center'>
                                            <p className='text-slate-500 text-sm font-normal leading-tight'>Name</p>
                                            <p className='text-black text-base font-normal'>{data.sender}</p>
                                        </span>
                                        <span className='flex gap-5 justify-between items-center'>
                                            <p className='text-slate-500 text-sm font-normal leading-tight'>Date</p>
                                            <p className='text-black text-base font-normal'>{moment(data.createdAt).format("MMM DD, YYYY - LTS")}</p>
                                        </span>
                                        <span className='flex gap-5 justify-between items-center'>
                                            <p className='text-slate-500 text-sm font-normal leading-tight'>Email</p>
                                            <p className='text-black text-base font-normal'>{data.createdBy}</p>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        }
        </div>
    )
}

const mapStateToProps = (state:any) => ({
    pocketTier: state.app.pocketTier,
    pocketBal: state.app.pocketBal,
    bearerToken: state.auth.token,
    publicKey: state.auth.public_key,
})

export default connect(mapStateToProps)(TransactionOverview)