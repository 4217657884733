import React, { useEffect, useState } from "react";
import Button from "components/atoms/Button";
import PageTitle from "components/atoms/PageTitle";
import InfoNav from "components/organisms/InfoNav";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import FormInput from "components/atoms/FormInput";
import helpIcon from "../../../../assets/svg/help-circle_2.svg";
import Alert from "components/atoms/Alert";
import { generateRandomAlphanumeric, handleException } from "utils";
import axios from "services/axios";
import { BASE_URL, BASE_URL_OTP } from "services/http";
import Spinner from "components/atoms/Spinner";
import CodeInput from "components/atoms/CodeInput";
import { Modal } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import successGif from "../../../../assets/gif/success.gif";
import { getValues } from "services/storage";

const CreateSubPocket = (props) => {
  const { pocket_data } = props;
  console.log({ pocket_data });

  const [loading, setLoading] = useState(false);

  const [pocketRef, setPocketRef] = useState("");
  const [firstname, setFirstname] = useState("");
  const [lastname, setLastname] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [pocketRefErr, setPocketRefErr] = useState("");
  const [firstnameErr, setFirstnameErr] = useState("");
  const [lastnameErr, setLastnameErr] = useState("");
  const [emailErr, setEmailErr] = useState("");
  const [phoneErr, setPhoneErr] = useState("");

  const navigate = useNavigate();

  const [otp, setOtp] = useState("");
  const [otpMode, setOtpMode] = useState(false);
  const [step, setStep] = useState(1);
  const [seconds, setSeconds] = useState(60);
  const [timerRunning, setTimerRunning] = useState(false);
  const [successModalOpen, setSuccessModalOpen] = useState(false);

  useEffect(() => {
    let timer: NodeJS.Timeout;

    if (timerRunning && seconds > 0) {
      timer = setInterval(() => {
        setSeconds((prevSeconds) => prevSeconds - 1);
      }, 1000);
    } else if (timerRunning && seconds === 0) {
      setTimerRunning(false);
    }

    return () => clearInterval(timer);
  }, [timerRunning, seconds]);

  const startTimer = () => {
    setTimerRunning(true);
    setSeconds(60);
  };

  const resendOTP = () => {
    startTimer();
  };

  useEffect(() => {
    if (step === 2) {
      startTimer();
    }
  }, [step]);

  const handleCloseModal = () => {
    setSuccessModalOpen(false);
    navigate("/dashboard/accounts/");
  };

  useEffect(() => {
    setPocketRef(generateRandomAlphanumeric(10));
  }, []);

  const createSubPocket = async () => {
    setLoading(true);
    const subPocketData = [
      {
        currency: props.primaryPocket_details.ledgerBalanceCurrency,
        selfOwned: false,
        pocketFunction: "BOTH",
        reference: pocketRef,
        pocketOwner: {
          existingPocketOwner: false,
          pocketOwnerId: props.pocket_data.pocketOwnerId,
          pocketOwnerDetails: {
            businessName: props.primaryPocket_details.pocketOwner.businessName,
            firstName: firstname,
            lastName: lastname,
            emailAddress: email,
            phoneNumber: phone,
            canRetailPockets: false,
            merchantDetails: {
              publicKey: props.public_key,
            },
          },
        },
      },
    ];
    try {
      const res = await axios.post(
        `${BASE_URL}pocket-id/${props.pocketId}/sub-pocket`,
        subPocketData,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${props.bearerToken}`,
            "Public-Key": `${props.public_key}`,
          },
        }
      );

      if (res.data.code === "error") {
        setLoading(false);
        Alert("error", res.data.message);
      } else {
        setLoading(false);
        Alert("success", "Created Successfully");
        setSuccessModalOpen(true);
      }
    } catch (error) {
      setLoading(false);
      handleException(error);
    }
  };

  const createOtp = async () => {
    const payload = {
      actionItem: "CREATE_SUB_POCKET_BY_HEAD_CHURCH",
    };

    setLoading(true);
    try {
      const res = await axios.post(`${BASE_URL_OTP}sendOtp`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.bearerToken}`,
        },
      });

      setLoading(false);
      if (res.data.status !== "00") {
        Alert("error", res.data.message);
      } else {
        setStep(2);
        Alert("success", "OTP sent");
      }
    } catch (error) {
      setLoading(false);
      handleException(error);
    }
  };

  const validateOtp = async () => {
    const payload = {
      email: pocket_data?.pocketCredentials?.userName,
      category: "CREATE_SUB_POCKET_BY_HEAD_CHURCH",
      otp: otp,
    };

    setLoading(true);
    try {
      const res = await axios.post(`${BASE_URL_OTP}otp-verify`, payload, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${props.bearerToken}`,
        },
      });

      setLoading(false);
      if (res.data.status !== "00") {
        Alert("error", res.data.message);
      } else {
        setStep(3);
        createSubPocket();
      }
    } catch (error) {
      setLoading(false);
      handleException(error);
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    createOtp();
  };

  const handleCancelEvent = () => {
    navigate("/dashboard/accounts/");
  };

  const handlePhoneInput = (e) => {
    const regex = /^[0-9\b]+$/;

    if (e.target.value === "" || regex.test(e.target.value)) {
      setPhone(e.target.value);
    }
  };

  return (
    <div className="">
      {/* <PageTitle 
                pageTitle="Create Sub pocket" 
                pageTitleStyle="mt-5 mb-8"
            /> */}

      <div className="flex gap-4 items-center bg-white border-t border-gray-100 mb-10 py-4 px-16 mt-[-20px] desktop:mx-[-48px] tablet:mx-[-40px] mobile:mx-[-32px]">
        <PageTitle
          pageTitle={step > 1 ? "Authenticate" : "Create subpocket account"}
        />
      </div>

      <form
        className="py-8 max-h-[calc(100vh-261px)] overflow-y-scroll custom_container mb-10 bg-Background5"
        onSubmit={handleSubmit}
      >
        <div className="flex justify-center items-center">
          <div className="relative shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] p-10 desktop:w-[43%] w-[60%] mobile:w-[95%] rounded-[15px] flex flex-col gap-5">
            {loading && (
              <div
                className={`absolute z-10 left-0 top-0 flex items-center py-10 justify-center rounded-[15px] bg-white bg-opacity-80 w-full h-full`}
              >
                <Spinner />
              </div>
            )}

            {step > 1 ? (
              <div className="text-center">
                <p className="text-xl font-semibold mb-10">
                  Enter One Time Passcode
                </p>
                <p className="mb-10">
                  Please enter the 6-digit OTP sent to{" "}
                  {pocket_data?.pocketCredentials?.userName}
                  <br /> (Do not refresh this page)
                </p>
                <div className="max-w-sm mx-auto flex justify-center">
                  <CodeInput
                    length={6}
                    type="numeric"
                    secret={false}
                    onComplete={(v) => setOtp(v)}
                    onChange={(v) => setOtp(v)}
                  />
                </div>
                <p className="mt-5">
                  Didn't receive the OTP?{" "}
                  <span
                    onClick={resendOTP}
                    className="text-red-500 cursor-pointer"
                  >
                    Resend ({seconds})
                  </span>
                </p>
              </div>
            ) : (
              <div>
                <FormInput
                  type="text"
                  name="firstname"
                  required
                  placeholder="Firstname"
                  style="mb-2 w-full"
                  inputStyle="border border-transparent text-Black2"
                  label="Firstname"
                  labelStyle="font-semibold text-base leading-6 text-Black2"
                  value={firstname}
                  onChange={(e) => setFirstname(e.target.value)}
                  validationErr={firstnameErr}
                />

                <FormInput
                  type="text"
                  name="lastname"
                  placeholder="Lastname"
                  style="mb-2 w-full"
                  required
                  inputStyle="border border-transparent text-Black2"
                  label="Lastname"
                  labelStyle="font-semibold text-base leading-6 text-Black2"
                  value={lastname}
                  onChange={(e) => setLastname(e.target.value)}
                  validationErr={lastnameErr}
                />

                <FormInput
                  type="email"
                  name="email"
                  required
                  placeholder="Email"
                  style="mb-2 w-full"
                  inputStyle="border border-transparent text-Black2"
                  label="Email"
                  labelStyle="font-semibold text-base leading-6 text-Black2"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  validationErr={emailErr}
                />

                <FormInput
                  type="text"
                  name="phone"
                  placeholder="Phone number"
                  style="mb-2 w-full"
                  required
                  inputStyle="border border-transparent text-Black2"
                  label="Phone Number"
                  labelStyle="font-semibold text-base leading-6 text-Black2"
                  value={phone}
                  onChange={handlePhoneInput}
                  validationErr={phoneErr}
                />
              </div>
            )}
          </div>
        </div>
        <div className="flex justify-between items-center absolute w-full bottom-0 left-0 py-8 px-16 bg-white shadow-[0_8px_30px_rgb(0,0,0,0.12)]">
          <Button
            btnType="button"
            btnText="Cancel"
            btnStyle="rounded-lg truncate flex gap-3 justify-center items-center w-[216px] mobile:w-fit mobile:px-8 px-3 py-2 font-semibold text-sm border border-gray-200 leading-7 flex-row-reverse rounded-lg truncate"
            disabled={false}
            disabledClass=""
            onClick={handleCancelEvent}
          />
          <Button
            btnType={step === 1 ? "submit" : "button"}
            // btnText=""
            btnText={
              loading ? (
                <Spinner
                  text="Processing..."
                  textStyle="text-white text-base"
                /> // <Spinner text="Loading..." textStyle="text-white" />
              ) : (
                "Create"
              )
            }
            btnStyle="bg-DarkBg3 text-white rounded-lg truncate flex gap-3 justify-center items-center w-[216px] mobile:w-fit mobile:px-8 px-3 py-2 font-semibold flex-row-reverse rounded-lg truncate create-button"
            disabled={loading}
            disabledClass=""
            onClick={() => (step === 2 ? validateOtp() : null)}
          />
        </div>
      </form>

      <Modal open={successModalOpen} onClose={handleCloseModal} size="xs">
        <Modal.Header closeButton></Modal.Header>
        <div className="flex flex-col items-center py-10">
          <p className="text-xl font-semibold mb-7">Account created</p>
          <p className="mb-20">Sub pocket has been successfully created </p>

          <img src={successGif} className="h-56 w-56" />
        </div>
      </Modal>
    </div>
  );
};

const mapStateToProps = (state) => ({
  bearerToken: state.auth.token,
  public_key: state.auth.public_key,
  pocket_data: state.auth.pocket_data,
  subPocketData: state.pocket.subPocketData,
  pocketId: state.auth.pocket_data?.pocketId,
  primaryPocket_details: state.pocket.primaryPocket_details,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(CreateSubPocket);

{
  /* <RadioAndCheckInput 
    type="radio" 
    name="pocketOwner" 
    inputData={ownerOptData} 
    containerStyle="gap-5"
    label='Select recipient'
    labelStyle='font-semibold text-base leading-6 text-Black2'
    handleSelect={setPocketOwner}
    validationErr={pocketOwnerErr}
/> */
}

// console.log("pocket_data:", props.pocket_data);
// console.log("primaryPocket_details:", props.primaryPocket_details);

// pocketRef: pocketRef,
// firstname: firstname,
// lastname: lastname,
// email: email,
// phone: phone

// "currency": "NGN",
// "selfOwned": false,
// "pocketFunction": "BOTH",
// "reference": "i37623456782uewef",
// "pocketOwner": {
//     "existingPocketOwner": "false",
//     "pocketOwnerId": "LrDQXBDH",
//     "pocketOwnerDetails" : {
//         "businessName": "Living Faith",
//         "firstName": "Test",
//         "lastName": "Account",
//         "emailAddress": "textaccount@mailinator.com",
//         "phoneNumber": "09121385067",
//         "canRetailPockets": false,
//         "merchantDetails" : {
//             "publicKey" : "SBPUBK_0RCWZDDRDJYGVYGGW1ITMCPTRJ6UM2HT"
//         }
//     }
// }
