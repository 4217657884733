import React, { FC } from "react";
import countryList from "../../../../data/country_list.json";
import CopyText from "components/atoms/CopyText";

type Props = {
  title: string;
  subtitle?: string;
  value?: string;
  children?: any;
  currency?: string;
  pocketBalance?: string;
  country?: string;
  pocketId?: string;
};

const AnalyticsCard: FC<Props> = ({
  title,
  subtitle,
  value,
  children,
  ...rest
}) => {
  console.log("Rest: ", rest?.pocketId);

  const getCountry: any = countryList.find((country) =>
    rest.country
      ? country.alpha2 === rest.country
      : country.currency_code === rest.currency
  );

  console.log(getCountry);
  return (
    <div className="bg-white h-[200px] w-[380px] border border-[#E2EEFF] rounded-lg drop-shadow-[7px_4px_5px_rgba(179,195,244,0.20)] p-5 relative">
      <div className="flex flex-col justify-between h-full">
        <img
          src={`/flags/${
            getCountry?.flag
              ? getCountry?.flag.toLowerCase()
              : getCountry?.name.toLowerCase().replace(/ /g, "_")
          }.gif`}
          className="w-[47px] h-[45px] rounded-[100%]"
        />
        <div>
          <p className="text-lg text-[#020607] font-semibold">{`${
            rest.currency
          } ${Number(rest.pocketBalance)
            ?.toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</p>
          <p className="text-[#747C91] mt-1">{getCountry?.title || getCountry?.name}</p>
        </div>
      </div>
      <div className="absolute right-0 top-0 h-full z-10 flex items-center">
        <div className="flex flex-col gap-0 p-3 rounded-l-xl bg-gray-100">
          <small className="text-gray-400">POCKET ID</small>
          <CopyText style="text-black" text={rest?.pocketId}/>
        </div>
      </div>
    </div>
  );
};

export default AnalyticsCard;
