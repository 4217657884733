import React from 'react'
import AppModal from 'components/organisms/CustomModal'
import copyIcon from '../../../assets/svg/copy.svg'
import Button from 'components/atoms/Button'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import CopyText from 'components/atoms/CopyText'

const SideComponent = (props) => {
    const navigate = useNavigate()

    console.log("props.data:", props.data)

    // {
    //     label: 'Transaction Status',
    //     render: (data) => <TableDataSpan 
    //                         onClick={()=>viewTransaction(data.transactionId)} 
    //                         text={data.transactionLeg} 
    //                         additionalClass={`text-opacity-70 text-xs rounded-full px-2 py-1 cursor-pointer
    //                           ${data.transactionLeg === 'DEBIT' ? 'bg-DangerAccent3 text-Danger2' : 'bg-Green_Accent text-Green_Accent2'}
    //                         `}
    //                       />
    //   },

    return (
        <AppModal handleClose={props.toggleModal} modalStyle="desktop:w-1/4 mobile:w-3/4 bg-white min-h-screen z-30 top-0 right-0 animate-slide_left p-8" contentStyle="flex flex-col gap-5 overflow-y-scroll h-[92vh]">
            <div className='flex flex-col text-left'>
                <span className='font-normal text-2xl mobile:text-lg text-black mb-5'>Transaction Overview</span>
                <span className='text-[14px] mobile:text-xs leading-7 text-SecondaryAccent'>View the details of the transaction.</span>
            </div>

            <div className='flex flex-col justify-between items-center h-full'>
                <div className='flex flex-col gap-8 w-full'>
                    <div className='mb-5 flex flex-col gap-5'>
                        <div>
                            <span className='text-base mobile:text-sm leading-7 text-SecondaryAccent'>Amount</span>
                            <div className='flex gap-3 items-center'>
                                <span className='font-bold text-[28px] mobile:text-xl leading-7 text-DarkBg3'>{props.data.transactionCurrency + " " + (Intl.NumberFormat().format(props.data.transactionAmount))}</span>
                                <span className={`${props.data.transactionLeg === 'CREDIT' ? 'bg-Green_Accent8 text-Success2' : 'bg-DangerAccent5 text-Danger4'} rounded-d px-2 py-1 text-xs leading-[15.6px] font-semibold`}>{props.data.transactionLeg}</span>
                            </div>
                        </div>
                        
                        <div className='flex flex-col gap-3'>
                            <span className='text-[15px] font-normal leading-7 text-SecondaryAccent'>{moment(props.data.createdAt).format("MMM DD, YYYY - LTS")}</span>
                            <CopyText 
                                text={props.data.reference}
                                textStyle="text-opacity-70 text-xs inline-block item-center bg-GrayCustom3 px-3 py-1 rounded-md"  
                                mssgStyle='-right-3 -top-[18px] hidden' 
                                imgStyle="w-4 h-4"
                            />
                        </div>
                    </div>
                    
                    <div className='flex flex-col'>
                        <span className='border-b border-SecondaryAccent2 font-semibold text-[18px] mobile:text-sm leading-6 text-DarkBg pb-4 mb-5'>
                        Payment Breakdown 
                        </span>
                        <div className='flex flex-col gap-5'>
                        <span className='w-full flex justify-between'>
                            <p className='font-normal text-xs leading-3 text-Black2'>Transaction  Amount</p>
                            <p className='font-semibold text-sm leading-[16.8px] text-DarkBg8'>{props.data.transactionCurrency + " " + (Intl.NumberFormat().format(props.data.transactionAmount))}</p>
                        </span>
                        {/* <span className='w-full flex justify-between'> */}
                            {/* <p className='font-normal text-xs leading-3 text-Black2 mb-3'>Trannsaction fee</p> */}
                            {/* <p className='font-semibold text-sm leading-[16.8px] text-DarkBg8'>{props.data.transactionCurrency} 5.00</p> */}
                            {/* <p className='font-semibold text-sm leading-[16.8px] text-DarkBg8'>NAN</p> */}
                        {/* </span> */}
                        </div>
                    </div>

                    <div className='flex flex-col'>
                        <span className='border-b border-SecondaryAccent2 font-semibold text-[18px] mobile:text-sm leading-6 text-DarkBg pb-4 mb-5'>
                            Other Information
                        </span>
                        <div className='flex flex-col gap-5'>
                            <div className='w-full flex justify-between items-center gap-5'>
                                <span className='font-normal text-xs leading-3 text-Black2'>Receiver Name</span>
                                <span className='font-semibold text-sm leading-[16.8px] text-DarkBg8'>{props.data.receiverName}</span>
                            </div>
                            <div className='w-full flex justify-between items-center gap-5'>
                                <span className='font-normal text-xs leading-3 text-Black2'>Receiver Bank</span>
                                <span className='font-semibold text-sm leading-[16.8px] text-DarkBg8'>{props.data.receiverBankCode}</span>
                            </div>
                            <div className='w-full flex justify-between items-center gap-5'>
                                <span className='font-normal text-xs leading-3 text-Black2'>Receiver Account Number</span>
                                <CopyText 
                                    text={props.data.receiverAccountNumber}
                                    textStyle="text-opacity-70 text-xs inline-block item-center bg-GrayCustom3 px-3 py-1 rounded-md"  
                                    mssgStyle='-right-3 -top-[18px] hidden' 
                                    imgStyle="w-4 h-4"
                                    style="flex-row-reverse"
                                />
                            </div>
                            <div className='w-full flex justify-between items-center gap-5'>
                                <span className='font-normal text-xs leading-3 text-Black2'>Transation Status</span>
                                <span className={`${props.data.message === 'Success' ? 'bg-Green_Accent8 text-Success2' : props.data.message === 'Failed' ? 'bg-DangerAccent5 text-Danger4' : 'bg-SecondaryAccent11 text-Accent_blue6'} px-[10px] py-[5px] rounded-md`}>{props.data.message}</span>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='w-full flex flex-col gap-5'>
                    <Button
                        btnType='button'
                        btnText="See detailed overview"
                        btnStyle='bg-SecondaryAccent3 text-[15px] font-normal leading-7 text-black w-full py-3'
                        onClick={() => {
                            navigate(`/dashboard/transactions/transaction_overview/${props.data.reference}`)
                            props.toggleModal()
                        }}
                    />
                    <Button
                        btnType='button'
                        btnText="Close"
                        btnStyle='bg-DarkBg3 text-[15px] font-normal leading-7 text-white w-full py-3 '
                        onClick={props.toggleModal}
                    />
                </div>
            </div>
        </AppModal>
    )
}

export default SideComponent
