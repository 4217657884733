import React, {useState} from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import Alert from './Alert'

type Props = {
    text: string,
    icon1?: JSX.Element,
    icon2?: JSX.Element,
    style?: string,
    active?: boolean,
    available: boolean,
    url: string,
    darkMode?: boolean,
    onClick: (e) => void
}

const SideNavItem: React.FC<Props> = ({icon1, icon2, text, active, style, onClick, url, available}) => {
    const navigate = useNavigate();
    const standardStyle = `${style} flex h-10 rounded items-center pl-12 tablet:pl-4 text-SecondaryAccent5 ${text !== '' && 'hover:bg-GrayCustom5'} hover:transition-opacity cursor-pointer my-1 text-white`
    const activeStyle = `bg-GrayCustom4 shadow-sm`
    
    return (
        <div onClick={() => {
                if (!available) {
                    Alert('info', "Sorry, this feature is not available yet.");
                    return;
                }
                navigate(url);
            }}
            data-name={text.toLocaleLowerCase().replace(" ", "_")}>
            <div data-name={text.toLocaleLowerCase().replace(" ", "_")} className={`${standardStyle} flex justify-between tablet:justify-start ${text !== '' && 'cursor-pointer'} text-white`} onClick={(e) => onClick(e)} style={{ ...(active && {background: 'linear-gradient(186deg, #4B4D52 -14.8%, rgba(75, 77, 82, 0.00) 132.74%)'}) }}>
                <span className='flex gap-3 w-fit items-center pointer-events-none'>
                    <span className="flex-shrink-0 pointer-events-none">{active ? icon1 : icon2}</span>
                    <span className='pointer-events-none'>{text}</span>
                </span>
                {/* {active && <span className='h-[35px] w-1 bg-Primary rounded-l pointer-events-none'></span>} */}
            </div>
        </div>
    )
}

export default SideNavItem