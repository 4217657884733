import React, {useCallback, useEffect, useState} from 'react'
import Button from 'components/atoms/Button'
import infoIcon from '../../../assets/svg/information.svg'
import {PocketData} from '../../../mockData/PocketList'
import {PocketTransactions} from '../../../mockData/PocketTransactions'
import { useParams } from 'react-router-dom'
import PaymentCard from 'components/molecules/PaymentCard'
import PageHeader from 'components/molecules/PageHeader'
import AppTable from 'components/organisms/app-table'
import TableDataSpan from 'components/organisms/app-table/TableDataSpan'
import copyIcon from '../../../assets/svg/copy.svg'
import moreIcon from '../../../assets/svg/more.svg'
import closeIcon from '../../../assets/svg/close-circle-solid.svg'
import Dropdown from 'components/atoms/Dropdown'
import PageTitle from 'components/atoms/PageTitle'
import CopyText from 'components/atoms/CopyText'
import { BASE_URL } from 'services/http'
import Alert from 'components/atoms/Alert'
import { handleException } from 'utils'
import moment from 'moment'
import {ToCamelCase} from '../../../components/atoms/CaseManager'
import ColorTag from 'components/atoms/ColorTag'
import { toggleAppEnv, toggleModal } from 'redux/app/app.action'
import { connect } from 'react-redux'
import SideComponent from '../Transactions/SideComponent'
import axios from 'services/axios'
import {PocketTransactionHeader} from '../../../components/molecules/tableExport'
import linkIcon from '../../../assets/svg/link.svg'
import bankIcon from "../../../assets/svg/bank.svg"
import walletIcon from "../../../assets/icons/wallet-2.svg"
import CountUp from 'react-countup'


type Props = {
    publicKey?: any,
    bearerToken?: any,
    modalState?: boolean, 
    toggleModal?: () => void
}

const searchKeys = [
    {title: "pocketId", value: null, link: null, action: () => null},
    {title: "reference", value: null, link: null, action: () => null},
    {title: "narration", value: null, link: null, action: () => null}
  ].sort()

const SubaPocketOverview:React.FC<Props> = ({modalState, toggleModal, bearerToken, publicKey}) => {
    const [query, setQuery] = useState("")
    const [queryKey, setQueryKey] = useState("")
    const [dropdown, setDropdown] = useState(false)
    const [loading, setLoading] = useState(false)
    const [errorState, setErrorState] = useState(false)
    const [pocketData, setPocketData] = useState<any>({})
    const [pocketOwnerData, setPocketOwnerData] = useState<any>({})
    const [showModal, setShowModal] = useState(false) 
    const [accountInfo, setAccountInfo] = useState<any>(null);

    const [transactionDetails, setTransactionDetails] = useState<any>({})
    const [loadingTransactions, setLoadingTransactions] = useState(false)
    const [transactionsErrorState, setTransactionsErrorState] = useState(false)
    const [pocketTransactions, setPocketTransactions] = useState<any>([])
    const [transactionCount, setTransactionCount] = useState<any>([])
    const [totalElements, setTotalElements] = useState(null)
    const [totalPages, setTotalPages] = useState(null)
    const [currentPage, setCurrentPage] = useState(1)
    const [pageNum, setPageNum] = useState(null)
    const [recordsPerPage] =  useState(5)
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")

    const pocketId = useParams()
    console.log({pocketId});
    
    const url = `search?reference=&pocketId=${pocketId.id}`

    const defaultURl = `transaction/search?pocketId=${pocketId.id}`
    const urlLogic =  query.length !== 0 && (!startDate || !endDate)
            ? `${defaultURl}&${ToCamelCase(queryKey)}=${query?.toLowerCase()}`

            : query.length !== 0 && startDate !== "" && endDate !== ""
            ? `${defaultURl}&${ToCamelCase(queryKey)}=${query?.toLowerCase()}&createdOnStartDate=${moment(startDate).format("DD-MM-YYYY")}&createdOnEndDate=${moment(endDate).format("DD-MM-YYYY")}`
            
            : (startDate && endDate === "")
            ? `${defaultURl}&createdOnStartDate=${moment(startDate).format("DD-MM-YYYY")}`

            : (startDate === "" && endDate)
            ? `${defaultURl}&createdOnEndDate=${moment(endDate).format("DD-MM-YYYY")}`

            : (startDate && endDate)
            ? `${defaultURl}&createdOnStartDate=${moment(startDate).format("DD-MM-YYYY")}&createdOnEndDate=${moment(endDate).format("DD-MM-YYYY")}`

            : defaultURl

    const pocketTransaction_url = `${urlLogic}&size=${recordsPerPage}&page=${pageNum === null ? 0 : pageNum}`


    const fetchPocketFunc = async () => {
        setLoading(true)
        try{
            const res = await axios.get(`${BASE_URL + url}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${bearerToken}`,
                    "Public-Key": `${publicKey}`
                }
            })
            if(res.data.code === "error"){
                setLoading(false)
                Alert('error', res.data.message)
            }else{
                setLoading(false)
                setPocketData(res.data.data.content[0])
                setPocketOwnerData(res.data.data.content[0].pocketOwner)
            }
            return res;
        }catch(error){
            setLoading(false)  
            setErrorState(true)
            handleException(error)
        }
    }
    const getPocketDetails = async () => {
        try {
            const res = await axios.get(`${BASE_URL}pocket-id/${pocketId.id}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${bearerToken}`,
                    "Public-Key": `${publicKey}`
                }
            });

            if(res.data.code === 'error'){
                Alert("error", res.data.message)
                setLoading(false)
            }else{
                console.log("primaryPocket_details:", res.data.data)
                setAccountInfo({ ...res?.data.data.pocketAccounts[0] });
            }

        } catch (error) {
            setLoading(false)
            handleException(error)
        }
    }

    useEffect(() => {
        if (pocketId) {
            getPocketDetails();
        }
    }, [pocketId]);
    console.log({accountInfo});
    

    const fetchPocketTransactions = useCallback(async () => {
        setLoadingTransactions(true)
        try{
            const res = await axios.get(`${BASE_URL + pocketTransaction_url}`, {
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${bearerToken}`,
                    "Public-Key": `${publicKey}`
                }
            })
            if(res.data.code === 'error'){
                setLoading(false)
                Alert('error', res.data.message)
            }else{
                setLoadingTransactions(false)
                setPocketTransactions(res.data.data.content)
                setTransactionCount(res.data.data.totalElements)
                console.log("PocketTransactions:", res.data.data.content)

                setTotalElements(res.data.data.totalElements)
                setTotalPages(res.data.data.totalPages)
            }
            return res;
        }catch(error){
            setLoadingTransactions(false)  
            setTransactionsErrorState(true)
            handleException(error)
        }
    }, [pocketTransaction_url])

    useEffect(() => {
        fetchPocketFunc()
    }, [])

    useEffect(() => {
        fetchPocketTransactions()
    }, [fetchPocketTransactions])

    const getCurrentPage = (pageNumber) => {
        setPageNum(pageNumber)
        setCurrentPage(pageNumber)
    }

    const viewTransaction = (id) => {
        setShowModal(prevState => !prevState)
        const transactionFilter = pocketTransactions.filter(item => item.transactionId === id)
        const transactionItem = transactionFilter[0]
        setTransactionDetails(transactionItem)  
    }

    const initials = `${pocketData?.pocketOwner?.firstName} ${pocketData?.pocketOwner?.lastName}`.split(" ").map(i => i[0]).join("").toUpperCase();

    const columns = [
        {
            label: "Reference ID",
            render: (data) =>   <TableDataSpan 
                                    onClick={()=>viewTransaction(data.transactionId)}
                                >
                                    <CopyText 
                                        text={data.reference} 
                                        textStyle="text-opacity-70 text-xs" 
                                        style='px-3 py-1' 
                                        mssgStyle='-right-8' 
                                        imgStyle="w-4 h-4"
                                    />
                                </TableDataSpan>
        },
        {
            label: 'Transaction Type',
            render: (data) =>   <TableDataSpan 
                                    onClick={()=>viewTransaction(data.transactionId)} 
                                    text={data.transactionLeg} 
                                    additionalClass={`text-opacity-70 text-xs px-3 py-1 rounded-2xl cursor-pointer
                                        ${data.transactionLeg === 'CREDIT' ? 'bg-Green_Accent text-Green_Accent2' : 'bg-DangerAccent3 text-Danger2'}
                                    `}
                                />
        },
        {
            label: 'Date',
            render: (data) =>   <TableDataSpan 
                                    onClick={()=>viewTransaction(data.transactionId)} 
                                    text={moment(data.createdAt).format("MMM DD, YYYY - LTS")} 
                                    additionalClass='text-opacity-70 text-xs'
                                />
        },
        {
            label: 'Narration',
            render: (data) =>   <TableDataSpan 
                                    onClick={()=>viewTransaction(data.transactionId)} 
                                    text={data.narration.length < 30 ? data.narration : data.narration.slice(0,30)+"..."} 
                                    additionalClass='text-opacity-70 text-xs'
                                />
        },
        {
            label: 'Amount',
            render: (data) =>   <TableDataSpan 
                                    onClick={()=>viewTransaction(data.transactionId)} 
                                    text={data.transactionCurrency + " " + (Intl.NumberFormat().format(data.transactionAmount))} 
                                    additionalClass='text-opacity-70 text-xs'
                                />
        },
        {
            label: 'Status',
            render: (data) => <TableDataSpan onClick={()=>viewTransaction(data.transactionId)}>
                                <div>
                                    <span className={`w-1 h-1 p-1 inline-block mr-2 rounded-full
                                        ${data.message === 'Success'
                                        ? 'bg-Success'
                                        : data.message === 'Failed'
                                        ? 'bg-Danger'
                                        : data.message === 'Pending'
                                        ? 'bg-Yellow'
                                        : ''
                                    }`}></span>
                                    <span className='text-opacity-70 text-xs'>{data.message}</span>
                                </div>
                              </TableDataSpan>
        }
    ]
    
    const overviewData = [
        {title: "Available balance", amount: pocketData.availableBalanceAmount},
        {title: "Amount sent", amount: 100},
        {title: "Amount received", amount: 100},
        {title: "Transfer charge", amount: 100},
        {title: "Sub accounts", amount: 100}
    ]

    console.log("pocketData:", pocketData);
    const getCurrency = () => {
        switch (pocketData?.availableBalanceCurrency) {
            case "NGN" : return "₦";
            case "GHS" : return  "₵";
            case "USD" : return "$";
            default: return pocketData?.availableBalanceCurrency;
        }
    }

    return (
        <>    

        <div className="bg-white border-t border-gray-100 mb-10 p-4 mt-[-20px] desktop:mx-[-48px] tablet:mx-[-40px] mobile:mx-[-32px]">
            { loading ? (
                <div role="status" className='animate-pulse flex items-center gap-4'>
                    <div className="h-12 w-12 bg-gray-200 rounded"></div>
                    <div className="h-4 bg-gray-200 rounded-full w-48"></div>
                    <div className="h-6 bg-gray-200 rounded-full w-20"></div>
                </div>
            ) : (

                <div className='flex gap-4 items-center'>
                    <span className='h-12 w-12 flex items-center justify-center text-xl rounded bg-[#E7E9F1] font-semibold'>{initials}</span>
                    <h3 className='text-xl font-semibold'>{`${pocketData?.pocketOwner?.firstName} ${pocketData?.pocketOwner?.lastName}`}</h3>
                    <div className={`text-xs font-light px-2 py-1 inline-block rounded-lg border ${pocketData?.status === "ACTIVE" ? 'text-green-600 bg-[#ECFDF3] border-green-100' : 'text-red-600 bg-[#FEF8F8] border-red-100'}`}>
                        <div className='flex items-center gap-2'>
                            <span className={`h-2 w-2 rounded-full ${pocketData?.status === "ACTIVE" ? "bg-green-600" : "bg-red-600"}`}></span> 
                            <span>{pocketData?.status === "ACTIVE" ? "Active" : "Inactive"}</span>
                        </div>
                    </div>
                </div>
            )}
        </div>

        <div className="bg-white rounded p-5 mb-16">
            {!accountInfo ? (
                <div role="status" className='animate-pulse flex items-center justify-between gap-10'>
                    <div className="flex flex-col gap-7">
                        <div className="h-3 bg-gray-200 rounded-full w-20"></div>
                        <div className="h-6 bg-gray-200 rounded-full w-48"></div>
                    </div>
                    <div className="flex flex-col gap-7">
                        <div className="h-3 bg-gray-200 rounded-full w-20"></div>
                        <div className="h-6 bg-gray-200 rounded-full w-48"></div>
                    </div>
                    <div className="flex flex-col gap-7">
                        <div className="h-3 bg-gray-200 rounded-full w-20"></div>
                        <div className="h-6 bg-gray-200 rounded-full w-48"></div>
                    </div>
                    <div className="flex flex-col gap-7">
                        <div className="h-3 bg-gray-200 rounded-full w-20"></div>
                        <div className="h-6 bg-gray-200 rounded-full w-48"></div>
                    </div>
                </div>
            ) : ( <div className='flex justify-between items-center'>
                <div className="flex flex-col gap-4">
                    <p className='text-[#747C91] text-sm'>Available balance</p>
                    <h3 className='text-2xl font-bold'> <span className='opacity-60'>{getCurrency()}</span>{Intl.NumberFormat().format(pocketData?.availableBalanceAmount)}</h3>
                </div>
                <div className="flex flex-col gap-4 pl-6 border-l border-[#DADCE8]">
                    <p className='text-[#747C91] text-sm'>Transaction count</p>
                    <h3 className='text-2xl font-bold'><CountUp end={transactionCount} duration={1} style={{ fontSize: 24, fontWeight: 'bold' }}/></h3>     
                </div>
                <div className="flex flex-col gap-4 pl-6 border-l border-[#DADCE8]">
                    <div className="flex items-center gap-2">
                        <span className='flex items-center justify-center rounded-md text-black bg-gray-200 w-14 h-14'>
                            <img  src={bankIcon} alt="" className='w-6 h-6'/>
                        </span>
                        <div>
                            <p className='mb-0'>Account number: <strong>{accountInfo?.accountNumber}</strong></p>
                            <p className='mt-0'>Bank name: <strong>{accountInfo?.bankName}</strong></p>
                        </div>
                    </div>
                    
                    <div className="flex gap-2 items-center">
                        <p className='text-[#747C91] text-sm'>Pocket ID:</p>
                        <CopyText text={pocketData?.pocketId} />
                    </div>
                </div>
                <div className="flex flex-col gap-4 pl-6 border-l border-[#DADCE8]">
                    <p className='text-[#747C91] text-sm'>Copy link to fund sub pocket account</p>

                    <a href={pocketData?.fundingLink} target='_blank' className='bg-blue-500 self-start px-3 no-underline hover:no-underline hover:text-white hover:bg-blue-700 text-white py-1 flex gap-2 justify-center rounded-lg'>
                        <img src={linkIcon} alt="" />
                        <span>Funding link</span>
                    </a>
                </div>
            </div>)}
        </div>
            <div className='flex flex-col'>
                <div className=''>
                    <PageHeader
                        pageTitle='Transactions'
                        pageTitleStyle='text-lg font-semibold leading-7 text-black'
                        exportTable={true}
                        tableKeys={searchKeys}
                        queryValue={query}
                        setQuery={setQuery}
                        setQueryKey={setQueryKey}
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        tableHeaders={PocketTransactionHeader}
                        tableData={pocketTransactions}
                        csvUrl={defaultURl}
                    />
                    <AppTable
                        data={pocketTransactions}
                        columns={columns}
                        loading={loadingTransactions}
                        errorState={errorState}
                        itemsPerPage={recordsPerPage}

                        dataLength={totalElements}
                        getCurrentPage={getCurrentPage}
                    />
                </div>
            </div>
            {showModal && 
                <SideComponent
                    toggleModal={() => setShowModal(prevState => !prevState)}
                    data={transactionDetails}
                />
            }
        </>
    )
}

const mapStateToProps = (state) => ({
    modalState: state.app.modalState,
    bearerToken: state.auth.token,
    publicKey: state.auth.public_key
})
  
const mapDispatchToProps = (dispatch) => ({
    toggleModal: () => dispatch(toggleModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(SubaPocketOverview)
