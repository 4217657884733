import React, { useState } from "react";
import {ArrowDownIcon} from '../atoms/ImagesImport'
import Chart from "chart.js/auto";
import { CategoryScale, ArcElement, Tooltip, Legend  } from "chart.js";
import {Bar, Line, Doughnut, Radar} from 'react-chartjs-2'

Chart.register(CategoryScale, ArcElement, Tooltip, Legend);

type Props = {
  chartType: string, 
  propData: any[] | string,
  style?: string,
  dataset: any,
  legend?: string,
}

const ChartComp: React.FC<Props> = ({chartType, propData, dataset, style, legend}) => {
  const {labels, data, borderWidth, borderRadius, barPercentage, categoryPercentage, backgroundColor, circumference} = dataset(propData)
  
  const chartData = ({
    labels: labels,
    datasets: [
      {
        label: legend,
        data: data,
        backgroundColor: backgroundColor,
        borderWidth: borderWidth,
        borderRadius: borderRadius,
        barPercentage: barPercentage,
        categoryPercentage: categoryPercentage,
        circumference:circumference
      }
    ]
  });
  
  const chartContent = chartType === "bar"

                        ? <Bar
                            className={style}
                            data={chartData}
                            options={{
                              plugins: {
                                title: {
                                  display: true,
                                  text: ""
                                },
                                legend: {
                                  display: true
                                }
                              }
                            }}
                          />

                        : chartType === "doughnut"

                        ? <Doughnut
                            className={style}
                            data={chartData}
                            options={{
                              plugins: {
                                title: {
                                  display: true,
                                  text: ""
                                },
                                legend: {
                                  display: true,
                                  position: "right"
                                }
                              }
                            }}
                          />
              
                        : chartType === "line"

                        ? <Line
                            className={style}
                            data={chartData}
                            options={{
                              plugins: {
                                title: {
                                  display: true,
                                  text: ""
                                },
                                legend: {
                                  display: true
                                }
                              }
                            }}
                          />
              
                        : ""
 
  return (
    <div className="w-full h-auto flex-shrink-0">
      {chartContent}
    </div>
  )
}

export default ChartComp