import { useState } from 'react';
import { ToTitleCase } from '../../../../components/atoms/CaseManager';

const inputAlphaNum = /^[a-zA-Z0-9._-]*$/;
const inputNum = /^[0-9]*$/;
const inputNumAlpha = /(^$|^\d*$)/;
const inputCapAlpha = /^[A-Z]*$/;

export const validateAmount = (input) => inputNum.test(input) && input !== '';
export const validateAmount_empty = (input) =>
  input !== '' && input !== undefined;
export const validateAmount_format = (input) => inputNum.test(input);

export const validateCurrency = (input) =>
  inputCapAlpha.test(input) && input !== '';
export const validateCurrency_empty = (input) =>
  input !== '' && input !== undefined;
export const validateCurrency_format = (input) => inputCapAlpha.test(input);

export const validateAccountNum = (input) =>
  inputNumAlpha.test(input) && input !== '' && input.length === 10;

export const validatePocketId = (input) =>
inputAlphaNum.test(input) && input !== '' && input.length === 10;

export const validateAccountNum_empty = (input) =>
  input !== '' && input !== undefined;
export const validateAccountNum_length = (input) =>
  input.toString().length === 10;
export const validateAccountNum_format = (input) => inputNumAlpha.test(input);

export const validateBankCode = (input) =>
  inputNumAlpha.test(input) && input !== '';
export const validateBankCode_empty = (input) =>
  input !== '' && input !== undefined;
export const validateBankCode_format = (input) => inputNumAlpha.test(input);

export const validateDesc = (input) => input !== '' && input.length > 0;
export const validateDesc_empty = (input) =>
  input !== '' && input !== undefined;
export const validateDesc_length = (input) => input.length > 0;

export const ValidationErrMssg = (rowIndex, columnIndex, dataItem) => {
  const actRowIndex = rowIndex + 1;

  if (columnIndex === 'amount' && !validateAmount(dataItem)) {
    if (!validateAmount_empty(dataItem)) {
      const errorMssgt = (
        <p>
          {<b>{ToTitleCase(columnIndex)}</b>} is empty on row{' '}
          <b>{actRowIndex}.</b>
        </p>
      );
      return errorMssgt;
    } else if (!validateAmount_format(dataItem)) {
      const errorMssgt = (
        <p>
          {<b>{ToTitleCase(columnIndex)}</b>} contains letters on row{' '}
          <b>{actRowIndex}.</b>
        </p>
      );
      return errorMssgt;
    }
  }

  if (columnIndex === 'currency' && !validateCurrency(dataItem)) {
    if (!validateCurrency_empty(dataItem)) {
      const errorMssg = (
        <p>
          {<b>{ToTitleCase(columnIndex)}</b>} is empty on row{' '}
          <b>{actRowIndex}.</b>
        </p>
      );
      return errorMssg;
    } else if (!validateCurrency_format(dataItem)) {
      const errorMssg = (
        <p>
          {<b>{ToTitleCase(columnIndex)}</b>} has incorrect format on row{' '}
          <b>{actRowIndex},</b> should contain all Uppercase.
        </p>
      );
      return errorMssg;
    }
  }

  if (columnIndex === 'accountNumber' && !validateAccountNum(dataItem)) {
    if (!validateAccountNum_empty(dataItem)) {
      const errorMssg = (
        <p>
          {<b>{ToTitleCase(columnIndex)}</b>} is empty on row{' '}
          <b>{actRowIndex}.</b>
        </p>
      );
      return errorMssg;
    } else if (!validateAccountNum_length(dataItem)) {
      const errorMssg = (
        <p>
          {<b>{ToTitleCase(columnIndex)}</b>} is incorrect on row{' '}
          <b>{actRowIndex},</b> should contain 10 digits.
        </p>
      );
      return errorMssg;
    } else if (!validateAccountNum_format(dataItem)) {
      const errorMssg = (
        <p>
          {<b>{ToTitleCase(columnIndex)}</b>} has incorrect format on row{' '}
          <b>{actRowIndex}</b>, should contain only digits.
        </p>
      );
      return errorMssg;
    }
  }

  if (columnIndex === 'bankCode' && !validateCurrency(dataItem)) {
    if (!validateBankCode_empty(dataItem)) {
      const errorMssg = (
        <p>
          {<b>{ToTitleCase(columnIndex)}</b>} is empty on row{' '}
          <b>{actRowIndex}.</b>
        </p>
      );
      return errorMssg;
    } else if (!validateBankCode_format(dataItem)) {
      const errorMssg = (
        <p>
          {<b>{ToTitleCase(columnIndex)}</b>} has incorrect format on row{' '}
          <b>{actRowIndex},</b> should contain only numbers.
        </p>
      );
      return errorMssg;
    }
  }

  if (columnIndex === 'description' && !validateDesc(dataItem)) {
    if (!validateDesc_empty(dataItem) || !validateDesc_length(dataItem)) {
      const errorMssg = (
        <p>
          {<b>{ToTitleCase(columnIndex)}</b>} is empty on row{' '}
          <b>{actRowIndex}.</b>
        </p>
      );
      return errorMssg;
    }
  }
};
