import React, { useEffect, useState, useCallback } from 'react'
import PageHeader from 'components/molecules/PageHeader'
import AppTable from 'components/organisms/app-table'
import TableDataSpan from 'components/organisms/app-table/TableDataSpan'
import { BASE_URL, BASE_URL_PROD_POCKET } from 'services/http'
import Alert from 'components/atoms/Alert'
import AppModal from '../../../components/organisms/CustomModal'
import { toggleAppEnv, toggleModal, getPocketTier, getPocketBal, storeNavKey } from 'redux/app/app.action'
import { connect } from 'react-redux'
import Button from 'components/atoms/Button'
import { useNavigate } from 'react-router-dom'
import {ToCamelCase} from '../../../components/atoms/CaseManager'
import CopyText from '../../../components/atoms/CopyText'
import moment from 'moment'
import { handleException } from '../../../utils'
import { getValues } from '../../../services/storage'
import SideComponent from './SideComponent'
import axios from 'axios'
import {TransactionsHeader} from '../../../components/molecules/tableExport'
import { data } from 'autoprefixer'
import SubNav from '../../../components/organisms/SubNav'

const searchKeys = [
  {title: "pocketId", value: null, link: null, action: () => null},
  {title: "reference", value: null, link: null, action: () => null},
  {title: "narration", value: null, link: null, action: () => null}
].sort()

const Transactions = ({
  toggleModal,
  modalState,
  bearerToken,
  publicKey,
  pocketId,
  getPocketTier,
  getPocketBal,
  pocketTier,
  pocketBal,
  storeNavKey
}) => { 
  const [query, setQuery] = useState("")
  const [queryKey, setQueryKey] = useState("")
  const [batchData, setBatchData] = useState([])
  const [transactions, setTransactions] = useState([])
  const [loading, setLoading] = useState(false)
  const [errorState, setErrorState] = useState(false)
  const [totalElements, setTotalElements] = useState(null)
  const [totalPages, setTotalPages] = useState(null)
  // const [transactionBals, setTransactionBals] = useState([])
  const [transactionDetails, setTransactionDetails] = useState([])
  const [pageNum, setPageNum] = useState(null)
  const [recordsPerPage ] =  useState(10)

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const [showModal, setShowModal] = useState(false)

  const navigate =  useNavigate()

  const defaultURl = `batch/payout/${pocketId}`

  // const url =  query.length !== 0 && (!startDate || !endDate)
  //           ? `${defaultURl}&${ToCamelCase(queryKey)}=${query?.toLowerCase()}`

  //           : query.length !== 0 && startDate !== "" && endDate !== ""
  //           ? `${defaultURl}&${ToCamelCase(queryKey)}=${query?.toLowerCase()}&createdOnStartDate=${moment(startDate).format("DD-MM-YYYY")}&createdOnEndDate=${moment(endDate).format("DD-MM-YYYY")}`
            
  //           : (startDate && endDate === "")
  //           ? `${defaultURl}&createdOnStartDate=${moment(startDate).format("DD-MM-YYYY")}`

  //           : (startDate === "" && endDate)
  //           ? `${defaultURl}&createdOnEndDate=${moment(endDate).format("DD-MM-YYYY")}`

  //           : (startDate && endDate)
  //           ? `${defaultURl}&createdOnStartDate=${moment(startDate).format("DD-MM-YYYY")}&createdOnEndDate=${moment(endDate).format("DD-MM-YYYY")}`

  //           : defaultURl

  const mainURL = `${defaultURl}?size=${recordsPerPage}&page=${pageNum === null ? 0 : pageNum}`

  const getCurrentPage = (pageNumber) => {
    setPageNum(pageNumber)
  }
  
  const fetchDataFunc = useCallback(async () => {
    setLoading(true)
    try{
      const res = await axios.get(`${BASE_URL_PROD_POCKET + mainURL}` , {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`,
          "Public-Key": `${publicKey}`
        }
      })
      if(res.data.code === 'error'){
        setLoading(false)
        Alert("error", res.data.message);
      }else{
        setLoading(false)
        console.log("BatchData_res:", res)
        console.log("BatchData:", res.data.data.content[0]?.totalBatchIdCount)
        setBatchData(res.data.data.content)
        setTotalElements(res.data.data.content[0]?.totalBatchIdCount)
        setTotalPages(res.data.data.totalPages)
      }
    }catch(error){
      setLoading(false)
      setErrorState(true)
      handleException(error)
    }
  }, [mainURL])
  
  useEffect(() => {
    fetchDataFunc()
  }, [fetchDataFunc])

  const viewBatchTransactions = (id) => {
    navigate()

    // setShowModal(prevState => !prevState)
    // const transactionFilter = transactions.filter(item => item.transactionId === id)
    // const transactionItem = transactionFilter[0]
    // setTransactionDetails(transactionItem)
  }

  const handleHover = (data) => {
    console.log("TestNavLink:", `/dashboard/transactions/batch_transactions/${data.batchId}`);
    storeNavKey(`/dashboard/transactions/batch_transactions/${data.batchId}`)
  }

  const columns = [ 
    {
      label: 'Date created',
      render: (data) => <TableDataSpan 
                          // onClick={()=>viewBatchTransactions(data.batchId)} 
                          handleMouseEnter={() => handleHover(data)}  
                          text={moment(data.createdAt).format("MMM DD, YYYY - LTS")} 
                          additionalClass='text-opacity-70 text-xs cursor-pointer'
                        />
    },
    {
      label: 'Batch ID',
      render: (data) => <TableDataSpan
                          // onClick={()=>viewBatchTransactions(data.batchId)} 
                          handleMouseEnter={() => handleHover(data)}  
                          additionalClass="cursor-default"
                          text={data.batchId}
                        />
    },
    {
      label: 'Total',
      render: (data) => <TableDataSpan
                          // onClick={()=>viewBatchTransactions(data.batchId)} 
                          handleMouseEnter={() => handleHover(data)}  
                          additionalClass="cursor-default"
                          text={data.totalCount}
                        />
    },
    {
      label: 'Pending',
      render: (data) => <TableDataSpan
                          // onClick={()=>viewBatchTransactions(data.batchId)} 
                          handleMouseEnter={() => handleHover(data)}  
                          additionalClass="cursor-default"
                          text={data.pendingCount}
                        />
    },
    {
      label: 'Processed',
      render: (data) => <TableDataSpan
                          // onClick={()=>viewBatchTransactions(data.batchId)} 
                          handleMouseEnter={() => handleHover(data)}  
                          additionalClass="cursor-default"
                          text={data.processedCount}
                        />
    },
    {
      label: 'Status',
      render: (data) => <TableDataSpan 
                          // onClick={()=>viewBatchTransactions(data.batchId)} 
                          handleMouseEnter={() => handleHover(data)}  
                          additionalClass='text-opacity-70 text-xs cursor-pointer'
                        >
                          <div className='flex gap-2 justify-start items-center'>
                            <span className={`${data.status === 'Completed' 
                                                ? 'bg-Green_Accent8 text-Success2' 
                                                : data.status === 'Failed' 
                                                ? 'bg-DangerAccent5 text-Danger4' 
                                                : 'bg-Yellow_Accent text-Yellow'} px-[10px] py-[5px] rounded-md`}>{data.status}</span>
                          </div>
                        </TableDataSpan>
    }
  ]
  
  const NavData = [
    {title: "Overview"},
    {title: "Batches"}
  ]

  console.log("BatchId:", batchData);

  return (
    <>
      <PageHeader
        pageTitle='Batch Transactions'
        pageTitleStyle=''
        exportTable={true}
        NoSearchBar={true}
        // tableKeys={searchKeys}
        // queryValue={query}
        // setQuery={setQuery}
        // setQueryKey={setQueryKey}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        tableHeaders={TransactionsHeader}
        tableData={batchData}
        csvUrl={defaultURl}
        navigation={true}
        navData={NavData}
        navDataLink={'transactions/'}
      />

      <AppTable
        data={batchData}
        columns={columns}
        loading={loading}
        errorState={errorState}
        itemsPerPage={recordsPerPage}

        dataLength={totalElements}
        getCurrentPage={getCurrentPage}
      />
      {showModal && 
        <SideComponent
          toggleModal={() => setShowModal(prevState => !prevState)}
          data={transactionDetails}
        />
      }
    </>
  )
}

const mapStateToProps = (state) => ({
  pocketTier: state.app.pocketTier,
  pocketBal: state.app.pocketBal,
  modalState: state.app.modalState,
  bearerToken: state.auth.token,
  user_data: state.auth.user_data,
  publicKey: state.auth.public_key,
  pocketId: state.auth.pocket_data.pocketId
})

const mapDispatchToProps = (dispatch) => ({
  toggleModal: () => dispatch(toggleModal()),
  getPocketTier: (data) => dispatch(getPocketTier(data)),
  getPocketBal: (data) => dispatch(getPocketBal(data)),
  storeNavKey: (data) => dispatch(storeNavKey(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Transactions)