import PageTitle from 'components/atoms/PageTitle'
import React, { useEffect } from 'react'
import Button from 'components/atoms/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import TableDataSpan from 'components/organisms/app-table/TableDataSpan';
import { getStatus } from 'utils';
import AppTable from 'components/organisms/app-table';
import exportIcon from "../../../../assets/svg/export.svg";
import { CSVLink } from 'react-csv';
import CopyText from 'components/atoms/CopyText';

const TransferDetails = () => {
  const navigate = useNavigate();

  const location: any = useLocation();
  const fileAvailable = location?.state?.fileData ? true : false;
  useEffect(() => {
      if (fileAvailable === false) {
          navigate("/dashboard/disbursement/bulk")
      }
  }, [navigate, location]);

  console.log(location)

  const combinedData = location?.state?.fileData.map((item: any) => {
    const foundItem = location?.state?.responseData?.find((i: any) => i?.data?.reference === item?.reference);
    return ({ ...item, status: getStatus(foundItem?.responseCode) })
  });
  
  const columns = [
    { 
      label:  "Reference",
      key: "reference",
      render: (row) => <TableDataSpan>{row.reference}</TableDataSpan>,
    },
    { 
      label:  "Account number",
      key: "accountNumber",
      render: (row) => <TableDataSpan>{row.accountNumber}</TableDataSpan>,
    },
    { 
      label:  "Bank code",
      key: "bankCode",
      render: (row) => <TableDataSpan>{row.bankCode}</TableDataSpan>,
    },
    { 
      label:  "Currency",
      key: "currency",
      render: (row) => <TableDataSpan>{row.currency}</TableDataSpan>,
    },
    { 
      label:  "Amount",
      key: "amount",
      render: (row) => <TableDataSpan>{row.amount}</TableDataSpan>,
    },
    { 
      label:  "Description",
      key: "description",
      render: (row) => <TableDataSpan>{row.description}</TableDataSpan>,
    },
    { 
      label:  "Status",
      key: "status",
      render: ({ status }) => (
        <TableDataSpan>
          <div className={`text-xs font-light px-2 py-1 inline-block rounded-md ${status === "SUCCESSFUL" ? 'text-green-600 bg-[#ECFDF3]' : status === "PENDING" ? "text-gray-700 bg-gray-200" : 'text-red-600 bg-[#FEF8F8]'}`}>
            <div className='flex items-center gap-2'>
              <span className={`h-2 w-2 rounded-full ${status === "SUCCESSFUL" ? "bg-green-600" : status === "PENDING" ? "bg-gray-700" : "bg-red-600"}`}></span> 
              <span>{status}</span>
            </div>
          </div>
        </TableDataSpan>
      )
    },
  ]

  const exportHeaders = columns.map((col) => ({ key: col.key, label: col.label }));

  return (
    <div>
      {(location?.state?.fileData && location?.state?.responseData) && 
        <div>
          <div className="flex justify-between items-center mb-7 mt-10">
            <PageTitle
              pageTitle='Bulk transfer summary'
              pageTitleStyle='font-bold'
              />

            <CSVLink
              data={combinedData}
              headers={exportHeaders}
              filename={`${new Date().getTime()}-bulk.csv`}
              className={`px-5 py-2 text-sm text-black font-semibold rounded-lg flex gap-2 items-center border border-gray-200 hover:bg-gray-200 hover:text-black hover:no-underline hover:border-gray-300`}
              >
              <img src={exportIcon} className='h-[14px] w-[14px]' alt="" />
              Export to CSV
            </CSVLink>
          </div>

          <div className="inline-flex items-center gap-2 p-3 px-5 mb-7 text-sm text-Blue rounded-lg bg-blue-600 bg-opacity-10" role="alert">
            <svg className="flex-shrink-0 inline w-4 h-4" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="rgb(59 130 246)" viewBox="0 0 20 20">
              <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z"/>
            </svg>
            <div>
              <span className="text-blue-500">This bulk disbursement can be track with the batch reference: <strong className='text-blue-800'><CopyText style='font-bold inline-flex gap-1' text={location?.state?.batchRef}/></strong></span>
            </div>
          </div>
          
          <div className='mt-10'>
            <AppTable
              data={combinedData}
              columns={columns}
              itemsPerPage={1000}
            />
          </div>

          <div className={`flex justify-between items-center absolute w-full bottom-0 left-0 py-8 px-16 mobile:py-4 mobile:px-10 bg-white shadow-[0_8px_30px_rgb(0,0,0,0.12)]`}>
              <Button 
                  btnType="button"
                  btnText="New Bulk Transfer" 
                  btnStyle="brounded-lg truncate flex gap-3 justify-center items-center w-[216px] mobile:w-fit mobile:px-8 px-3 py-2 font-semibold text-sm border border-gray-200 leading-7 flex-row-reverse rounded-lg truncate" 
                  disabledClass="bg-BackDrop_l_xl"
                  onClick={() => navigate('/dashboard/disbursement/bulk')}
              />

              <Button 
                  btnType="button"
                  btnText="View Progress" 
                  btnStyle="bg-DarkBg3 text-white rounded-lg truncate flex gap-3 justify-center items-center w-[216px] mobile:w-fit mobile:px-8 px-3 py-2 font-semibold flex-row-reverse rounded-lg truncate create-button" 
                  // disabled={loading ? true : false} 
                  // disabledClass="bg-BackDrop_l_xl"
                  onClick={()=>navigate(`/dashboard/transactions/batch_transactions/${location?.state?.batchRef}`)}
              />
          </div>
        </div>
      }

    </div>
  )
}

const mapStateToProps = (state: any) => ({});

export default connect(

)(TransferDetails)