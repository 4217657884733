import React from 'react'
import AppModal from 'components/organisms/CustomModal'
import copyIcon from '../../../assets/svg/copy.svg'
import Button from 'components/atoms/Button'
import { useNavigate } from 'react-router-dom'
import moment from 'moment'
import CopyText from 'components/atoms/CopyText'
import {printContent, printStyled} from '../../../components/atoms/PrintFunction'
import Pdf from 'react-to-pdf';

const SideComponent = (props) => {
    const navigate = useNavigate()

    console.log("BatchData:", props.data)

    return (
        <AppModal handleClose={props.toggleModal} modalStyle="desktop:w-1/4 mobile:w-3/4 bg-white min-h-screen z-30 top-0 right-0 animate-slide_left p-8" contentStyle="flex flex-col gap-5 overflow-y-scroll h-[92vh]">
            <div className='flex flex-col text-left'>
                <span className='font-normal text-2xl mobile:text-lg text-black mb-5'>Transaction Overview</span>
                <span className='text-[14px] mobile:text-xs leading-7 text-SecondaryAccent'>View the details of the transaction.</span>
            </div>

            <div className='flex flex-col justify-between items-center h-full'>
                <div className='flex flex-col gap-8 w-full'>
                    <div className='mb-5 flex flex-col gap-5'>
                        <div>
                            <span className='text-base mobile:text-sm leading-7 text-SecondaryAccent'>Amount</span>
                            <div className='flex gap-3 items-center'>
                                <span className='font-bold text-[28px] mobile:text-xl leading-7 text-DarkBg3'>{props.data.currency + " " + (Intl.NumberFormat().format(props.data.amount))}</span>
                                <span className={`${props.data.transactionLeg === 'CREDIT' ? 'bg-Green_Accent8 text-Success2' : 'bg-DangerAccent5 text-Danger4'} rounded-d px-2 py-1 text-xs leading-[15.6px] font-semibold`}>{props.data.transactionLeg}</span>
                            </div>
                        </div>
                        
                        <div className='flex flex-col gap-3'>
                            <span className='text-[15px] font-normal leading-7 text-SecondaryAccent'>{moment(props.data.createdAt).format("MMM DD, YYYY - LTS")}</span>
                            <CopyText 
                                text={props.data.reference}
                                textStyle="text-opacity-70 text-xs inline-block item-center bg-GrayCustom3 px-3 py-1 rounded-md"  
                                mssgStyle='-right-3 -top-[18px] hidden' 
                                imgStyle="w-4 h-4"
                            />
                        </div>
                    </div>
                    
                    <div className='flex flex-col'>
                        <span className='border-b border-SecondaryAccent2 font-semibold text-[18px] mobile:text-sm leading-6 text-DarkBg pb-4 mb-5'>
                        Payment Breakdown 
                        </span>
                        <div className='flex flex-col gap-5'>
                            <span className='w-full flex justify-between items-center'>
                                <p className='font-normal text-xs leading-3 text-Black2'>Transaction  Amount</p>
                                <p className='font-semibold text-sm leading-[16.8px] text-DarkBg8'>{props.data.currency + " " + (Intl.NumberFormat().format(props.data.amount))}</p>
                            </span>
                        </div>
                    </div>

                    <div className='flex flex-col'>
                        <span className='border-b border-SecondaryAccent2 font-semibold text-[18px] mobile:text-sm leading-6 text-DarkBg pb-4 mb-5'>
                            Other Information
                        </span>
                        <div className='flex flex-col gap-5'>
                            <div className='w-full flex justify-between items-center gap-5'>
                                <span className='font-normal text-xs leading-3 text-Black2'>Account Number</span>
                                <span className='font-semibold text-sm leading-[16.8px] text-DarkBg8'>{props.data.accountNumber}</span>
                            </div>
                            <div className='w-full flex justify-between items-center gap-5'>
                                <span className='font-normal text-xs leading-3 text-Black2'>Transation Status</span>
                                <span className={`${props.data.processingStatusCode === '00' ? 'bg-Green_Accent8 text-Success2' : props.data.processingStatusCode === 'S20' ? 'bg-Yellow_Accent text-Yellow' : 'bg-DangerAccent5 text-Danger4'} px-[10px] py-[5px] rounded-md`}>
                              {props.data.processingStatus}</span>
                            </div>
                            <div className='w-full flex justify-between items-center gap-5'>
                                <span className='font-normal text-xs leading-3 text-Black2'>Reason</span>
                                <span className='font-semibold text-sm leading-[16.8px] text-DarkBg8'>{props.data.failureReason}</span>
                            </div>
                        </div>
                    </div>
                </div>

{/* <Pdf targetRef={receiptContainer} filename={`Bank9ja-${info?.reference}.pdf`}>
{({ toPdf }: any) => <Button text="Download Receipt" onClick={toPdf} className="Button-primary" icon={DownloadIcon}/>}
</Pdf> */}

                <div className='w-full flex flex-col gap-5'>
                    {/* <Pdf targetRef={receiptContainer} filename={`Bank9ja-${info?.reference}.pdf`}>
                        <Button
                            btnType='button'
                            btnText="See detailed overview"
                            btnStyle='bg-SecondaryAccent3 text-[15px] font-normal leading-7 text-black w-full py-3'
                            onClick={() => {
                                navigate(`/dashboard/transactions/transaction_overview/${props.data.reference}`)
                                props.toggleModal()
                            }}
                        /> */}
                    {/* </Pdf> */}
                    <Button
                        btnType='button'
                        btnText="Close"
                        btnStyle='bg-DarkBg3 text-[15px] font-normal leading-7 text-white w-full py-3 '
                        onClick={props.toggleModal}
                    />
                </div>
            </div>
        </AppModal>
    )
}

export default SideComponent
