export const MerchantPocket = [
    {
        "label": "Date Created",
        "key": "createdAt"
    },
    {
        "label": "Business Name",
        "key": "businessName"
    },
    {
        "label": "Pocket Balance",
        "key": "availableBalanceAmount"
    },
    {
        "label": "Pocket ID",
        "key": "pocketId"
    },
    {
        "label": "Pocket Account Number",
        "key": "accountNumber"
    },
    {
        "label": "Pocket Reference",
        "key": "reference"
    },
    {
        "label": "Category",
        "key": "parentId"
    },
    {
        "label": "Pocket Status",
        "key": "status"
    }
]

export const TransactionsHeader = [
    {
        "label": "Date",
        "key": "createdAt"
    },
    {
        "label": "Amount",
        "key": "transactionAmount"
    },
    {
        "label": "Pocket Balace",
        "key": "availableBalanceAmount"
    },
    {
        "label": "Pocket ID",
        "key": "pocketId"
    },
    {
        "label": "Transaction Reference",
        "key": "reference"
    },
    {
        "label": "Transaction ID",
        "key": "transactionId"
    },
    {
        "label": "Transaction Type",
        "key": "transactionType"
    },
    {
        "label": "Transaction Status",
        "key": "transactionLeg"
    }
]

export const TransactionsExportHeader = [
    {
        "label": "Date",
        "key": "createdAt"
    },
    {
        "label": "Amount",
        "key": "transactionAmount"
    },
    {
        "label": "Pocket Balace",
        "key": "availableBalanceAmount"
    },
    {
        "label": "Pocket ID",
        "key": "pocketId"
    },
    {
        "label": "Transaction Reference",
        "key": "reference"
    },
    {
        "label": "Transaction ID",
        "key": "transactionId"
    },
    {
        "label": "Transaction Type",
        "key": "transactionType"
    },
    {
        "label": "Transaction Status",
        "key": "transactionLeg"
    },
    {
        "label": "Narration",
        "key": "narration"
    },
    {
        "label": "Transaction Source",
        "key": "transactionSource"
    }
]

export const PocketTransactionHeader = [
    {
        "label": "Reference ID",
        "key": "reference"
    },
    {
        "label": "Transaction Type",
        "key": "transactionLeg"
    },
    {
        "label": "Date",
        "key": "createdAt"
    },
    {
        "label": "Narration",
        "key": "narration"
    },
    {
        "label": "Amount",
        "key": "transactionAmount"
    },
    {
        "label": "Status",
        "key": "message"
    }
]