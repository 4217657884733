import React, { useEffect } from 'react'
import { Outlet } from 'react-router-dom'
import { connect } from 'react-redux'
import AuthHeader from 'components/atoms/AuthHeader'
import authSideImg from '../../assets/images/authDisplayImg.png'

const Auth = () => {
    
    return (
        <div className='w-full h-[100vh] flex flex-row-reverse'>
            <div className='flex flex-col w-[63.611%] tablet:w-full p-8'>
                {/* <AuthHeader/> */}
                <div className='w-full h-[80vh] tablet:h-[100vh] flex fle-col justify-center items-center'>
                    <div className='w-80 h-fit flex flex-col gap-5'>
                        <img className='border-2 desktop:w-20 w-14 h-auto' src="https://assets.seerbitapi.com/images/seerbit_logo_type.png" alt="logo"/>
                        <Outlet/>
                    </div>
                </div>
            </div>
            <div className='w-[36.389%] tablet:hidden'>
                <img src={authSideImg} alt="auth_sideImg" className="w-full h-[100vh] object-cover object-center"/>
            </div>
        </div>
    )
}

const mapStateToProps = (state: any) => ({})

const mapDispatchToProps = (dispatch: any) => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Auth)