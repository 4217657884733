import {
  SET_POCKET_DATA,
  SET_POCKET_DETAILS,
  CREATE_SUB_POCKET,
  CLEAR_NEW_SUB_POCKET_DATA,
  SET_ACCOUNT_INFO,
  SET_DASHBOARD_ANALYTICS,
  SET_ALL_POCKET_DETAILS
} from './pocket.type'

export const setPocketData = (data: any[]) => ({
  type: SET_POCKET_DETAILS,
  payload: data
})

export const setPocketDetails = (data: any[]) => ({
  type: SET_POCKET_DETAILS,
  payload: data
})

export const seAlltPocketDetails = (data: any[]) => ({
  type: SET_ALL_POCKET_DETAILS,
  payload: data
})

export const createSubPocket = (data: any[]) => ({
  type: CREATE_SUB_POCKET,
  payload: data
})

export const setPocketAccountInfo = (data: any[]) => ({
  type: SET_ACCOUNT_INFO,
  payload: data
})

export const setDashboardAnalytics = (data: any[]) => ({
  type: SET_DASHBOARD_ANALYTICS,
  payload: data
})

export const clearNewSubPocketData = ()  => ({
  type: CLEAR_NEW_SUB_POCKET_DATA,
})