import React, { useEffect, useState, useCallback } from "react";
import PageHeader from "components/molecules/PageHeader";
import AppTable from "components/organisms/app-table";
import TableDataSpan from "components/organisms/app-table/TableDataSpan";
import { BASE_URL, username } from "services/http";
import Alert from "components/atoms/Alert";
import AppModal from "../../../components/organisms/CustomModal";
import {
  toggleAppEnv,
  toggleModal,
  getPocketTier,
  getPocketBal,
} from "redux/app/app.action";
import { connect } from "react-redux";
import Button from "components/atoms/Button";
import { useNavigate } from "react-router-dom";
import { ToCamelCase } from "../../../components/atoms/CaseManager";
import CopyText from "../../../components/atoms/CopyText";
import moment from "moment";
import { handleException } from "../../../utils";
import { getValues } from "../../../services/storage";
import SideComponent from "./SideComponent";
import axios from "axios";
import { TransactionsHeader, TransactionsExportHeader } from "../../../components/molecules/tableExport";
import { data } from "autoprefixer";
import SubNav from "../../../components/organisms/SubNav";

const searchKeys = [
  { title: "pocketId", value: null, link: null, action: () => null },
  { title: "reference", value: null, link: null, action: () => null },
  { title: "narration", value: null, link: null, action: () => null },
].sort();

const Transactions = ({
  toggleModal,
  modalState,
  bearerToken,
  publicKey,
  pocketId,
  getPocketTier,
  getPocketBal,
  pocketTier,
  pocketBal,
}) => {
  const [query, setQuery] = useState("");
  const [queryKey, setQueryKey] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [errorState, setErrorState] = useState(false);
  const [totalElements, setTotalElements] = useState(null);
  const [totalPages, setTotalPages] = useState(null);
  // const [transactionBals, setTransactionBals] = useState([])
  const [transactionDetails, setTransactionDetails] = useState([]);
  const [pageNum, setPageNum] = useState(null);
  const [recordsPerPage] = useState(10);

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [showModal, setShowModal] = useState(false);

  const defaultURl = `transaction/v2/search`;
  const downloadURl = `transaction/download?role=admin`;
  const [pocketMode, setPocketMode] = useState("ALL");

  const handleSetPocketMode = (data) => setPocketMode(data);

  const url =
    query.length !== 0 && (!startDate || !endDate)
      ? `${defaultURl}?${ToCamelCase(queryKey)}=${query?.toLowerCase()}`
      : query.length !== 0 && startDate !== "" && endDate !== ""
      ? `${defaultURl}?${ToCamelCase(
          queryKey
        )}=${query?.toLowerCase()}&createdOnStartDate=${moment(
          startDate
        ).format("DD-MM-YYYY")}&createdOnEndDate=${moment(endDate).format(
          "DD-MM-YYYY"
        )}`
      : startDate && endDate === ""
      ? `${defaultURl}?createdOnStartDate=${moment(startDate).format(
          "DD-MM-YYYY"
        )}`
      : startDate === "" && endDate
      ? `${defaultURl}?createdOnEndDate=${moment(endDate).format("DD-MM-YYYY")}`
      : startDate && endDate
      ? `${defaultURl}?createdOnStartDate=${moment(startDate).format(
          "DD-MM-YYYY"
        )}&createdOnEndDate=${moment(endDate).format("DD-MM-YYYY")}`
      : defaultURl;

  const mainURL = `${url}${
    url.includes("?") ? "&" : "?"
  }transactionLeg=CREDIT?size=${recordsPerPage}&page=${pageNum === null ? 0 : pageNum - 1}`;

  const getCurrentPage = (pageNumber) => {
    setPageNum(pageNumber);
  };

  const fetchDataFunc = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${BASE_URL + mainURL}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearerToken}`,
          "Public-Key": `${publicKey}`,
        },
      });
      if (res.data.code === "error") {
        setLoading(false);
        Alert("error", res.data.message);
      } else {
        setLoading(false);
        setTransactions(res.data.data.content);
        setTotalElements(res.data.data.totalElements);
        setTotalPages(res.data.data.totalPages);
      }
    } catch (error) {
      setLoading(false);
      setErrorState(true);
      handleException(error);
    }
  }, [mainURL]);

  useEffect(() => {
    fetchDataFunc();
  }, [fetchDataFunc]);

  const viewTransaction = (id) => {
    setShowModal((prevState) => !prevState);
    const transactionFilter = transactions.filter(
      (item) => item.transactionId === id
    );
    const transactionItem = transactionFilter[0];
    setTransactionDetails(transactionItem);
  };

  // console.log("viewTransaction:", transactionDetails)
  // console.log("transactions:", transactions)

  // const pocketDataDetails = [
  //   {
  //     pocketTier: data.pocketTier,
  //     pocketBal: data.availableBalanceAmount
  //   }
  // ]

  const handleHover = (data) => {
    getPocketTier(data.pocketTier);
    getPocketBal(data.availableBalanceAmount);
  };

  const columns = [
    {
      label: "Amount",
      render: (data) => (
        <TableDataSpan
          onClick={() => viewTransaction(data.transactionId)}
          handleMouseEnter={() => handleHover(data)}
          text={
            data.transactionCurrency +
            " " +
            Intl.NumberFormat().format(data.transactionAmount)
          }
          additionalClass="text-opacity-70 text-xs cursor-pointer"
        >
          <div className="flex gap-2 justify-start items-center">
            <span>
              {data.transactionCurrency +
                " " +
                Intl.NumberFormat().format(data.transactionAmount)}
            </span>
            <span
              className={`${
                data.message === "Success"
                  ? "bg-Green_Accent8 text-Success2"
                  : data.message === "Failed"
                  ? "bg-DangerAccent5 text-Danger4"
                  : "bg-SecondaryAccent11 text-Accent_blue6"
              } px-[10px] py-[5px] rounded-md`}
            >
              {data.message}
            </span>
          </div>
        </TableDataSpan>
      ),
    },
    {
      label: "Pocket ID",
      render: (data) => (
        <TableDataSpan
          handleMouseEnter={() => handleHover(data)}
          additionalClass="cursor-default"
        >
          <CopyText
            text={data.pocketId}
            textStyle="text-opacity-70 text-xs mr-2 inline-block item-center"
            mssgStyle="-right-3 -top-[18px]"
            imgStyle="w-4 h-4"
          />
        </TableDataSpan>
      ),
    },
 
    {
      label: "Pocket Balance",
      render: (data) => (
        <TableDataSpan
          onClick={() => viewTransaction(data.transactionId)}
          handleMouseEnter={() => handleHover(data)}
          text={
            data.transactionCurrency +
            " " +
            Intl.NumberFormat().format(data.availableBalanceAmount)
          }
          additionalClass="text-opacity-70 text-xs cursor-pointer"
        />
      ),
    },
    {
      label: "Receiver Name",
      render: (data) => (
        <TableDataSpan
          onClick={() => viewTransaction(data.transactionId)}
          handleMouseEnter={() => handleHover(data)}
          text={data.receiverName}
          additionalClass="text-opacity-70 text-xs cursor-pointer"
        />
      ),
    },
    {
      label: "Payment Reference",
      render: (data) => (
        <TableDataSpan
          onClick={() => viewTransaction(data.transactionId)}
          handleMouseEnter={() => handleHover(data)}
          additionalClass="cursor-default"
        >
          <CopyText
            text={data.reference}
            textStyle="text-opacity-70 text-xs mr-2 inline-block item-center"
            mssgStyle="-right-3 -top-[18px]"
            imgStyle="w-4 h-4"
          />
        </TableDataSpan>
      ),
    },
    {
      label: "Date Created",
      render: (data) => (
        <TableDataSpan
          onClick={() => viewTransaction(data.transactionId)}
          handleMouseEnter={() => handleHover(data)}
          text={moment(data.createdAt).format("MMM DD, YYYY - LTS")}
          additionalClass="text-opacity-70 text-xs cursor-pointer"
        />
      ),
    },
  ];

  const NavData = [{ title: "Overview" }, { title: "Batches" }];

  return (
    <>
      <PageHeader
        pageTitle="Transactions"
        pageTitleStyle=""
        exportTable={true}
        tableKeys={searchKeys}
        queryValue={query}
        setQuery={setQuery}
        setQueryKey={setQueryKey}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        tableHeaders={TransactionsHeader}
        exportHeaders={TransactionsExportHeader}
        tableData={transactions}
        exportType={"TRANSACTION"}
        csvUrl={
          query.length !== 0
            ? `${defaultURl}${defaultURl.includes("?") ? "&" : "?"}${ToCamelCase(queryKey)}=${query?.toLowerCase()}`
            : defaultURl
        }
        navigation={true}
        navData={NavData}
        navDataLink={"transactions/"}
      />
      <AppTable
        data={transactions}
        columns={columns}
        loading={loading}
        errorState={errorState}
        itemsPerPage={recordsPerPage}
        dataLength={totalElements}
        getCurrentPage={getCurrentPage}
      />
      {showModal && (
        <SideComponent
          toggleModal={() => setShowModal((prevState) => !prevState)}
          data={transactionDetails}
        />
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  pocketTier: state.app.pocketTier,
  pocketBal: state.app.pocketBal,
  modalState: state.app.modalState,
  bearerToken: state.auth.token,
  user_data: state.auth.user_data,
  publicKey: state.auth.public_key,
  pocketId: state.auth.pocket_data.pocketId,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModal: () => dispatch(toggleModal()),
  getPocketTier: (data) => dispatch(getPocketTier(data)),
  getPocketBal: (data) => dispatch(getPocketBal(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Transactions);
