import React, { useCallback, useEffect, useState } from 'react';
import {
  FaHome,
  FaReceipt,
  FaFileInvoiceDollar,
  FaCodeBranch,
  FaGetPocket,
} from 'react-icons/fa';
import {
  MdMessage,
  MdKeyboardArrowDown,
  MdPayments,
  MdPeopleAlt,
  MdSubscriptions,
} from 'react-icons/md';
import { GoPackage } from 'react-icons/go';
import { HiChevronUpDown, HiShoppingCart } from 'react-icons/hi2';
import { HiDeviceMobile } from 'react-icons/hi';
import {
  IoLinkSharp,
  IoSettings,
  IoReceiptOutline,
  IoReceipt,
} from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import SideNavItem from 'components/atoms/SideNavItem';
import {
  HomeIconSolid,
  HomeIconOutline,
  SettingsIconOutline,
  ReceiptSolid,
  ReceiptOutline,
  AccountSolid,
  AccountOutline,
  DisbursemenntSolid,
  DisbursemenntOutline,
  SeerbitLogoGray,
} from '../atoms/ImagesImport';
import Alert from 'components/atoms/Alert';
import { getValues } from 'services/storage';
import { selectNavItem, storeCurrentPath } from 'redux/app/app.action';
import { ToTitleCase } from 'components/atoms/CaseManager';
import downArrow from '../../assets/svg/arrow-down-light.svg'
import logoutIcon_w from '../../assets/svg/logout-white.svg'
import { logoutFunc } from 'utils'
// import Settings from 'pages/dashboard/Settings'

type Props = {
  setModalId?: (id: string) => void;
  setShowModal?: (value: any) => void;
  selectedNavItem: any;
  selectNavItem: (value: any) => void;
  currentPath: any;
  storeCurrentPath: (value: any) => void;
};

const SideNav: React.FC<Props> = ({
  setModalId,
  setShowModal,
  selectedNavItem,
  selectNavItem,
  currentPath,
  storeCurrentPath,
}) => {
  const [showDropdown, setShowDropdown] = useState(false)
  const [selected, setSelected] = useState('home');
  const username = getValues('username');
  const accountType = getValues('pocketType');

  const navItems = [
    {
      text: 'Home',
      icon1: <HomeIconSolid />,
      icon2: <HomeIconOutline />,
      style: ` block py-4`,
      available: true,
      status: '',
    },

    {
      text: 'Accounts',
      icon1: <AccountSolid />,
      icon2: <AccountOutline />,
      style: `py-4`,
      available: true,
      status: '',
    },

    {
      text: 'Transactions',
      icon1: <ReceiptSolid />,
      icon2: <ReceiptOutline />,
      style: ` block py-4`,
      available: true,
      status: '',
    },

    {
      text: 'Disbursement',
      icon1: <DisbursemenntSolid />,
      icon2: <DisbursemenntOutline />,
      style: `py-4`,
      available: true,
      status: '',
    },

    {
      text: 'Settings',
      icon1: <IoSettings className='w-5 h-5' />,
      icon2: <SettingsIconOutline />,
      style: `py-4`,
      available: true,
      status: '',
    },
  ];

  useEffect(() => {
    const path = window.location.pathname.split('/')[2];
    switch (path) {
      case 'accounts':
        return setSelected('accounts');
      case 'transactions':
        return setSelected('transactions');
      case 'disbursement':
        return setSelected('disbursement');
      default:
        return setSelected('home');
    }
  }, []);

  const handleNavItemClick = (e, item) => {
    if (!item.available) {
      return;
    }
    setSelected(e.target.dataset.name);
    setModalId && setModalId(e.target.id)
    setShowModal && setShowModal(false)
  };

  return (
    <div className='h-fit sticky top-0 mobile:w-full px-6 mobile:px-0'>
      <div className='flex flex-col justify-between bg-black relative h-[100vh]'>
        <div className='top-0 mt-4 mobile:mx-5 bg-GrayCustom4 rounded-md relative'>
          <div className='flex justify-between items-center gap-3 bg-GrayCustom4 rounded-md py-2 px-2 flex-shrink-0 overflow-auto'>
            <span className='flex justify-center items-center flex-shrink-0 bg-DarkBg3 rounded-full w-8 h-8 border border-Background font-bold text-base leading-4 text-center mx-auto text-SecondaryAccent4'>
              {username?.slice(0,2).toUpperCase()}
            </span>
            <span className='overflow-x-scroll custom_container'>
              <p className='font-normal text-xs leading-5 text-SecondaryAccent5'>{accountType === 'SUB_POCKET' ? 'Sub Pocket Account' : 'Business Account'}</p>
              <p className='font-normal text-sm leading-5 text-wrap text-Black3 truncate'>{username}</p>
            </span>
            <span className='text-Black3 hidden mobile:block' onClick={() => setShowDropdown(prevState => !prevState)}>
              <img src={downArrow} alt='dropdown_arrowIcon' className='w-5 h-5'/>
            </span>
          </div>
          {showDropdown &&
            <div className='p-2 animate-slide_down relative z-20 bottom-0 mobile:flex hidden flex-col'>
              <div className='flex gap-4 bg-BackDrop_d_xs px-5 py-3 rounded-md' onClick={logoutFunc}>
                <span className=''>
                  <img src={logoutIcon_w} alt='logoutIcon' className='w-6 h-6'/>
                </span>
                <span className='text-base font-normal leading-5 text-white'>
                  Logout
                </span>
              </div>
            </div>
          }
        </div>
        <div className='w-full flex justify-center'>
          <div className={`w-full mobile:w-4/5 flex flex-col gap-2 my-auto absolute ${showDropdown ? 'top-40' : 'top-28'}`}>
            {navItems.map((item, index) => (
              <SideNavItem
                key={index}
                icon1={item.icon1}
                icon2={item.icon2}
                text={item.text}
                style={item.style}
                available={item.available}
                active={
                  item.text.toLocaleLowerCase().replace(' ', '_') === selected
                }
                url={`/dashboard/${item.text
                  .replace(' ', '_')
                  .toLocaleLowerCase()}`}
                onClick={(e) => handleNavItemClick(e, item)}
              />
            ))}
          </div>
        </div>

        <div className='bottom-0 flex justify-center p-5'>
          <SeerbitLogoGray />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  darkMode: state.app.darkMode,
  currentUser: state.auth.device_auth_data,
  loggedUserData: state.auth.setLoggedUserData,
  selectedNavItem: state.app.selectedNavItem,
  currentPath: state.app.currentPath,
});

const mapDispatchToProps = (dispatch: any) => ({
  selectNavItem: (data: any) => dispatch(selectNavItem(data)),
  storeCurrentPath: (data: any) => dispatch(storeCurrentPath(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SideNav);