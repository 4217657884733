import Alert from 'components/atoms/Alert';
import Button from 'components/atoms/Button';
import PageTitle from 'components/atoms/PageTitle';
import Spinner from 'components/atoms/Spinner';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { cancelFileUpload, clearBatchID } from 'redux/app/app.action';
import axios from 'axios';
import { handleException } from 'utils';
import loadingImg from '../../../../assets/images/CSV import illustration.svg';

const AuthProcessing = (props) => {
  const [loading, setLoading] = useState(false);
  const [transactionStatus, setTransactionStatus] = useState(false);
  const newBatchId = useRef('')

  const navigate = useNavigate();
  const location: any = useLocation();
  const fileAvailable = location?.state?.fileData ? true : false;
  const transferType = location.state.selected;

  
  useEffect(() => {
    if (fileAvailable === false) {
      navigate('/dashboard/disbursement/bulk');
    }
  }, [navigate, location]);

    const initiateBulkDisbursement = async () => {
        setLoading(true);
        try {
        const res = await axios.post(
                    transferType === "sub_pocket_tf"
          ? `https://pocket.seerbitapi.com/pocket/v2/payout/bulk/pocket-to-pocket`
          : `https://pocket.seerbitapi.com/pocket/v2/payout/bulk/pocket-id/${location?.state?.pocketID || props.pocketId}`,
        transferType === "sub_pocket_tf"
          ? location?.state?.fileData.map((data) => ({
              amount: data.amount,
              currency: data.currency,
              reference: data.reference,
              description: data.description,
              fromPocket: props.pocketId,
              toPocket: data.pocketId,
            }))
          : location?.state?.fileData,
            {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${props.bearerToken}`,
                'Public-Key': `${props.public_key}`,
            },
            }
        );
        if (res.data.status.toString() !== '00') {
            console.log("BatchRes:",res);
            Alert('error', res.data.message);
        } else {
            setLoading(false);
            // Alert('success', 'Bulk Disbursement Processing');
            Alert('success', res.data.message);
            setTransactionStatus(true);
            const messageWords = res.data.message.split(' ');
            const batchRef = messageWords[messageWords.length - 1];

            newBatchId.current = res.data.data.batchId
            // navigate('/dashboard/disbursement/bulk-transfer-details', {
            // state: {
            //     fileData: location?.state?.fileData,
            //     responseData: res.data.data,
            //     batchRef,
            // },
            // });

            console.log("BatchRes:",res);
            
        }
        } catch (error: any) {
        setLoading(false);
        handleException(error);
        Alert('error', error.message);
        }
    };

  useEffect(() => {
    initiateBulkDisbursement();
  }, []);

  const handleNewTransfer = () => {
    props.cancelFileUpload();
    navigate('/dashboard/disbursement/bulk');
  };

  return (
    <div className='flex flex-col gap-5 justify-between'>
      <div className='py-5 mobile:p-0 mobile:rounded-[15px] min-h-[70vh] overflow-y-scroll custom_container mb-10'>
        <div className='flex justify-center items-center min-h-[65vh] p-5 mobile:p-3 rounded-[15px]'>
          {loading && (
            <div
              className={`absolute z-10 bottom-60 left-0 right-0 mx-auto flex flex-col justify-end items-center py-10 rounded-[15px] w-fit h-auto`}
            >
              <Spinner />
            </div>
          )}

          <div className='flex flex-col gap-8 justify-between items-center w-fit h-auto p-10 mobile:p-5 mobile:my-[4rem] rounded-[10px]'>
            <span className='w-[209px] h-[134px]'>
              <img
                src={loadingImg}
                alt='loadingImg'
                className='w-full h-full object-cover object-fit object-center'
              />
            </span>
            <div className='p-3 flex flex-col justify-center gap-5 items-center'>
              <span className='font-semibold text-base leading-[26px] text-Black6 text-center'>
                Please wait, we're processing your payment
              </span>
              <span className='text-sm font-normal leading-[22px] text-center'>
                    <p>The Batch ID generated for your request is <b>{newBatchId.current}</b></p>
                    {/* <br/>You can continue to work on other things while we create your payments. */}
                    {/* <br/>We'll email you as soon as we're done. 
                    <Link to='' className='decoration-none hover:underline text-Accent_blue ml-[2px]'>Click here to track your payment status</Link>
                    </p> */}
                </span>
            </div>
          </div>
        </div>
      </div>

            <div className={`justify-between flex items-center absolute w-full bottom-0 left-0 py-8 px-16 mobile:py-4 mobile:px-10 bg-white shadow-[0_8px_30px_rgb(0,0,0,0.12)]`}>                

                <Button 
                    btnType="button"
                    btnText="New Bulk Transfer" 
                    btnStyle="brounded-lg truncate flex gap-3 justify-center items-center w-[216px] mobile:w-fit mobile:px-8 px-3 py-2 font-semibold text-sm border border-gray-200 leading-7 flex-row-reverse rounded-lg truncate" 
                    // disabled={loading ? true : false} 
                    // disabledClass="bg-BackDrop_l_xl"
                    onClick={handleNewTransfer}
                />

                <Button 
                    btnType="button"
                    btnText="View Progress" 
                    btnStyle="bg-DarkBg3 text-white rounded-lg truncate flex gap-3 justify-center items-center w-[216px] mobile:w-fit mobile:px-8 px-3 py-2 font-semibold flex-row-reverse rounded-lg truncate create-button" 
                    // disabled={loading ? true : false} 
                    // disabledClass="bg-BackDrop_l_xl"
                    onClick={newBatchId.current === '' ? ()=>Alert('error','Invalid Batch ID') : ()=>navigate(`/dashboard/transactions/batch_transactions/${newBatchId.current}`)}
                />
            </div>
        </div>
    ) 
}

const mapStateToProps = (state: any) => ({
  fileData: state.app?.fileData,
  bearerToken: state.auth.token,
  public_key: state.auth.public_key,
  pocketId: state.auth.pocket_data?.pocketId,
  currentBatchID: state.app.currentBatchID,
});

const mapDispatchToProps = (dispatch: any) => ({
  clearBatchID: () => dispatch(clearBatchID()),
  cancelFileUpload: () => dispatch(cancelFileUpload()),
});

export default connect(mapStateToProps, mapDispatchToProps)(AuthProcessing);


























// import Alert from "components/atoms/Alert";
// import Button from "components/atoms/Button";
// import PageTitle from "components/atoms/PageTitle";
// import Spinner from "components/atoms/Spinner";
// import React, { useEffect, useRef, useState } from "react";
// import { connect } from "react-redux";
// import { Link, useLocation, useNavigate } from "react-router-dom";
// import { cancelFileUpload, clearBatchID } from "redux/app/app.action";
// import axios from "axios";
// import { handleException } from "utils";
// import loadingImg from "../../../../assets/images/CSV import illustration.svg";

// const AuthProcessing = (props) => {
//   const [loading, setLoading] = useState(false);
//   const [transactionStatus, setTransactionStatus] = useState(false);
//   const newBatchId = useRef("");

//   const navigate = useNavigate();
//   const location: any = useLocation();
//   const transferType = location.state.selected;

//   console.log(location.selected)
//   const fileAvailable = location?.state?.fileData ? true : false;
//   useEffect(() => {
//     if (fileAvailable === false) {
//       navigate("/dashboard/disbursement/bulk");
//     }
//   }, [navigate, location]);

//   const initiateBulkDisbursement = async () => {
//     setLoading(true);
//     try {
//       const res = await axios.post(
//         transferType === "sub_pocket_tf"
//           ? `https://pocket.seerbitapi.com/pocket/v2/payout/bulk/pocket-to-pocket`
//           : `https://pocket.seerbitapi.com/pocket/v2/payout/bulk/pocket-id/${props.pocketId}`,
//         transferType === "sub_pocket_tf"
//           ? location?.state?.fileData.map((data) => ({
//               amount: data.amount,
//               currency: data.currency,
//               reference: data.reference,
//               description: data.description,
//               fromPocket: props.pocketId,
//               toPocket: data.pocketId,
//             }))
//           : location?.state?.fileData,
//         {
//           headers: {
//             "Content-Type": "application/json",
//             Authorization: `Bearer ${props.bearerToken}`,
//             "Public-Key": `${props.public_key}`,
//           },
//         }
//       );
//       if (res.data.status.toString() !== "00") {
//         console.log("BatchRes:", res);
//         Alert("error", res.data.message);
//       } else {
//         setLoading(false);
//         // Alert('success', 'Bulk Disbursement Processing');
//         Alert("success", res.data.message);
//         setTransactionStatus(true);
//         const messageWords = res.data.message.split(" ");
//         const batchRef = messageWords[messageWords.length - 1];

//         newBatchId.current = res.data.data.batchId;
//         navigate("/dashboard/disbursement/bulk-transfer-details", {
//           state: {
//             fileData: location?.state?.fileData,
//             responseData: res.data.data,
//             batchRef,
//             selected: transferType
//           },
//         });

//         console.log("BatchRes:", res);
//       }
//     } catch (error: any) {
//       setLoading(false);
//       handleException(error);
//       Alert("error", error.message);
//     }
//   };

//   useEffect(() => {
//     initiateBulkDisbursement();
//   }, []);

//   const handleNewTransfer = () => {
//     props.cancelFileUpload();
//     navigate("/dashboard/disbursement/bulk");
//   };

//   return (
//     <div className="flex flex-col gap-5 justify-between">
//       <div className="py-5 mobile:p-0 mobile:rounded-[15px] min-h-[70vh] overflow-y-scroll custom_container mb-10">
//         <div className="flex justify-center items-center min-h-[65vh] p-5 mobile:p-3 rounded-[15px]">
//           {loading && (
//             <div
//               className={`absolute z-10 bottom-60 left-0 right-0 mx-auto flex flex-col justify-end items-center py-10 rounded-[15px] w-fit h-auto`}
//             >
//               <Spinner />
//             </div>
//           )}

//           <div className="flex flex-col gap-8 justify-between items-center w-fit h-auto p-10 mobile:p-5 mobile:my-[4rem] rounded-[10px]">
//             <span className="w-[209px] h-[134px]">
//               <img
//                 src={loadingImg}
//                 alt="loadingImg"
//                 className="w-full h-full object-cover object-fit object-center"
//               />
//             </span>
//             <div className="p-3 flex flex-col justify-center gap-5 items-center">
//               <span className="font-semibold text-base leading-[26px] text-Black6 text-center">
//                 Please wait, we're processing your payment
//               </span>
//               <span className="text-sm font-normal leading-[22px] text-center">
//                 <p>
//                   The Batch ID generated for your request is{" "}
//                   <b>{newBatchId.current}</b>
//                 </p>
//                 {/* <br/>You can continue to work on other things while we create your payments. */}
//                 {/* <br/>We’ll email you as soon as we’re done. 
//                     <Link to='' className='decoration-none hover:underline text-Accent_blue ml-[2px]'>Click here to track your payment status</Link>
//                     </p> */}
//               </span>
//             </div>
//           </div>
//         </div>
//       </div>

//       <div
//         className={`justify-between flex items-center absolute w-full bottom-0 left-0 py-8 px-16 mobile:py-4 mobile:px-10 bg-white shadow-[0_8px_30px_rgb(0,0,0,0.12)]`}
//       >
//         <Button
//           btnType="button"
//           btnText="New Bulk Transfer"
//           btnStyle="brounded-lg truncate flex gap-3 justify-center items-center w-[216px] mobile:w-fit mobile:px-8 px-3 py-2 font-semibold text-sm border border-gray-200 leading-7 flex-row-reverse rounded-lg truncate"
//           // disabled={loading ? true : false}
//           // disabledClass="bg-BackDrop_l_xl"
//           onClick={handleNewTransfer}
//         />

//         <Button
//           btnType="button"
//           btnText="View Progress"
//           btnStyle="bg-DarkBg3 text-white rounded-lg truncate flex gap-3 justify-center items-center w-[216px] mobile:w-fit mobile:px-8 px-3 py-2 font-semibold flex-row-reverse rounded-lg truncate create-button"
//           // disabled={loading ? true : false}
//           // disabledClass="bg-BackDrop_l_xl"
//           onClick={
//             newBatchId.current === ""
//               ? () => Alert("error", "Invalid Batch ID")
//               : () =>
//                   navigate(
//                     `/dashboard/transactions/batch_transactions/${newBatchId.current}`
//                   )
//           }
//         />
//       </div>
//     </div>
//   );
// };

// const mapStateToProps = (state: any) => ({
//   fileData: state.app?.fileData,
//   bearerToken: state.auth.token,
//   public_key: state.auth.public_key,
//   pocketId: state.auth.pocket_data?.pocketId,
//   currentBatchID: state.app.currentBatchID,
// });

// const mapDispatchToProps = (dispatch: any) => ({
//   clearBatchID: () => dispatch(clearBatchID()),
//   cancelFileUpload: () => dispatch(cancelFileUpload()),
// });

// export default connect(mapStateToProps, mapDispatchToProps)(AuthProcessing);
