import React, { useEffect, useState } from 'react'
import FormInput from 'components/atoms/FormInput'
import { useNavigate } from 'react-router-dom'
import { logoutHandler, setAuthRes, setUserLoggedIn } from 'redux/auth/auth.action'
import { setValues, removeValues, getValues } from 'services/storage'
import { NotificationBell } from '../atoms/ImagesImport'
import arrowDownIcon from '../../assets/svg/arrow-down.svg'
import arrowUpIcon from '../../assets/svg/arrow-up.svg'
import userIcon from '../../assets/svg/navDropdownIcon/user.svg'
import profileTick from '../../assets/svg/navDropdownIcon/profile-tick.svg'
import configureIcon from '../../assets/svg/navDropdownIcon/configure.svg'
import messageIcon from '../../assets/svg/navDropdownIcon/message.svg'
import logoutIcon from '../../assets/svg/navDropdownIcon/logout.svg'
import searchIcon from '../../assets/svg/search-icon.svg'
import menuIcon from '../../assets/svg/menu-icon.svg'
import seerbitBoxedLogo from '../../assets/svg/SeerBit boxed Icon SVG.svg'
import { connect } from 'react-redux'
// import { toggleModal } from '../../redux/app/app.action'
import AppModal from '../organisms/CustomModal'
import SideNav from './SideNav'
import Dropdown from 'components/atoms/Dropdown'
import UserProfileNav from './UserProfileNav'

const MobileNav = ({logoutHandler}) => {
    const [value, setValue] = useState("")
    const [dropdown, setDropdown] = useState(false)
    const [showModal, setShowModal] = useState(false)
    const [modalId, setModalId] = useState("")


    const navigate = useNavigate()

    const handleOnChange = e => {
        setValue(e.target.value)
    }

    const handleModal = (e) => {
        setModalId(e.target.id)
        setShowModal(prevState => !prevState)
    }

    const modalContent = (
        <AppModal handleClose={handleModal} modalStyle="bg-white min-h-screen z-40 top-0 left-0 animate-slide_right2 flex justify-center items-center" contentStyle="h-full w-full absolute top-0 left-0 right-0">
            <SideNav setShowModal={setShowModal} setModalId={setModalId}/>
        </AppModal>
    )

    const username = getValues('username')
    
    return (
        <>
            <span className='justify-center ml-8' onClick={e=>handleModal(e)}>
                <img src={seerbitBoxedLogo} alt="menu_icon" className='w-8 h-8' id="mainNav"/>
            </span>
            <span className='flex items-center gap-3 mr-8'>
                <div className='flex flex-shrink-0 justify-center items-center bg-DarkBg3 rounded-full w-8 h-8' onClick={() =>  navigate('/dashboard/settings/profile')}>
                    <span className='flex-shrink-0 font-bold text-lg leading-4 text-center mx-auto text-SecondaryAccent4'>{username?.slice(0,2).toUpperCase()}</span>
                </div>
            </span>

            {showModal && modalId === "mainNav" && modalContent}
        </>
    )
}

const mapDispatchToProps = (dispatch:any) => ({
    logoutHandler: () => dispatch(logoutHandler()),
})

export default connect(null, mapDispatchToProps)(MobileNav)