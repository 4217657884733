import React, { useEffect, useState } from "react";
import axios from "services/axios";
import Dropdown from "components/atoms/Dropdown/index";
import walletIcon_active from "../../../../assets/svg/wallet-d-outline.svg";
import bankIcon_active from "../../../../assets/svg/bank.svg";
import walletIcon from "../../../../assets/svg/wallet-outline-grey.svg";
import bankIcon from "../../../../assets/svg/bank_grey.svg";
import arrow_left from "../../../../assets/svg/arrow-left.svg";
import arrow_right from "../../../../assets/svg/arrow-right.svg";

import accessBank from "../../../../assets/svg/banks/access.svg";
import firstBank from "../../../../assets/svg/banks/fbn.svg";
import gtBank from "../../../../assets/svg/banks/gtb.svg";
import zenithBank from "../../../../assets/svg/banks/zenith.svg";

import arrowUp from "../../../../assets/svg/arrow-up.svg";
import arrowDown from "../../../../assets/svg/arrow-down.svg";

import FormInput from "components/atoms/FormInput";
import Button from "components/atoms/Button";
import Alert from "components/atoms/Alert";
import { BASE_URL, username } from "services/http";
import { connect } from "react-redux";
import { storeBanklist, toggleModal } from "redux/app/app.action";
import SideComponent from "../SideComponent";
import Spinner from "components/atoms/Spinner";
import ring_loader from "../../../../assets/gif/ring_loader_1.gif";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import Select from "react-select";
import { getValues, setValues } from "services/storage";
import countryList from "../../../../data/country_list.json";

import {
  generateRandomAlphanumeric,
  getBusinessInitials,
  getFileType,
  handleException,
} from "utils";
import { truncate } from "lodash";

const SingleTransfer = (props) => {
  const [loading, setLoading] = useState(false);
  const [bankLoading, setBankLoading] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const [hoverStatus, setHoverStatus] = useState(false);
  const [selected, setSelected] = useState("sub_pocket_tf");
  const [selectBankName, setSelectBankName] = useState("");
  const [selectBank, setSelectBank] = useState<any>({});
  const [bankData, setBankData] = useState<any>([]);
  const [selectedBank, setSelectedBank] = useState<any>();
  const [currencies, setCurrencies] = useState<any>([]);
  const [values, setValues] = useState<any>({
    toAmount: "",
    fromAmount: "",
  });

  const [storeBankCode, setStoreBankCode] = useState("");

  const [selectedPayoutAcct, setSelectedPayoutAcct] = useState("");
  const [transactionData, setTransactionData] = useState();

  const [accountNum, setAccountNum] = useState("");
  const [beneficiaryPocketID, setBeneficiaryPocketID] = useState("");
  const [narration, setNarration] = useState("");
  const [amount, setAmount] = useState("");
  const [bankName, setBankName] = useState("");
  const [inputBankName, setInputBankName] = useState("");

  const [beneficiaryErr, setBeneficiaryErr] = useState("");
  const [amountErr, setAmountErr] = useState("");
  const [bankNameErr, setBankNameErr] = useState("");
  const [accountName, setAccountName] = useState("");

  const businessInitials =
    getBusinessInitials(props.pocketAccountInfo?.pocketOwner?.businessName) ||
    "SBT";

  const transactionRef = `${businessInitials}-S${Math.floor(
    Math.pow(12, 10 - 1) + Math.random() * 10 * Math.pow(12, 10 - 1)
  )}`;

  const navigate = useNavigate();

  const actionItemValue = "APPROVE_DISBURSEMENT_BY_HEAD_CHURCH";

  const location: any = useLocation();

  // console.log("actionItemValue_1:", process.env.ACTION_ITEM_PRIMARY_DISBURSEMENT);
  // console.log("actionItemValue_2:", process.env.ACTION_ITEM_PRIMARY_DISBURSEMENT);
  // console.log("actionItemValue_3:", actionItemValue);

  const handleSubmit = (e) => {
    e.preventDefault();

    const transactionData_ToPocket = {
      payoutAccount: values?.from?.pocketId,
      amount: values?.fromAmount,
      transactionPurpose: `convert ${values?.from?.currency} - ${values?.to?.currency}`,
      transactionRef: transactionRef,
      destination: values?.to?.pocketId,
      type: "convert",
      toAmount: values?.toAmount,
      fromCurrency: values?.from?.currency,
      toCurrency: values?.to?.currency,
    };

    if (values?.fromAmount === "") {
      Alert("error", "Amount cannot be empty");
      return;
    }

    if (values?.to?.pocketId?.length < 10) {
      Alert("error", "Select Beneficiary currency");
      return;
    }

    if (!values.rate) {
      Alert("error", "Unable to apply rate");
      return;
    }
    if (values?.to?.pocketId === "" || values?.from?.pocketId === "") {
      Alert("error", "Please, fill all fields");
      return;
    } else {
      navigate("/dashboard/convert/transaction_detail", {
        state: {
          fileData: transactionData_ToPocket,
          mode: "sub_pocket_tf",
        },
      });
    }
  };

  const handleAmount = (e) => {
    const regex = /^[0-9\b]+$/;

    if (e.target.value === "" || regex.test(e.target.value)) {
      setValues({ ...values, fromAmount: e.target.value });


      console.log(Number(e.target.value) > Number(values.from?.pocketBalance), "check")

      if (Number(e.target.value) > Number(values.from?.pocketBalance)) {
        setBeneficiaryErr("Insufficient funds in pocket");
      } else {
        setBeneficiaryErr("");
      }
    }
  };

  // const getBanks = async () => {
  //   setBankLoading(true);
  //   try {
  //     const res = await axios.get(
  //       "https://pocket.seerbitapi.com/pocket/banks",
  //       {
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${props.bearerToken}`,
  //           "Public-Key": `${props.public_key}`,
  //         },
  //       }
  //     );
  //     if (res.data.responseCode !== "00") {
  //       setBankLoading(false);
  //       Alert("error", res.data.message);
  //     } else {
  //       setBankLoading(false);
  //       setBankData(res.data.data);
  //       // storeBanklist(res.data.data)
  //       console.log("BankRes:", res);
  //       setDropdown(true);
  //       // handleDropdown()
  //     }
  //   } catch (error) {
  //     setBankLoading(false);
  //     handleException(error);
  //   }
  // };

  // const handleDropdown = () => {
  //   if (accountNum === "" || accountNum.length < 10) {
  //     Alert("error", "Enter Account number first");
  //   }
  // };

  // const handleSelectBank = (data) => {
  //   setSelectBank(data.bankcode);
  //   setSelectBankName(data.bankname);
  //   setDropdown(false);
  // };

  const fetchRate = async () => {
    setLoading(true);
    try {
      const res = await axios.post(
        "https://pocket.seerbitapi.com/pocket/rates", //"https://eoz4rjaukrwnecq.m.pipedream.net",
        {
          to: values?.to?.currency,
          from: values?.from?.currency,
          amount: values.fromAmount,
        },

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${props.bearerToken}`,
            "Public-Key": `${props.public_key}`,
          },
        }
      );
      setLoading(false);
      if (res?.data?.code === "R00") {
        setValues({
          ...values,
          rate: res?.data,
          toAmount: res?.data.to.amount,
          totalAmount: values.fromAmount,
        });
      } else {
        Alert("error", res.data.message);
        setValues({
          ...values,
          rate: "",
          toAmount: "",
        });
      }
    } catch (error: any) {
      setLoading(false);
      setAccountName("");
      handleException(error);
    }
  };

  // const fetchDropDownData = (e) => {
  //   if (accountNum === "" || accountNum.length < 10) {
  //     Alert("error", "Enter Account number first");
  //   } else {
  //     console.log("EnteredValue:", e.target.value);
  //     setInputBankName(e.target.value.toLowerCase());

  //     if (e.target.value.length >= 1) {
  //       setLoading(true);
  //       // if(dropdown){
  //       //     setLoading(false)
  //       //     setDropdown(false)
  //       // }else{
  //       if (bankData.length === 0) {
  //         //   getBanks();
  //       } else {
  //         setLoading(false);
  //         setDropdown(true);
  //       }
  //       // }
  //     }
  //   }
  // };

  // const handleHover = (data) => {
  //   setStoreBankCode(data.bankcode);
  // };

  // useEffect(() => {
  //   getBanks();
  // }, []);

  console.log(selectedBank, "selected");

  useEffect(() => {
    let getData;
    if (values.fromAmount) {
      getData = setTimeout(() => {
        fetchRate();
      }, 800);
    }

    if (Number(values.fromAmount) > Number(values.from?.pocketBalance)) {
      setBeneficiaryErr("Insufficient funds in pocket");
    } else {
      setBeneficiaryErr("");
    }

    return () => clearTimeout(getData);
  }, [values.fromAmount, values.to, values.from]);

  const accountType = getValues("pocketType");

  // setHoverStatus

  useEffect(() => {
    const country = (datax) =>
      countryList.find((country) =>
        datax.country
          ? country.alpha2 === datax?.country
          : country?.currency_code === datax?.currency
      );

    setCurrencies(
      props?.allPrimaryPockets?.map((data) => {
        return {
          ...data,
          country: country(data),
          label: `${country(data)?.name} - ${data?.currency} ${Number(
            data.pocketBalance
          )
            ?.toFixed(2)
            .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`,
          value: data.pocketId,
        };
      })
    );
  }, []);

  useEffect(() => {
    if (currencies.length > 0) {
      setValues({ ...values, from: currencies[0], to: currencies[1] });
    }
  }, [currencies]);
  return (
    <div>
      <form
        className="py-3 mobile:p-0 mobile:rounded-[15px] max-h-[calc(100vh-261px)] overflow-y-scroll custom_container"
        onSubmit={() => {}}
      >
        <div className="flex justify-center items-center">
          <div className="relative shadow-[rgba(17,_17,_26,_0.1)_0px_0px_16px] bg-white p-10 desktop:w-[40%] w-[80%] mobile:w-full rounded-[15px] flex flex-col gap-3">
            <div className="w-full relative">
              <label className={`text-base leading-6 text-[#000] my-2`}>
                Convert money from
              </label>
              <Select
                options={currencies?.map((data: any) => ({
                  ...data,
                  label: `${data?.country?.name} - ${data?.currency} ${Number(
                    data.pocketBalance
                  )
                    ?.toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")} `,
                  value: data.pocketId,
                }))}
                isLoading={false}
                name="from"
                value={values.from}
                onChange={(e: any) => {
                  // console.log(`bank_name`, e);
                  setValues({
                    ...values,
                    from: {
                      ...e,
                    },
                  });
                }}
                placeholder="Select Pocket"
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    width: "100%",
                    zIndex: 345,
                    marginTop: "10px",
                    borderRadius: "5px",
                  }),
                }}
              />
            </div>

            <FormInput
              type="text"
              name="beneficiary_pocketId"
              placeholder="00.00"
              style="w-full my-0"
              inputStyle="border border-transparent text-Black2"
              label="Amount"
              labelStyle="text-base leading-e6 text-[#000] my-2"
              value={values.fromAmount}
              onChange={(e) => handleAmount(e)}
              validationErr={beneficiaryErr}
              icon={
                values?.from?.country?.flag
                  ? values?.from?.country?.flag?.toLowerCase()
                  : values?.from?.country?.name?.toLowerCase() || ""
              }
              currency={values?.from?.currency || ""}
            />

            <div className="bg-[#F7F8F9] w-full rounded text-[16px] p-4 my-4">
              <div className="flex justify-between items-center">
                <p className="text-[#000] m-0 p-0">Fee</p>
                {loading ? (
                  <div className="flex items-center gap-2 animate-pulse">
                    <div className="h-6 bg-gray-200 rounded-full w-20"></div>
                  </div>
                ) : (
                  <p className="text-[green] m-0 p-0 font-semiboldg">{`${values?.from?.currency} 0.00`}</p>
                )}
              </div>
              <div className="flex justify-between items-center my-4">
                <p className="text-[#000] m-0 p-0">Total Amount</p>
                {loading ? (
                  <div className="flex items-center gap-2 animate-pulse">
                    <div className="h-6 bg-gray-200 rounded-full w-24"></div>
                  </div>
                ) : (
                  <p className="text-[#000] m-0 p-0 font-semiboldg">{`${
                    values?.from?.currency
                  } ${Number(values.totalAmount || 0)
                    ?.toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")}`}</p>
                )}
              </div>
              <div className="flex justify-between items-center mt-4 border-t pt-3">
                <p className="text-[#000] m-0 p-0">Today's Rate</p>
                {loading ? (
                  <div className="flex items-center gap-2 animate-pulse">
                    <div className="h-6 bg-gray-200 rounded-full w-28"></div>
                  </div>
                ) : (
                  <p className="text-[#000] m-0 p-0 font-semibold">{`1 ${
                    values?.from?.currency
                  } = ${Number(values?.rate?.to?.rate || "0.00")
                    ?.toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")} ${
                    values?.to?.currency
                  }`}</p>
                )}
              </div>
            </div>

            <div className="w-full relative">
              <label className={` text-base leading-6 text-[#000] my-2`}>
                Convert money To
              </label>
              <Select
                options={currencies?.map((data: any) => ({
                  ...data,
                  label: `${data?.country?.name} - ${data?.currency} ${Number(
                    data.pocketBalance
                  )
                    ?.toFixed(2)
                    .replace(/\d(?=(\d{3})+\.)/g, "$&,")} `,
                  value: data.pocketId,
                }))}
                isLoading={false}
                name="to"
                value={values.to}
                onChange={(e: any) => {
                  setValues({
                    ...values,
                    to: {
                      ...e,
                    },
                  });
                }}
                placeholder="Select Pocket"
                styles={{
                  control: (baseStyles) => ({
                    ...baseStyles,
                    width: "100%",
                    zIndex: 345000,
                    marginTop: "10px",
                    borderRadius: "5px",
                  }),
                }}
              />
            </div>

            <FormInput
              type="text"
              name="beneficiary_pocketId"
              placeholder="00.00 "
              style="w-full "
              inputStyle="border border-transparent text-Black2 text-[16px] font-semibold text-[#000]"
              label="Amount you will receive"
              labelStyle="text-base leading-e6 text-[#000] my-2"
              value={Number(values.toAmount || 0)
                ?.toFixed(2)
                .replace(/\d(?=(\d{3})+\.)/g, "$&,")}
              onChange={(e) =>
                setValues({ ...values, toAmount: e.target.value })
              }
              validationErr={""}
              icon={
                values?.to?.country?.flag
                  ? values?.to?.country?.flag?.toLowerCase()
                  : values?.to?.country?.name?.toLowerCase() || ""
              }
              currency={values?.to?.currency || ""}
              disabled={true}
            />
          </div>
        </div>
      </form>
      {accountType !== "PRIMARY_POCKETc" && (
        <div className="flex justify-between items-center absolute w-full bottom-0 left-0 py-8 px-16 mobile:py-4 mobile:px-10 bg-white shadow-[0_8px_30px_rgb(0,0,0,0.12)]">
          <Button
            btnType="button"
            btnText="Cancel"
            btnStyle="brounded-lg truncate flex gap-3 justify-center items-center w-[216px] mobile:w-fit mobile:px-8 px-3 py-2 font-semibold text-sm border border-gray-200 leading-7 flex-row-reverse rounded-lg truncate"
            disabled={false}
            disabledClass=""
            onClick={() => navigate("/dashboard/home")}
          />
          <Button
            btnType="submit"
            btnText="Convert"
            btnStyle="bg-DarkBg3 text-white rounded-lg truncate flex gap-3 justify-center items-center w-[216px] mobile:w-fit mobile:px-8 px-3 py-2 font-semibold flex-row-reverse rounded-lg truncate create-button"
            disabled={loading}
            disabledClass=""
            onClick={handleSubmit}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: any) => ({
  bankList: state.app.bankList,
  fileData: state.app?.fileData,
  bearerToken: state.auth.token,
  public_key: state.auth.public_key,
  modalState: state.app.modalState,
  pocketId: state.auth.pocket_data?.pocketId,
  pocketAccountInfo: state.pocket.pocketAccountInfo,
  allPrimaryPockets: state.pocket.allPrimaryPockets,
});

const mapDispatchToProps = (dispatch: any) => ({
  toggleModal: () => dispatch(toggleModal()),
  storeBanklist: (data: any) => dispatch(storeBanklist(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SingleTransfer);
