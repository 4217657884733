import React, { useState, useCallback } from "react";
import axios from "services/axios";
import { Link, useNavigate } from "react-router-dom";
import Button from "components/atoms/Button";
import FormInput from "components/atoms/FormInput";
import Spinner from "components/atoms/Spinner";
import showPwd from "../../../assets/svg/show.svg";
import hidePwd from "../../../assets/svg/hide.svg";
import { BASE_URL, getRequest, postRequest } from "services/http";
import Alert from "components/atoms/Alert";
import { handleException } from "utils";
import {
  logoutHandler,
  setAuthRes,
  setBearerToken,
  setPocketData,
  setPublicKey,
  setUserData,
  setUserLoggedIn,
} from "redux/auth/auth.action";
import { connect } from "react-redux";
import { getValues, setValues } from "services/storage";
import { setLandingPageData } from "redux/app/app.action";
import { seAlltPocketDetails } from "redux/pocket/pocket.action";
import AppModal from "components/organisms/CustomModal";

const Login = ({
  setAuthRes,
  auth_data,
  setBearerToken,
  bearerToken,
  setUserData,
  user_data,
  setUserLoggedIn,
  setPublicKey,
  pocket_data,
  primaryPocket_data,
  setPocketData,
  setLandingPageData,
  landingPage_data,
  seAlltPocketDetails,
  publicKey,
}) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [emailErr, setEmailErr] = useState("");
  const [passwordErr, setPasswordErr] = useState("");

  const [fetchStatus, setFetchStatus] = useState("");

  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [errorState, setErrorState] = useState(false);

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const navigate = useNavigate();
  const formComplete = email !== "" && password !== "";
  const disabledBtn = formComplete === false || loading === true;

  // const fetchPageData = async (token:any, public_key:any) => {
  //     try{
  //       const res = await axios.get(`${BASE_URL}landingpage`, {
  //         headers: {
  //             "Content-Type": "application/json",
  //             "Authorization": `Bearer ${token}`,
  //             "Public-Key": `${public_key}`
  //         }
  //       })
  //       if(res.data.code === "error") {
  //         Alert("error", res.data.message);
  //       } else {
  //         setLandingPageData({...landingPage_data, ...res.data.data})
  //         console.log("Dashboard_data:", res.data.data);

  //       }
  //     }catch(error){
  //       handleException(error)
  //     }
  // }

  const fetchUserData = async (token: any) => {
    try {
      const res = await axios.get(`${BASE_URL}user/find-by-username/${email}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
      if (res.data.code === "error") {
        setLoading(false);
        Alert("error", res.data.message);
      } else {
        setUserData({ ...user_data, ...res.data.data });
        const public_key = `${
          res.data.data.isBusiness
            ? res.data.data.businessDetails.publicKey
            : process.env.REACT_APP_POCKET_PUBLIC_KEY
        }`;

        setValues(`${email}_public_key`, public_key);
        setValues("business_type", res.data.data.isBusiness);
        setPublicKey(public_key);
        setFetchStatus(res.data.code);

        await checkPocketType(token, public_key);

        await checkAllPrimaryPockets(token, public_key);

        if (res?.data.data.isBusiness) {
          await fetchPrimaryPocket(token, public_key);
          // await fetchPageData(token, public_key)
        } else {
          Alert("error", "Seerbit Merchants only.");
          setLoading(false);
          logoutHandler();
          localStorage.clear();
          sessionStorage.clear();
        }
      }
      return res;
    } catch (error) {
      setLoading(false);
      handleException(error);
    }
  };

  const fetchPrimaryPocket = async (token: any, public_key: any) => {
    try {
      const res = await axios.get(`${BASE_URL}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Public-Key": `${public_key}`,
        },
      });
      if (res.data.code === "error") {
        Alert("error", res.data.message);
      } else {
        setValues("primary_pocket_id", res.data.data.pocketId);

        console.log("Primary Pocket Response:", res);

        if (getValues("pocketType") !== "PRIMARY_POCKET") {
          const pocketID = res.data.data.pocketId;
          await fetchSubPocket(token, public_key, pocketID);
          setValues("disburse", process.env.ACTION_ITEM_OUTLET_DISBURSEMENT);
          setValues(
            "addSubPocket",
            process.env.ACTION_ITEM_OUTLET_CREATE_SUB_POCKET
          );
        } else {
          setPocketData({ ...pocket_data, ...res.data.data });
          setValues("disburse", process.env.ACTION_ITEM_PRIMARY_DISBURSEMENT);
          setValues(
            "addSubPocket",
            process.env.ACTION_ITEM_PRIMARY_CREATE_SUB_POCKET
          );
        }
      }
      return res;
    } catch (error) {
      handleException(error);
    }
  };

  const fetchSubPocket = async (token: any, public_key: any, pocketId: any) => {
    try {
      const res = await axios.get(`${BASE_URL}pocketdetail/${email}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
          "Public-Key": `${public_key}`,
        },
      });

      if (res.data.code === "error") {
        Alert("error", res.data.message);
      } else {
        console.log(res.data);
        setValues("sub_pocket_id", res.data?.data?.pocket?.pocketId);
        setPocketData({ ...pocket_data, ...res.data?.data?.pocket });

        console.log("Sub_Pocket_Response:", res?.data?.data?.pocket);
      }

      return res;
    } catch (error) {
      handleException(error);
    }
  };

  const checkPocketType = async (token: any, public_key: any) => {
    try {
      const res = await axios.get(
        `https://pocket.seerbitapi.com/pocket/pocket-owner/${email}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Public-Key": `${public_key}`,
          },
        }
      );

      if (res.data.code === "error") {
        Alert("error", res.data.message);
      } else {
        console.log("checkPocketType:", res.data.pocketType);
        // setPocketType(res.data.pocketType)
        setValues("pocketType", res.data.pocketType);
      }

      return res;
    } catch (error) {
      handleException(error);
    }
  };

  const checkAllPrimaryPockets = async (token: any, public_key: any) => {
    try {
      const res = await axios.get(
        `https://pocket.seerbitapi.com/pocket/multiple-pockets/${email}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
            "Public-Key": `${public_key}`,
          },
        }
      );

      if (res.data.code === "error") {
        Alert("error", res.data.message);
      } else {
        console.log("allPrimaryPockets:", res.data);
        // setPocketType(res.data.pocketType)
        setPocketData({ ...pocket_data, allPrimaryPockets: res.data });
        seAlltPocketDetails(res.data);
        setValues("allPrimaryPockets", res.data);
      }

      return res;
    } catch (error) {
      // handleException(error);
    }
  };

  const validateUser = async (userData: any) => {
    try {
      const res = await axios.post(`${BASE_URL}authenticate`, userData, {
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (res.data.code === "error") {
        setLoading(false);
        Alert("error", res.data.message);
      } else {
        setAuthRes({ ...auth_data, ...res.data });
        setBearerToken(res.data.data.bearerToken);
        setValues(`${email}_token`, res.data.data.bearerToken);

        if (res.data.data.requirePasswordChange) {
          setShowModal(true);

          const resx = await axios.get(
            `${BASE_URL}user/find-by-username/${email}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${res.data.data.bearerToken}`,
              },
            }
          );

          const public_key = `${
            resx.data.data.isBusiness
              ? resx.data.data.businessDetails.publicKey
              : process.env.REACT_APP_POCKET_PUBLIC_KEY
          }`;

          setPublicKey(public_key);
          setLoading(false);
          return;
          // navigate("/dashboard/home");
        }

        const response = await fetchUserData(res.data.data.bearerToken);

        console.log("UserData Res:", response);

        if (response?.data.data.isBusiness) {
          if (fetchStatus !== "error") {
            Alert("success", "Login Successful.");
            setUserLoggedIn(true);
            setValues("username", email);
            navigate("/dashboard/home");
            setLoading(false);
          } else {
            Alert("error", "Unable to fetch user data.");
            setLoading(false);
          }
        } else {
          setLoading(false);
          logoutHandler();
          localStorage.clear();
          sessionStorage.clear();
        }
      }
      return res;
    } catch (error) {
      setLoading(false);
      handleException(error);
    }
  };

  console.log("", "=== values");

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    if (email.length < 1) {
      setEmailErr("Please enter a valid email");
    }
    if (password.length < 1) {
      setPasswordErr("Password should be at least 1 characters");
    }

    const userData = {
      email: email,
      password: password,
    };

    validateUser(userData);
  };

  const mainURL = `user/change-password`;

  const ChangePasswordFunc = useCallback(async () => {
    if (!newPassword || !confirmPassword || !password) {
      Alert("error", "Incomplete Form data input");
      return;
    }

    if (newPassword !== confirmPassword) {
      Alert("error", "Password do not Match");
      return;
    }

    setLoading(true);
    try {
      const res = await axios.post(
        `${BASE_URL + mainURL}`,
        {
          currentPassword: oldPassword,
          newPassword: newPassword,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${bearerToken}`,
            // "Public-Key": `${publicKey}`,
          },
        }
      );
      if (res.data.code === "error") {
        setLoading(false);
        Alert("error", res.data.message);
      } else {
        setLoading(false);
        Alert("success", res.data.message);
        setShowModal(false);
      }
    } catch (error) {
      setLoading(false);
      setErrorState(true);
      handleException(error);
    }
  }, [password, newPassword, confirmPassword]);

  return (
    <>
      <form onSubmit={handleSubmit}>
        <p className="font-semibold text-2xl leading-7 text-Black2">
          Login to your account
        </p>
        <span className="text-left flex flex-col gap-5 mb-5">
          <p className="font-normal text-base leading-7">
            Welcome back, please enter your details
          </p>
        </span>
        <span className="flex flex-col gap-3">
          <FormInput
            type="email"
            name="email"
            placeholder="example@gmail.com"
            inputStyle="border border-transparent text-Black2"
            label="Email Address"
            labelStyle="font-normal text-base leading-6 text-Black2"
            onChange={(e) => setEmail(e.target.value)}
            value={email}
            validationErr={emailErr}
          />
          <FormInput
            type={showPassword ? "text" : "password"}
            name="passwd"
            placeholder="********"
            inputStyle="border border-transparent text-Black2 flex-row-reverse"
            label="Password"
            labelStyle="font-normal text-base leading-6 text-Black2"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            validationErr={passwordErr}
            img={showPassword ? showPwd : hidePwd}
            imgStyle="cursor-pointer w-5 h-5 block"
            imgOnClick={() => setShowPassword((prevState) => !prevState)}
          />
        </span>
        <span>
          <Button
            btnType="submit"
            btnText={
              loading
                ? // <Spinner/>      // <Spinner text="Loading..." textStyle="text-white" />
                  "Loading..."
                : "Sign in"
            }
            btnStyle={`${
              disabledBtn ? "cursor-not-allowed" : ""
            } bg-black text-white px-6 py-3 mt-12 w-full`}
            disabled={formComplete === false || loading === true}
          />
        </span>
        <span className="flex justify-centerf mt-6">
          <Link
            to="/auth/forgot_password"
            className="text-[#E90500] text-base leading-7 hover:underline hover:transition-all"
          >
            Forgot password?
          </Link>
        </span>
      </form>
      {showModal && (
        <AppModal
          modalStyle="desktop:w-[40%] w-3/5 mobile:w-4/5 min-h-fit z-30 top-0 left-0 right-0 p-4 my-[10rem] mobile:my-[5rem] mx-auto animate-center_align rounded-md"
          contentStyle="overflow-y-scroll min-h-fit w-full"
          // handleClose={() => setShowModal(false)}
        >
          <div className="text-center bg-white p-10 w-full rounded-lg">
            <h5>Password Expired!</h5>
            <p>Password Change Required</p>

            <FormInput
              type={showPassword ? "text" : "password"}
              name="passwd"
              placeholder="********"
              inputStyle="border border-transparent text-Black2 flex-row-reverse"
              label="Current Password"
              labelStyle="font-normal text-left text-Black2 mx-0 mt-6"
              onChange={(e) => setOldPassword(e.target.value)}
              value={oldPassword}
              validationErr={passwordErr}
              img={showPassword ? showPwd : hidePwd}
              imgStyle="cursor-pointer w-5 h-5 block"
              imgOnClick={() => setShowPassword((prevState) => !prevState)}
            />
            <FormInput
              type={showPassword ? "text" : "password"}
              name="passwd"
              placeholder="********"
              inputStyle="border border-transparent text-Black2 flex-row-reverse"
              label="New Password"
              labelStyle="font-normal text-left text-Black2 mx-0 mt-6"
              onChange={(e) => setNewPassword(e.target.value)}
              value={newPassword}
              validationErr={passwordErr}
              img={showPassword ? showPwd : hidePwd}
              imgStyle="cursor-pointer w-5 h-5 block"
              imgOnClick={() => setShowPassword((prevState) => !prevState)}
            />
            <FormInput
              type={showPassword ? "text" : "password"}
              name="passwd"
              placeholder="********"
              inputStyle="border border-transparent text-Black2 flex-row-reverse"
              label="Confirm Password"
              labelStyle="font-normal text-left text-Black2 mx-0 mt-6"
              onChange={(e) => setConfirmPassword(e.target.value)}
              value={confirmPassword}
              validationErr={passwordErr}
              img={showPassword ? showPwd : hidePwd}
              imgStyle="cursor-pointer w-5 h-5 block"
              imgOnClick={() => setShowPassword((prevState) => !prevState)}
            />
            <Button
              onClick={ChangePasswordFunc}
              btnType="button"
              btnText={
                loading ? (
                  <Spinner /> // <Spinner text="Loading..." textStyle="text-white" />
                ) : (
                  "Change Password"
                )
              }
              btnStyle="mt-10 bg-black text-SecondaryAccent5 font-semibold text-sm leading-7 px-5 py-1 !rounded-lg w-full"
              disabled={loading}
            />
          </div>
        </AppModal>
      )}
    </>
  );
};

const mapStateToProps = (state: any) => ({
  bearerToken: state.auth.token,
  auth_data: state.auth.auth_data,
  pocket_data: state.auth.pocket_data,
  landingPage_data: state.app.landingPage_data,
  primaryPocket_data: state.pocket.primaryPocket_data,
  publicKey: state.auth.public_key,
});

const mapDispatchToProps = (dispatch: any) => ({
  logoutHandler: () => dispatch(logoutHandler()),
  setAuthRes: (data: any) => dispatch(setAuthRes(data)),
  setUserData: (data: any) => dispatch(setUserData(data)),
  setPublicKey: (data: any) => dispatch(setPublicKey(data)),
  setPocketData: (data: any) => dispatch(setPocketData(data)),
  setBearerToken: (data: any) => dispatch(setBearerToken(data)),
  setUserLoggedIn: (data: boolean) => dispatch(setUserLoggedIn(data)),
  setLandingPageData: (data: any[]) => dispatch(setLandingPageData(data)),
  seAlltPocketDetails: (data: any) => dispatch(seAlltPocketDetails(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
