import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import {tableBody} from '../../../mockData/BusinessList'
import copyIcon from '../../../assets/svg/copy.svg'
import editIcon from '../../../assets/svg/edit-black.svg'
import Button from 'components/atoms/Button'
import AppModal from '../../../components/organisms/CustomModal'
import { connect } from 'react-redux'
import Alert from 'components/atoms/Alert'
import FormInput from 'components/atoms/FormInput'
import Toggle from 'components/atoms/Toggle'
import {toggleModal, toggleAppEnv} from '../../../redux/app/app.action'
import RadioAndCheckInput from 'components/atoms/RadioAndCheckInputGroup/RadioAndCheckInput'
import ArrowDownIcon from '../../../assets/svg/arrow-down.svg'
import DetailsCard from 'components/molecules/DetailsCard'
import Prompts from 'components/atoms/Prompts'
import { render } from '@testing-library/react'
import checkIcon from '../../../assets/svg/prompt_check.svg'
import infoIcon from '../../../assets/svg/info-circle-danger.svg'
import PageTitle from 'components/atoms/PageTitle'
import PageTitleHeader from 'components/molecules/PageTitleHeader'
import ColorTag from 'components/atoms/ColorTag'
import CopyText from 'components/atoms/CopyText'

const ConfigureBusiness = ({modalState, toggleModal, appEnv, toggleAppEnv}) => {
  const [directPocket, setDirectPocket] = useState(false)
  const [creationCharge, setCreationCharge] = useState(false)
  const [fundingCharge, setFundingCharge] = useState(false)
  const [prompt, setPrompt] = useState(false)
  const [actionState, setActionState] = useState(false)
  const [isAlertVisible, setIsAlertVisible] = useState(false)
  const [promptSetting, setPromptSetting] = useState({
    icon: "",
    iconStyle: "",
    BgColor:"",
    loadColor:"",
    message:"",
    subMessage:""
  })
  const [confirmPrompt, setConfirmPrompt] = useState({
    icon: "",
    iconStyle: "",
    BgColor:"",
    load: false,
    loadColor:"",
    message:"",
    subMessage:""
  })

  const routeParam = useParams()
  const businessItem = tableBody.filter(item => item.id == routeParam.id)
  const business = businessItem[0]

  // const handleSetUp = () => {
  //   setShowModal(!showModal)
  // }

  const radioData = [
    {label: "NGN"},
    {label: "USD"},
    {label: "KES"},
    {label: "GHS"},
  ]

  const handleSubmit = e => {
    e.preventDefault()
  }

  const modalContent = (
    <AppModal handleClose={toggleModal} modalStyle="desktop:w-1/4 mobile:w-3/4 bg-white min-h-screen z-30 top-0 right-0 animate-slide_left p-8" contentStyle="overflow-y-scroll h-[92vh]">
      <form className='flex flex-col gap-10' onSubmit={handleSubmit}>
        <div className='flex flex-col'>
          <span className='font-semibold text-2xl leading-7 text-black mb-3'>
            Set up
          </span>
          <span className='font-normal text-[15px] leading-7 text-SecondaryAccent'>
            Configure your business settings
          </span>
        </div>
        <div className='flex flex-col'>
          <span className='border-b border-SecondaryAccent2 font-semibold text-[18px] leading-6 text-DarkBg pb-4 mb-5'>
            Payout
          </span>
          <div className='flex flex-col gap-5'>
            <span className='w-full flex justify-between'>
              <p className='font-normal text-xs leading-3 text-Black2'>Payout service status</p>
              <Toggle 
                handleChange={toggleAppEnv} 
                checked={appEnv} 
                style="w-8 h-4 after:h-4 after:w-4 after:top-0 after:left-0 peer-checked:bg-black after:bg-GrayCustom6"
              />
            </span>
            <span className='w-full flex flex-col justify-between'>
              <p className='font-normal text-xs leading-3 text-Black2 mb-3'>Payout service charge</p>
              <FormInput 
                type="number"  
                name="service_charge" 
                placeholder="0.0" 
                inputStyle="border border-trannsparent text-Black2"
                onChange={()=>{}}
                min={0}
                // value={email}
                // validationErr={emailError}
              />
            </span>
          </div>
        </div>
        <div className='flex flex-col'>
          <span className='border-b border-SecondaryAccent2 font-semibold text-[18px] leading-6 text-DarkBg pb-4 mb-5'>
            Pockets
          </span>
          <div className='flex flex-col gap-8'>
            <span className='w-full flex justify-between'>
              <p className='font-normal text-xs leading-3 text-Black2'>Direct pocket</p>
              <span className='w-12 h-6 flex justify-end items-centeru crsor-pointer' onClick={() => setDirectPocket(!directPocket)}>
                <img src={ArrowDownIcon} alt="ArrowDownIcon" className="w-4 h-4" />
              </span>
            </span>
            {directPocket && <DetailsCard title="Sub pocket service status" inputTitleOne="Service charge type" inputTitleTwo="Service charge"/>}
            <span className='w-full flex justify-between'>
              <p className='font-normal text-xs leading-3 text-Black2'>Creation charge</p>
              <span className='w-12 h-6 flex justify-end items-centeru crsor-pointer' onClick={() => setCreationCharge(!creationCharge)}>
                <img src={ArrowDownIcon} alt="ArrowDownIcon" className="w-4 h-4"/>
              </span>
            </span>
            {creationCharge && <DetailsCard title="Sub pocket service status" inputTitleOne="Service charge type" inputTitleTwo="Service charge"/>}
            <span className='w-full flex justify-between'>
              <p className='font-normal text-xs leading-3 text-Black2'>Funding charge</p>
              <span className='w-12 h-6 flex justify-end items-centeru crsor-pointer' onClick={() => setFundingCharge(!fundingCharge)}>
                <img src={ArrowDownIcon} alt="ArrowDownIcon" className="w-4 h-4"/>
              </span>
            </span>
            {fundingCharge && <DetailsCard inputTitleOne="Service charge type" inputTitleTwo="Service charge"/>}
            <span className='w-full flex justify-between'>
              <p className='font-normal text-xs leading-3 text-Black2'>2 Factor authentication</p>
              <span className='w-12 h-6 flex justify-end items-centeru crsor-pointer' onClick={()=>{}}>
                <img src={ArrowDownIcon} alt="ArrowDownIcon" className="w-4 h-4"/>
              </span>
            </span>
            {/* {two_Factor_authentication && two_Factor_authentication} */}
          </div>
        </div>
        <div className='flex flex-col'>
          <span className='border-b border-SecondaryAccent2 font-semibold text-[18px] leading-6 text-DarkBg pb-4 mb-5'>
            Currency
          </span>
          <div className='flex flex-col gap-8'>
            <span className='w-full flex flex-col justify-between'>
              <p className='font-normal text-xs leading-3 text-Black2 mb-3'>Assign currencies for {business.business_name}</p>
              <RadioAndCheckInput type="radio" name="currency" inputData={radioData} checkStyle=""/>
            </span>
          </div>
        </div>
        <Button
          btnType='submit'
          btnText="Save changes"
          btnStyle='border py-[10px] bg-DarkBg3 text-white text-[15px] leading-7'
          onClick={()=>{}}
          disabled={false}
          disabledClass=""
        />
      </form>
    </AppModal>
  )

  const promptContent = (
    <AppModal modalStyle="w-full h-full flex justify-center items-center -top-[20%] z-30 animate-center_align" contentStyle="overflow-y-scroll w-fit">
      <Prompts
        promptSetting={promptSetting}
        action={true}
        handleClose={()=>{
          setPrompt(false)
        }}
        handleConfirm={()=>{
          setActionState(!actionState)
          setPrompt(false)
          setIsAlertVisible(!isAlertVisible)
          setTimeout(() => {
            setIsAlertVisible(false);
          }, 1500);
        }}
      />
    </AppModal>
  )

  const confirmationContent = (
    <AppModal modalStyle="w-full h-full flex justify-center items-center -top-[20%] z-30 animate-center_align" contentStyle="overflow-y-scroll">
      <Prompts
        promptSetting={confirmPrompt}
      />
    </AppModal>
  )

  const manageClick = () => {
    if(actionState){
      setPromptSetting({
        icon: infoIcon,
        iconStyle: "bg-DangerAccent4",
        BgColor:"white",
        loadColor:"bg-Danger3",
        message:"Disable Business",
        subMessage:"The business account will be been disabled, do you wish to continue?"
      })
      setConfirmPrompt({
        icon: infoIcon,
        iconStyle: "bg-DangerAccent4",
        BgColor:"white",
        load: true,
        loadColor:"bg-Danger3",
        message:"Business disabled successfully",
        subMessage:""
      })
    }else{
      setPromptSetting({
        icon: checkIcon,
        iconStyle: "bg-Green_Accent6",
        BgColor:"white",
        loadColor:"bg-Green_Accent7",
        message:"Enable Business",
        subMessage:"Are you sure you want to enable this business account?"
      })
      setConfirmPrompt({
        icon: checkIcon,
        iconStyle: "bg-Green_Accent6",
        BgColor:"white",
        load: true,
        loadColor:"bg-Green_Accent7",
        message:"Business enabled successfully",
        subMessage:""
      })
    }
    setPrompt(!prompt)


  }
  
  const multipleBtnData = [
    {btnText:`${actionState ? 'Disable business' : 'Enable business'}`, btnStyle:`border px-4 py-1 desktop:text-sm mobile:text-xs flex-shrink-0 ${actionState ? 'bg-DangerAccent4 border-Danger3 text-Danger3' : 'bg-Green_Accent6 border-Green_Accent7 text-Green_Accent7'}`, btnImg:"", btnImgStyle:"", onClick:manageClick}
  ]

  return (
    <>
      <div className='flex flex-col'>
        <PageTitleHeader 
            title="Configure Business"
            btn_num={1}
            btnData={multipleBtnData} 
        />
        <div className='flex flex-col'>
          <div className='flex flex-col gap-1 desktop:w-fit mobile:w-full mb-8'>
            <span className='font-semibold desktop:text-2xl mobile:text-xl text-black'>
              {business.business_name}
            </span>
            <span className='font-normal desktop:text-[15px] mobile:text-xs text-DarkBg3 leading-7'>
              {business.date_created}
            </span>
            <CopyText 
              text="SEERBIT_PROD_2399949488837773762" 
              textStyle="bg-SecondaryAccent3 px-4 py-2 rounded-md w-fit font-normal text-xs leading-5 text-DarkBg"  
              mssgStyle='-right-[60px]' 
              imgStyle="w-5 h-5"
            />
          </div>
          <div className='flex flex-col gap-10 desktop:w-[910px] mobile:w-full'>
            <div className='flex flex-col'>
              <span className='border-b border-SecondaryAccent2 font-semibold text-[18px] leading-6 text-DarkBg pb-4 mb-5'>
                Business details
              </span>
              <div className='flex flex-col gap-5'>
                <span className='desktop:w-[410px] mobile:w-full flex justify-between items-center'>
                  <p className='font-normal text-xs leading-3 text-Black2'>Business Country</p>
                  <p className='font-semibold text-sm leading-5 text-Black3'>{business.country}</p>
                </span>
                <span className='desktop:w-[410px] mobile:w-full flex justify-between items-center'>
                  <p className='font-normal text-xs leading-3 text-Black2'>Business Currency</p>
                  <p className='font-semibold text-sm leading-5 text-Black3'>{business.currency}</p>
                </span>
              </div>
            </div>

            <div className='flex flex-col'>
              <span className='border-b border-SecondaryAccent2 font-semibold text-[18px] leading-6 text-DarkBg pb-4 mb-5'>
                Transaction fee
              </span>
              <div className='flex flex-col gap-5'>
                <span className='desktop:w-[410px] mobile:w-full flex justify-between items-center'>
                  <p className='font-normal text-xs leading-3 text-Black2'>Creating pockets</p>
                  <p className='font-semibold text-sm leading-5 text-Black3'>{business.currency} 200</p>
                </span>
                <span className='desktop:w-[410px] mobile:w-full flex justify-between items-center'>
                  <p className='font-normal text-xs leading-3 text-Black2'>Credits</p>
                  <p className='font-semibold text-sm leading-5 text-Black3'>{business.currency} 200</p>
                </span>
                <span className='desktop:w-[410px] mobile:w-full flex justify-between items-center'>
                  <p className='font-normal text-xs leading-3 text-Black2'>Debits</p>
                  <p className='font-semibold text-sm leading-5 text-Black3'>{business.currency} 200</p>
                </span>
              </div>
            </div>

            <div className='flex flex-col'>
              <span className='border-b border-SecondaryAccent2 pb-4 mb-5'>
                <span className='flex justify-between desktop:w-[410px] mobile:w-full'>
                  <span className='font-semibold text-[18px] leading-6 text-DarkBg'>
                    Configurations
                  </span>
                  <span>
                    <Button
                      btnType='button'
                      btnText="Set up"
                      btnStyle='bg-DarkBg3 px-4 py-1 text-SecondaryAccent5 font-normal text-xs leading-5 text-center text-GrayCustom8'
                      onClick={toggleModal}
                    />
                  </span>
                </span>
              </span>
              <div className='flex flex-col gap-5'>
                <span className='desktop:w-[410px] mobile:w-full flex justify-between items-center'>
                  <p className='font-normal text-xs leading-3 text-Black2'>Payout</p>
                  <ColorTag 
                    styleColor={`${business.business_status === 'Active' ? 'bg-Green_Accent3 border-Green_Accent2' : business.business_status === 'Inactive' ? 'bg-DangerAccent4 border-Danger' : 'bg-Yellow_Accent border-Yellow' } rounded-md px-3 py-1 flex gap-1 items-center`} 
                    text={business.business_status} 
                    textColor={`${business.business_status === 'Active' ? 'text-Green_Accent2' : business.business_status === 'Inactive' ? 'text-Danger' : 'text-Yellow'} font-semibold text-sm leading-5`} 
                    BgColor={`${business.business_status === 'Active' ? 'bg-Green_Accent2' : business.business_status === 'Inactive' ? 'bg-Danger' : 'bg-Yellow'}`}
                  />
                </span>
                <span className='desktop:w-[410px] mobile:w-full flex justify-between items-center'>
                  <p className='font-normal text-xs leading-3 text-Black2'>Pocket</p>
                  <ColorTag 
                    styleColor={business.pocket_status === 'Activated' ? 'bg-Accent_blue5 border-Accent_blue4' : 'bg-DangerAccent3 border-Danger2'} 
                    text={business.pocket_status} 
                    textColor={business.pocket_status === 'Activated' ? 'text-Accent_blue4' : 'text-Danger2'} 
                    BgColor={business.pocket_status === 'Activated' ? 'bg-Accent_blue4' : 'bg-Danger2'}
                  />   
                </span>
                <span className='desktop:w-[410px] mobile:w-full flex justify-between items-center'>
                  <p className='font-normal text-xs leading-3 text-Black2'>Currency</p>
                  <ColorTag 
                    styleColor={business.currency === 'NGN' ? 'bg-Green_Accent border-Green_Accent2' : business.currency === 'USD' ? 'bg-Yellow_Accent border-Yellow_Accent2' : business.currency === 'GHS' ? 'bg-SecondaryAccent3 border-DarkBg3' : ''} 
                    text={business.currency} 
                    textColor={business.currency === 'NGN' ? 'text-Green_Accent2' : business.currency === 'USD' ? 'text-Yellow_Accent2' : business.currency === 'GHS' ? 'text-DarkBg3' : ''} 
                    BgColor={business.currency === 'NGN' ? 'bg-Green_Accent2' : business.currency === 'USD' ? 'bg-Yellow_Accent2' : business.currency === 'GHS' ? 'bg-DarkBg3' : ''}
                  />              
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      {modalState && modalContent}
      {prompt && promptContent}
      {isAlertVisible && confirmationContent}
    </>
  )
}

const mapStateToProps = (state: any) => ({
  appEnv: state.app.appEnv,
  modalState: state.app.modalState
})

const mapDispatchToProps = (dispatch: any) => ({
  toggleAppEnv: () => dispatch(toggleAppEnv()),
  toggleModal: () => dispatch(toggleModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureBusiness)