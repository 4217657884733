import React, { FC, useState, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ToCamelCase } from "../../../../components/atoms/CaseManager";
import moment from "moment";
import axios from "axios";
import { connect } from "react-redux";
import { handleException } from "../../../../utils";
import { BASE_URL, username } from "services/http";
import Alert from "components/atoms/Alert";
import creditIcon from "../../../../assets/svg/credit.svg";
import debitIcon from "../../../../assets/svg/debit.svg";

type Props = {
  title: string;
  subtitle?: string;
  value?: string;
  children?: any;
};

const Card: FC<any> = ({
  title,
  subtitle,
  value,
  children,
  toggleModal,
  modalState,
  bearerToken,
  publicKey,
  pocketId,
  getPocketTier,
  getPocketBal,
  pocketTier,
  pocketBal,
  isPocketId,
}) => {
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [query, setQuery] = useState("");
  const [queryKey, setQueryKey] = useState("");
  const [pageNum, setPageNum] = useState(null);
  const [recordsPerPage] = useState(10);
  const [transactions, setTransactions] = useState([]);
  const [errorState, setErrorState] = useState(false);
  const [totalElements, setTotalElements] = useState(null);
  const [totalPages, setTotalPages] = useState(null);

  const [loading, setLoading] = useState(false);

  const defaultURl = `transaction/v2/search`;

  const url =
    query.length !== 0 && (!startDate || !endDate)
      ? `${defaultURl}?${ToCamelCase(queryKey)}=${query?.toLowerCase()}`
      : query.length !== 0 && startDate !== "" && endDate !== ""
      ? `${defaultURl}?${ToCamelCase(
          queryKey
        )}=${query?.toLowerCase()}&createdOnStartDate=${moment(
          startDate
        ).format("DD-MM-YYYY")}&createdOnEndDate=${moment(endDate).format(
          "DD-MM-YYYY"
        )}`
      : startDate && endDate === ""
      ? `${defaultURl}?createdOnStartDate=${moment(startDate).format(
          "DD-MM-YYYY"
        )}`
      : startDate === "" && endDate
      ? `${defaultURl}?createdOnEndDate=${moment(endDate).format("DD-MM-YYYY")}`
      : startDate && endDate
      ? `${defaultURl}?createdOnStartDate=${moment(startDate).format(
          "DD-MM-YYYY"
        )}&createdOnEndDate=${moment(endDate).format("DD-MM-YYYY")}`
      : defaultURl;

  const mainURL = `${url}?size=${recordsPerPage}&page=${
    pageNum === null ? 0 : pageNum
  }`;

  const fetchDataFunc = useCallback(async () => {
    setLoading(true);
    try {
      const res = await axios.get(`${BASE_URL + mainURL}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${bearerToken}`,
          "Public-Key": `${publicKey}`,
        },
      });
      if (res.data.code === "error") {
        setLoading(false);
        Alert("error", res.data.message);
      } else {
        setLoading(false);
        setTransactions(res.data.data.content);
        setTotalElements(res.data.data.totalElements);
        setTotalPages(res.data.data.totalPages);
      }
    } catch (error) {
      setLoading(false);
      setErrorState(true);
      handleException(error);
    }
  }, [mainURL]);

  useEffect(() => {
    fetchDataFunc();
  }, [fetchDataFunc]);

  console.log(transactions, "====434343");

  return (
    <div className="bg-white h-[312px] border border-[#E2EEFF] rounded-lg">
      <div className="px-6 py-2 border-b border-[#E2EEFF] flex justify-between">
        <p className="text-lg text-[#020607]">{"Recent Transactions"}</p>
        <p
          className="cursor-pointer underline"
          onClick={() => navigate("/dashboard/transactions/overview")}
        >
          See all transactions
        </p>
      </div>
      {!transactions ? (
        <div className="flex h-full items-center justify-center">
          <div className="flex flex-col items-center gap-2">
            <div>No Data Available</div>
          </div>
        </div>
      ) : (
        <div className="p-6">
          {transactions.slice(0, 3).map((data: any) => (
            <div className="py-4 flex items-center justify-between">
              <div className="flex items-center">
                <img
                  src={data.transactionLeg === "DEBIT" ? debitIcon : creditIcon}
                />
                <div className="mx-4">
                  <div>{data?.transactionLeg}</div>
                  <div>
                    {moment(data.createdAt).format("MMM DD, YYYY - LTS")}
                  </div>
                </div>
              </div>
              <div>{`${data.transactionCurrency} ${data.transactionAmount}`}</div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  pocketTier: state.app.pocketTier,
  pocketBal: state.app.pocketBal,
  modalState: state.app.modalState,
  bearerToken: state.auth.token,
  user_data: state.auth.user_data,
  publicKey: state.auth.public_key,
  pocketId: state.auth.pocket_data.pocketId,
});

const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Card);
// export default Card;
