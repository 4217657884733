import React, { useEffect, useState, useCallback } from 'react'
import PageHeader from 'components/molecules/PageHeader'
import AppTable from 'components/organisms/app-table'
import TableDataSpan from 'components/organisms/app-table/TableDataSpan'
import { BASE_URL, BASE_URL_PROD_POCKET } from 'services/http'
import Alert from 'components/atoms/Alert'
import AppModal from '../../../components/organisms/CustomModal'
import { toggleAppEnv, toggleModal, getPocketTier, getPocketBal } from 'redux/app/app.action'
import { connect } from 'react-redux'
import Button from 'components/atoms/Button'
import { useNavigate, useParams } from 'react-router-dom'
import {ToCamelCase} from '../../../components/atoms/CaseManager'
import CopyText from '../../../components/atoms/CopyText'
import moment from 'moment'
import { handleException } from '../../../utils'
import { getValues } from '../../../services/storage'
import BatchSideComp from './BatchSideComp'
import axios from 'axios'
import {TransactionsHeader} from '../../../components/molecules/tableExport'
import { data } from 'autoprefixer'
import SubNav from '../../../components/organisms/SubNav'
import PageTitle from '../../../components/atoms/PageTitle'

const searchKeys = [
  {title: "pocketId", value: null, link: null, action: () => null},
  {title: "reference", value: null, link: null, action: () => null},
  {title: "narration", value: null, link: null, action: () => null}
].sort()

const BatchTransactionsOverview = ({
  toggleModal,
  modalState,
  bearerToken,
  publicKey,
  pocketId,
  getPocketTier,
  getPocketBal,
  pocketTier,
  pocketBal
}) => { 
  const [query, setQuery] = useState("")
  const [queryKey, setQueryKey] = useState("")
  const [transactions, setTransactions] = useState([])
  const [loading, setLoading] = useState(false)
  const [errorState, setErrorState] = useState(false)
  const [totalElements, setTotalElements] = useState(null)
  const [totalPages, setTotalPages] = useState(null)
  // const [transactionBals, setTransactionBals] = useState([])
  const [transactionDetails, setTransactionDetails] = useState([])
  const [pageNum, setPageNum] = useState(null)
  const [recordsPerPage ] =  useState(10)

  const [startDate, setStartDate] = useState("")
  const [endDate, setEndDate] = useState("")

  const [showModal, setShowModal] = useState(false)

  const batchId = useParams()
  console.log(batchId.id)

  // https://pocket.seerbitapi.com/pocket/pockets/batch/SBP0000829

  // https://pocket.seerbitapi.com/pocket/pockets/batch/SBP0000829?size=5&page=0&amount=5&batchId=kcy3m6j&startDate=2024-02-19T22%3A40%3A58&endDate=2024-02-19T22%3A40%3A58&reference=JIN-VRvUcvvggRm3DbbDvDjjBFG
  
  // batch/SBP0000829
  // ?size=5&page=0
  // &amount=5
  // &batchId=kcy3m6j
  // &startDate=2024-02-19T22%3A40%3A58
  // &endDate=2024-02-19T22%3A40%3A58
  // &reference=JIN-VRvUcvvggRm3DbbDvDjjBFG

  const defaultURl = `batch/${pocketId}?size=${recordsPerPage}&batchId=${batchId.id}&page=${pageNum === null ? 0 : pageNum}`

  const mainURL =  query.length !== 0 && (!startDate || !endDate)
            ? `${defaultURl}&${ToCamelCase(queryKey)}=${query?.toLowerCase()}`

            : query.length !== 0 && startDate !== "" && endDate !== ""
            ? `${defaultURl}&${ToCamelCase(queryKey)}=${query?.toLowerCase()}&createdOnStartDate=${moment(startDate).format("DD-MM-YYYY")}&createdOnEndDate=${moment(endDate).format("DD-MM-YYYY")}`
            
            : (startDate && endDate === "")
            ? `${defaultURl}&createdOnStartDate=${moment(startDate).format("DD-MM-YYYY")}`

            : (startDate === "" && endDate)
            ? `${defaultURl}&createdOnEndDate=${moment(endDate).format("DD-MM-YYYY")}`

            : (startDate && endDate)
            ? `${defaultURl}&createdOnStartDate=${moment(startDate).format("DD-MM-YYYY")}&createdOnEndDate=${moment(endDate).format("DD-MM-YYYY")}`

            : defaultURl

  const getCurrentPage = (pageNumber) => {
    setPageNum(pageNumber)
  }
  
  const fetchDataFunc = useCallback(async () => {
    setLoading(true)
    try{
      const res = await axios.get(`${BASE_URL_PROD_POCKET + mainURL}` , {
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${bearerToken}`,
          "Public-Key": `${publicKey}`
        }
      })
      if(res.data.code === 'error'){
        setLoading(false)
        Alert("error", res.data.message);
      }else{
        setLoading(false)
        setTransactions(res.data?.data?.content)
        console.log("Res", res);
        setTotalElements(res.data?.data?.totalElements)
        setTotalPages(res.data?.data?.totalPages)
      }
    }catch(error){
      setLoading(false)
      setErrorState(true)
      handleException(error)
    }
  }, [mainURL])
  
  useEffect(() => {
    fetchDataFunc()
  }, [fetchDataFunc])

  const viewTransaction = (id) => {
    setShowModal(prevState => !prevState)
    const transactionFilter = transactions.filter(item => item.transactionId === id)
    const transactionItem = transactionFilter[0]
    setTransactionDetails(transactionItem)
  }

  const handleHover = (data) => {
    getPocketTier(data.pocketTier)
    getPocketBal(data.availableBalanceAmount)
  }

  const columns = [ 
    {
      label: 'Date Created',
      render: (data) => <TableDataSpan 
                          onClick={()=>viewTransaction(data.transactionId)}
                          handleMouseEnter={()=>handleHover(data)} 
                          text={moment(data.createdAt).format("MMM DD, YYYY - LTS")} 
                          additionalClass='text-opacity-70 text-xs cursor-pointer'
                        />
    },
    {
      label: 'Amount',
      render: (data) => <TableDataSpan 
                          onClick={()=>viewTransaction(data.transactionId)} 
                          handleMouseEnter={()=>handleHover(data)} 
                          additionalClass='text-opacity-70 text-xs cursor-pointer'
                        >
                          <div className='flex gap-2 justify-start items-center'>
                            <span>{data.currency + " " + (Intl.NumberFormat().format(data.amount))}</span>
                          </div>
                        </TableDataSpan>
    },
    {
      label: 'Payment Reference',
      render: (data) => <TableDataSpan
                          onClick={()=>viewTransaction(data.transactionId)} 
                          handleMouseEnter={()=>handleHover(data)}  
                          additionalClass="cursor-default"
                        >
                          <CopyText 
                            text={data.reference}
                            textStyle="text-opacity-70 text-xs mr-2 inline-block item-center"  
                            mssgStyle='-right-3 -top-[18px]' 
                            imgStyle="w-4 h-4"
                          />
                        </TableDataSpan>
    },
    {
      label: 'Account Number',
      render: (data) => <TableDataSpan 
                          onClick={()=>viewTransaction(data.transactionId)} 
                          handleMouseEnter={()=>handleHover(data)} 
                          additionalClass="cursor-default"
                          text={data.accountNumber}
                        />
    },
    {
      label: 'Status',
      render: (data) => <TableDataSpan 
                          // onClick={()=>viewBatchTransactions(data.batchId)} 
                          handleMouseEnter={() => handleHover(data)}  
                          additionalClass='text-opacity-70 text-xs cursor-pointer'
                        >
                          <div className='flex gap-2 justify-start items-center'>
                            <span className={`${data.processingStatusCode === '00' ? 'bg-Green_Accent8 text-Success2' : data.processingStatusCode === 'S20' ? 'bg-Yellow_Accent text-Yellow' : 'bg-DangerAccent5 text-Danger4'} px-[10px] py-[5px] rounded-md`}>
                              {data.processingStatus}</span>
                          </div>
                        </TableDataSpan>
    }
  ]

  // S20
  
  const NavData = [
    {title: "Overview"},
    {title: "Batches"}
  ]

  console.log("BatchTransOverview:", transactions);

  return (
    <>
      <PageHeader
        pageTitle={`Batch Transactions Overview - ${batchId.id}`}
        pageTitleStyle='!text-xl font-semibold'
        exportTable={true}
        tableKeys={searchKeys}
        queryValue={query}
        setQuery={setQuery}
        setQueryKey={setQueryKey}
        NoSearchBar={true}
        startDate={startDate}
        endDate={endDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        tableHeaders={TransactionsHeader}
        tableData={transactions}
        csvUrl={mainURL}
      />

      {/* <div className='bg-Background3 rounded-[5px] p-4 my-3 mobile:py-2 flex flex-col justify-between gap-4 mobile:gap-2'>
        <div className='flex mobile:flex-col justify-between gap-4'>
          <div className='flex flex-col gap-3'>
            <span className='font-normal text-base mobile:text-sm leading-5 text-SecondaryAccent gap-[5px] mobile:gap-[2px] flex mobile:justify-between'>
              <span className='w-fit break-keep'>Date</span>
              <span className='text-DarkBg3 mobile:justify-end w-fit mobile:w-[60%] flex'><p className='text-right w-full font-semibold truncate'>{'DataInitiated'}</p></span>
            </span>
          </div>
          <div className='flex flex-col gap-3'>
            <span className='font-normal text-base mobile:text-sm leading-5 text-SecondaryAccent gap-[5px] mobile:gap-[2px] flex mobile:justify-between'>
              <span className='w-fit break-keep'>Transaction count</span>
              <span className='text-DarkBg3 mobile:justify-end w-fit mobile:w-[60%] flex'><p className='text-right w-full font-semibold truncate'>{totalElements}</p></span>
            </span>
          </div>
        </div>
      </div> */}

      <AppTable
        data={transactions ? transactions : []}
        columns={columns}
        loading={loading}
        errorState={errorState}
        itemsPerPage={recordsPerPage}

        dataLength={totalElements}
        getCurrentPage={getCurrentPage}
      />
      {showModal && 
        <BatchSideComp
          toggleModal={() => setShowModal(prevState => !prevState)}
          data={transactionDetails}
        />
      }
    </>
  )
}

const mapStateToProps = (state) => ({
  pocketTier: state.app.pocketTier,
  pocketBal: state.app.pocketBal,
  modalState: state.app.modalState,
  bearerToken: state.auth.token,
  user_data: state.auth.user_data,
  publicKey: state.auth.public_key,
  pocketId: state.auth.pocket_data.pocketId,
})

const mapDispatchToProps = (dispatch) => ({
  toggleModal: () => dispatch(toggleModal()),
  getPocketTier: (data) => dispatch(getPocketTier(data)),
  getPocketBal: (data) => dispatch(getPocketBal(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(BatchTransactionsOverview)