import React, { FC } from 'react'

type Props = {
  title: string;
  subtitle?: string;
  value?: string;
  children?: any;
}

const AnalyticsCard: FC<Props> = ({ title, subtitle, value, children }) => {
  return (
    <div className='bg-white h-[312px] border border-[#E2EEFF] rounded-lg'>
      <div className='px-6 py-2 border-b border-[#E2EEFF]'><p className='text-lg text-[#020607]'>{title}</p></div>
      <div className="flex h-full items-center justify-center">
        {children ? (
          <div>
            {children}
          </div>
        ) : (
          <div className='flex flex-col items-center gap-2'>
            <p className='text-[#747C91] text-sm'>{subtitle}</p>
            <h1 className='text-4xl font-semibold'>{value}</h1>
          </div>
        )}
      </div>
    </div>
  )
}

export default AnalyticsCard